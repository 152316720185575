import React from "react";
import EmissionPage from "../../components/emission/EmissionPage";
import Live from "./Live";

class FeedComponent extends React.Component {
  constructor(props) {
    super();
    this.tab = props.tab;
  }

  render() {
    switch (this.tab) {
      case "following":
      case "popular":
      case "recent":
        return (
          <EmissionPage
            loading={!this.props.loaded}
            route={this.props.route}
            loadingMore={this.props.loadingMore}
            loadMore={this.props.loadMore}
            checkingNewer={this.props.checkingNewer}
            checkNewer={this.props.checkNewer}
            totalEnd={this.props.totalEnd}
            followEnd={this.props.followEnd}
            popularEnd={this.props.popularEnd}
            emissions={this.props.emissions}
            load={() => ({
              emissions: this.props.emissions,
            })}
            flavor={"feed-" + this.tab}
            updateEmissions={this.props.updateEmissions}
            userEdit={this.props.userEdit}
            ban={this.props.ban}
            restoreUser={this.props.restoreUser}
            privateProfile={this.props.privateProfile}
            unprivate={this.props.unprivate}
            blockOther={this.props.blockOther}
            unblockOther={this.props.unblockOther}
            socketStore={this.props.socketStore}
            accountDeleted={this.props.accountDeleted}
          />
        );
      case "live":
        return (
          <Live
            tab={this.props.tab}
            selectTab={this.props.selectTab}
            loaded={this.props.loaded}
            clickEmissionBody={this.props.clickEmissionBody}
            vote={this.props.vote}
            polls={this.props.polls}
            submitVotes={this.props.submitVotes}
            pollsSubmitting={this.props.pollsSubmitting}
            selectFile={this.props.selectFile}
            signalBoost={this.props.signalBoost}
            like={this.props.like}
            copyEmissionLink={this.props.copyEmissionLink}
            emissionCopied={this.props.emissionCopied}
            reply={this.props.reply}
            userInfo={this.props.userInfo}
            route={this.props.route}
            remove={this.props.remove}
            restore={this.props.restore}
            report={this.props.report}
            updateEmission={this.props.updateEmission}
            loadingMore={this.props.loadingMore}
            seeMore={this.props.seeMore}
            totalEnd={this.props.totalEnd}
            followEnd={this.props.followEnd}
            loadMore={this.props.loadMore}
            emissions={this.props.emissions}
            checkingNewer={this.props.checkingNewer}
            checkNewer={this.props.checkNewer}
            popularEnd={this.props.popularEnd}
            profiles={this.props.profiles}
            liveEnd={this.props.liveEnd}
            checkingStreams={this.props.checkingStreams}
            checkStreams={this.props.checkStreams}
            updateEmissions={this.props.updateEmissions}
            userEdit={this.props.userEdit}
            ban={this.props.ban}
            restoreUser={this.props.restoreUser}
            privateProfile={this.props.privateProfile}
            unprivate={this.props.unprivate}
            blockOther={this.props.blockOther}
            unblockOther={this.props.unblockOther}
            socketStore={this.props.socketStore}
            pushSocketEvent={this.props.pushSocketEvent}
            accountDeleted={this.props.accountDeleted}
          />
        );
    }
  }
}

export default FeedComponent;
