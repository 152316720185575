import env from "../env";
import React from "react";
import { connect } from "react-redux";
import { MDBCard, MDBCardBody, MDBContainer } from "mdb-react-ui-kit";
import {
  route,
  new_emission,
  following,
  vote,
  signal_boost,
  set_user,
  remove_emission,
  restore_emission,
  update_emission,
  like_other,
  set_profile,
  ban_other,
  new_follow,
  private_profile,
  disable_messages,
  stream_start,
  stream_end,
  reply,
  user_edit,
  restore_user,
  unprivate,
  block_other,
  unblock_other,
  view,
  views,
  add_message_profile,
  set_unread_messages,
  clear_profile,
  account_deleted,
  update_socket_state,
  set_token,
  set_cache,
  notify,
} from "../redux/actions";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import { motion } from "framer-motion";
import axios from "axios";
import TextInput from "../components/textInput/TextInput";
import LoginModal from "../components/loginModal/LoginModal";
import LogoLoader from "../components/LogoLoader";
import ProfileInfoSelf from "./profile/self/ProfileInfo";
import ProfileInfoOther from "./profile/other/ProfileInfo";
import ProfileFeed from "./profile/ProfileFeed";
import { withRouter } from "react-router-dom";

/**
 * login - self - other
 * visit - self - other
 * visit - other - self
 */

const tabs = ["emissions", "emissionsAndReplies", "media", "likes"];

class Profile extends React.Component {
  constructor(props) {
    super();
    let cachedData;
    this.initialScrollTop = 0;
    /**
     * this.profile: String - The username of the profile
     * this.username: String - The username of the user currently logged in
     */
    this.profile = props.match.params.profile
      .replace(/^[\W_]+/g, "")
      .toLowerCase();
    if (
      props.history?.location?.state?.currRoute
        .split("/")[1]
        .split("#")[0]
        .replace(/^[\W_]+/g, "")
        .toLowerCase() === this.profile
    ) {
      cachedData = props.cache.find(
        (c) => c.page === "profile" && c.profile === this.profile
      );
      if (cachedData) {
        props.set_profile(cachedData.profileInfo);
        this.initialScrollTop = cachedData?.scrollTop;
      }
    }
    if (!cachedData) props.clear_profile();
    this.username = props.userInfo.username;
    this.state = cachedData
      ? cachedData.state
      : {
          /**
           * socketConnected: Boolean - Whether the main socket has connected
           */
          socketConnected: false,
          tabSelected:
            props.tempAction && props.tempAction.tab
              ? props.tempAction.tab
              : "emissions",
          tabExit: t.fade_out,
          tabEnter: t.fade_out,
          loginModalShown: false,
          updating: false,
          reset: false,
          socketEvents: [],
        };
  }

  /**
   * Connect socket
   */
  componentDidMount() {
    if (this.initialScrollTop) {
      const root = document.getElementById("root");
      root.style.scrollBehavior = "auto";
      root.scrollTop = this.initialScrollTop;
      root.style.scrollBehavior = "smooth";
    }
    // this.props.clear_profile();
    this.load();
    if (this.props.socket) this.initializeSocket();
  }

  /**
   * If socket is reconnected, re-initialize
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.socket && this.props.socket) this.initializeSocket();
    if (
      (prevProps.userInfo._id &&
        this.props.userInfo._id &&
        h.checkJanny(prevProps.userInfo) !==
          h.checkJanny(this.props.userInfo)) ||
      (prevProps.userInfo._id &&
        this.props.userInfo._id &&
        h.checkChadmin(prevProps.userInfo) !==
          h.checkChadmin(this.props.userInfo)) ||
      (prevProps.profileInfo?.user &&
        this.props.profileInfo?.user &&
        (prevProps.profileInfo?.user?.ban?.banned !==
          this.props.profileInfo?.user?.ban?.banned ||
          prevProps.profileInfo?.user?.private !==
            this.props.profileInfo?.user?.private ||
          prevProps.profileInfo.blocksMe !== this.props.profileInfo.blocksMe ||
          prevProps.profileInfo.isBlocked !== this.props.profileInfo.isBlocked))
    )
      this.update();
  }

  // Leave the profile socket room when leaving the profile
  componentWillUnmount() {
    // this.props.socket.emit("leave");
    this.props.clear_profile();
    const profileInfo = JSON.parse(JSON.stringify(this.props.profileInfo));
    this.props.set_cache({
      page: "profile",
      state: this.state,
      profileInfo,
      profile: this.profile,
      scrollTop: document.getElementById("root")?.scrollTop,
    });
    if (!this.props.socket) return;
    this.props.socket.off("new-emission");
    this.props.socket.off("vote");
    this.props.socket.off("like");
    this.props.socket.off("signalboost");
    this.props.socket.off("update-user");
    this.props.socket.off("remove-emission");
    this.props.socket.off("restore-emission");
    this.props.socket.off("ban");
    this.props.socket.off("restore-user");
    this.props.socket.off("private");
    this.props.socket.off("unprivate");
    this.props.socket.off("disable-messages");
    this.props.socket.off("stream-start");
    this.props.socket.off("new-message");
    this.props.socket.off("remove-message");
    this.props.socket.off("conversation-left");
    this.props.socket.off("conversation-removed");
    this.props.socket.off("conversation-kicked");
    this.props.socket.off("stream-end");
    this.props.socket.off("reply");
    this.props.socket.off("view");
    this.props.socket.off("views");
    this.props.socket.off("message-count");
    this.props.socket.off("pin");
    this.props.socket.off("block");
    this.props.socket.off("unblock");
    this.props.socket.off("account-deleted");
  }

  load = () => {
    axios
      .get(
        `${process.env.REACT_APP_LAMBDA_API_EMISSIONS}/profile/${this.profile}`,
        {
          headers: {
            Authorization: this.props.token,
          },
        }
      )
      .then((res) => {
        this.props.set_token(res.data.token);
        this.setState(
          (curr) => ({
            ...curr,
            loaded: true,
          }),
          () => {
            if (
              res.data.profileInfo?.user?._id !== this.props.userInfo._id &&
              res.data.profileInfo.conversation?.messages &&
              localStorage.getItem("chatKey") &&
              localStorage.getItem("userID") === this.props.userInfo._id
            ) {
              res.data.profileInfo.conversation = h.decryptConversations(
                this.props.userInfo._id,
                localStorage.getItem("chatKey"),
                [res.data.profileInfo.conversation]
              )[0];
            }
            res.data.profileInfo.emissions.items = h.updateArrayItems(
              this.props.profileInfo.emissions.items,
              res.data.profileInfo.emissions.items
            );
            res.data.profileInfo.likes.items = h.updateArrayItems(
              this.props.profileInfo.likes.items,
              res.data.profileInfo.likes.items
            );
            if (
              this.props.profileInfo.conversation &&
              res.data.profileInfo.conversation
            )
              res.data.profileInfo.conversation.messages = h.updateArrayItems(
                this.props.profileInfo.conversation.messages,
                res.data.profileInfo.conversation.messages
              );
            this.props.set_profile(res.data.profileInfo);
          }
        );
      })
      .catch((err) => {
        console.log("error", err);
        if (err.response?.status === 404) this.props.route("/not-found");
        else {
          setTimeout(this.load, 1500);
        }
      });
  };

  update = () =>
    this.setState(
      (curr) => ({
        ...curr,
        updating: true,
      }),
      () =>
        axios
          .post(
            process.env.REACT_APP_LAMBDA_PROFILE + "/update-external",
            {
              profile: this.profile,
              emissions: [
                ...new Set([
                  ...this.props.profileInfo.emissions.items.map(
                    (e) => e.emissionID
                  ),
                  ...this.props.profileInfo.likes.items.map(
                    (e) => e.emissionID
                  ),
                ]),
              ],
            },
            {
              headers: {
                Authorization: this.props.token,
              },
            }
          )
          .then((res) => {
            this.props.set_token(res.data.token);
            this.setState(
              (curr) => ({
                ...curr,
                updating: false,
              }),
              () => this.props.set_profile(res.data.profileInfo)
            );
          })
          .catch((err) => {
            console.log("update error", err);
            this.update();
          })
    );

  connectSocket = () =>
    this.setState(
      (curr) => ({
        ...curr,
        socketConnected: true,
      }),
      this.initializeSocket
    );

  setTab = (tab, callback) =>
    this.setState(
      (curr) => ({
        ...curr,
        tabExit: this.props.profileInfo.loaded
          ? tabs.indexOf(tab) > tabs.indexOf(this.state.tabSelected)
            ? t.fade_out_left
            : t.fade_out_right
          : t.fade_out,
        tabEnter: this.props.profileInfo.loaded
          ? tabs.indexOf(tab) > tabs.indexOf(this.state.tabSelected)
            ? t.fade_out_right
            : t.fade_out_left
          : t.fade_out,
      }),
      () =>
        this.setState(
          (curr) => ({
            ...curr,
            tabSelected: tab,
          }),
          () => {
            if (callback) callback();
          }
        )
    );

  /**
   * Turn off and then turn back on all socket actions
   */
  initializeSocket = () => {
    if (!this.props.socket) return;
    this.props.socket.off("new-emission");
    this.props.socket.off("vote");
    this.props.socket.off("like");
    this.props.socket.off("signalboost");
    this.props.socket.off("update-user");
    this.props.socket.off("remove-emission");
    this.props.socket.off("restore-emission");
    this.props.socket.off("ban");
    this.props.socket.off("restore-user");
    this.props.socket.off("private");
    this.props.socket.off("unprivate");
    this.props.socket.off("disable-messages");
    this.props.socket.off("stream-start");
    this.props.socket.off("new-message");
    this.props.socket.off("remove-message");
    this.props.socket.off("conversation-left");
    this.props.socket.off("conversation-removed");
    this.props.socket.off("conversation-kicked");
    this.props.socket.off("stream-end");
    this.props.socket.off("reply");
    this.props.socket.off("view");
    this.props.socket.off("views");
    this.props.socket.off("message-count");
    this.props.socket.off("pin");
    this.props.socket.off("block");
    this.props.socket.off("unblock");
    this.props.socket.off("account-deleted");

    this.props.socket.on("account-deleted", (data) => {
      try {
        this.props.account_deleted(data);
      } catch (err) {
        console.log("account-deleted error", err, data);
      }
    });
    this.props.socket.on("pin", (emission) => {
      try {
        if (
          this.props.profileInfo.emissions.items.find(
            (e) => e.emissionID === emission.emissionID
          )
        )
          this.props.update_emission(emission);
        else
          this.props.new_emission(h.setMetadata(emission, this.props.userInfo));
      } catch (err) {
        console.log("pin error", err, emission);
      }
    });
    this.props.socket.on("view", (data) => {
      try {
        this.props.view(data);
      } catch (err) {
        console.log("view error", err, data);
      }
    });
    this.props.socket.on("views", (data) => {
      try {
        this.props.views(data);
      } catch (err) {
        console.log("views error", err, data);
      }
    });
    this.props.socket.on("new-emission", (emission) => {
      try {
        if (this.props.profileInfo.user?._id === emission.userID)
          this.props.new_emission(h.setMetadata(emission, this.props.userInfo));
      } catch (err) {
        console.log("new-emission error", err, emission);
      }
    });
    this.props.socket.on("vote", (data) => {
      try {
        this.props.vote(data);
      } catch (err) {
        console.log("vote error", err, data);
      }
    });
    this.props.socket.on("like", (likeData) => {
      try {
        this.props.like_other({
          ...likeData,
          emission: h.setMetadata(likeData.emission, this.props.userInfo),
        });
      } catch (err) {
        console.log("like error", err, likeData);
      }
    });
    this.props.socket.on("signalboost", (data) => {
      try {
        this.props.signal_boost(data);
      } catch (err) {
        console.log("signalboost error", err, data);
      }
    });
    this.props.socket.on("remove-emission", (emission) => {
      try {
        if (emission.remove.user.userID !== this.props.userInfo._id) {
          if (
            h.checkJanny(this.props.userInfo) ||
            this.props.userInfo._id === emission.userID
          )
            this.props.update_emission(emission);
          else this.props.remove_emission(emission);
        }
      } catch (err) {
        console.log("remove-emission error", err, emission);
      }
    });
    this.props.socket.on("restore-emission", (emission) => {
      try {
        this.props.update_emission(emission);
      } catch (err) {
        console.log("restore-emission error", err, emission);
      }
    });
    this.props.socket.on("update-user", (data) => {
      try {
        this.props.user_edit(data);
      } catch (err) {
        console.log("update-user error", err, data);
      }
    });
    this.props.socket.on("ban", (data) => {
      try {
        this.props.ban_other(data);
      } catch (err) {
        console.log("ban error", err, data);
      }
    });

    /**
     * When banned user has their account restored, request all of their emissions that were removed as a result of that user being banned
     */
    this.props.socket.on("restore-user", async (userInfo) => {
      try {
        if (
          userInfo?.eventID &&
          this.state.socketEvents.find((e) => e === userInfo?.eventID)
        )
          return;
        else
          this.setState(
            (curr) => ({
              ...curr,
              socketEvents: userInfo?.eventID
                ? [...this.state.socketEvents, userInfo?.eventID]
                : this.state.socketEvents,
            }),
            async () => {
              try {
                const emissions = h.getUserProfileEmissions(
                  userInfo,
                  this.props.profileInfo
                );
                const getEmissions = () =>
                  new Promise((resolve) =>
                    axios
                      .post(
                        process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/by-id",
                        {
                          emissions: emissions,
                        },
                        {
                          headers: {
                            Authorization: this.props.token,
                          },
                        }
                      )
                      .then((res) => {
                        this.props.set_token(res.data.token);
                        resolve(res.data.emissions);
                      })
                      .catch((err) => {
                        console.log("restored emissions err", err);
                        setTimeout(async () => {
                          const restoredEmissions = await getEmissions();
                          resolve(restoredEmissions);
                        }, 1500);
                      })
                  );
                let restoredEmissions = [];
                if (emissions.length) restoredEmissions = await getEmissions();
                this.props.restore_user(userInfo, restoredEmissions);
              } catch (err) {
                console.log("restore-user setState callback error", err);
              }
            }
          );
      } catch (err) {
        console.log("restore-user error", err, userInfo);
      }
    });
    this.props.socket.on("private", (data) => {
      try {
        this.props.private_profile(data);
      } catch (err) {
        console.log("private error", err);
      }
    });

    /**
     * When user unprivates their account, request all of their emissions that were removed as a result of that user being banned
     */
    this.props.socket.on("unprivate", async (userInfo) => {
      try {
        if (
          userInfo?.eventID &&
          this.state.socketEvents.find((e) => e === userInfo?.eventID)
        )
          return;
        else
          this.setState(
            (curr) => ({
              ...curr,
              socketEvents: userInfo?.eventID
                ? [...this.state.socketEvents, userInfo?.eventID]
                : this.state.socketEvents,
            }),
            async () => {
              try {
                const emissions = h.getUserProfileEmissions(
                  userInfo,
                  this.props.profileInfo
                );
                const getEmissions = () =>
                  new Promise((resolve) =>
                    axios
                      .post(
                        process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/by-id",
                        {
                          emissions: emissions,
                        },
                        {
                          headers: {
                            Authorization: this.props.token,
                          },
                        }
                      )
                      .then((res) => {
                        this.props.set_token(res.data.token);
                        resolve(res.data.emissions);
                      })
                      .catch((err) => {
                        console.log("restored emissions err", err);
                        setTimeout(async () => {
                          const restoredEmissions = await getEmissions();
                          resolve(restoredEmissions);
                        }, 1500);
                      })
                  );
                let restoredEmissions = [];
                if (emissions.length) restoredEmissions = await getEmissions();
                this.props.unprivate(userInfo, restoredEmissions);
              } catch (err) {
                console.log("unprivate setState callback error", err);
              }
            }
          );
      } catch (err) {
        console.log("unprivate error", err);
      }
    });
    this.props.socket.on("disable-messages", (data) => {
      try {
        this.props.disable_messages(data);
      } catch (err) {
        console.log("disable-messages error", err, data);
      }
    });
    this.props.socket.on("stream-start", (data) => {
      try {
        this.props.stream_start(data);
      } catch (err) {
        console.log("stream-start error", err, data);
      }
    });
    this.props.socket.on("stream-end", (data) => {
      try {
        this.props.stream_end(data);
      } catch (err) {
        console.log("stream-end error", err, data);
      }
    });
    this.props.socket.on("reply", (data) => {
      try {
        if (
          !this.props.profileInfo.emissions.items.find(
            (e) => e.replyID === data.replyID
          )
        )
          this.props.reply(data);
      } catch (err) {
        console.log("socket reply error", err, data);
      }
    });
    this.props.socket.on("block", (data) => {
      try {
        this.props.block_other(data);
      } catch (err) {
        console.log("block error", err, data);
      }
    });

    /**
     * When user is unblocked by another user, request all of their emissions that were removed as a result of that user being banned
     */
    this.props.socket.on("unblock", async (userInfo) => {
      try {
        if (
          userInfo?.eventID &&
          this.state.socketEvents.find((e) => e === userInfo?.eventID)
        )
          return;
        else
          this.setState(
            (curr) => ({
              ...curr,
              socketEvents: userInfo?.eventID
                ? [...this.state.socketEvents, userInfo?.eventID]
                : this.state.socketEvents,
            }),
            async () => {
              try {
                const emissions = h.getUserProfileEmissions(
                  userInfo,
                  this.props.profileInfo
                );
                const getEmissions = () =>
                  new Promise((resolve) =>
                    axios
                      .post(
                        process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/by-id",
                        {
                          emissions: emissions,
                        },
                        {
                          headers: {
                            Authorization: this.props.token,
                          },
                        }
                      )
                      .then((res) => {
                        this.props.set_token(res.data.token);
                        resolve(res.data.emissions);
                      })
                      .catch((err) => {
                        console.log("restored emissions err", err);
                        setTimeout(async () => {
                          const restoredEmissions = await getEmissions();
                          resolve(restoredEmissions);
                        }, 1500);
                      })
                  );
                let restoredEmissions = [];
                if (emissions.length) restoredEmissions = await getEmissions();
                this.props.unblock_other(userInfo, restoredEmissions);
              } catch (err) {
                console.log("unblock setState callback error", err);
              }
            }
          );
      } catch (err) {
        console.log("unblock error", err);
      }
    });
    this.props.socket.on("message-count", (data) => {
      try {
        this.props.set_unread_messages(data);
      } catch (err) {
        console.log("message-count error", err, data);
      }
    });
  };

  toggleLoginModal = () =>
    this.setState((curr) => ({
      ...curr,
      loginModalShown: !this.state.loginModalShown,
    }));

  setLoginModal = (option) =>
    this.setState((curr) => ({
      ...curr,
      loginModalShown: option,
    }));

  newEmission = (emission, files) => {
    setTimeout(() => {
      const pinnedFound = this.props.profileInfo.emissions.items.find(
        (emission) => emission.pinned
      );
      document
        .getElementById(pinnedFound ? "scroll-top" : "feed-top")
        .scrollIntoView();
    }, 333);
    this.setState(
      (curr) => ({
        ...curr,
        reset: !this.state.reset,
      }),
      () => {
        switch (this.state.tabSelected) {
          case "emissionsAndReplies":
            this.setTab("emissions", () => this.props.new_emission(emission));
            break;
          case "media":
            if (!files)
              this.props.setTab("emissions", () =>
                this.props.new_emission(emission)
              );
            break;
          case "likes":
            this.props.setTab("emissions", () =>
              this.props.new_emission(emission)
            );
            break;
          default:
            this.props.new_emission(emission);
        }
      }
    );
  };

  render() {
    return (
      <motion.div
        className="page-container"
        transition={t.fade_out}
        initial={t.fade_out}
        animate={t.normalize}
        exit={t.fade_out_scale_1}
      >
        <MDBContainer className="pt-4 h-100">
          <LoginModal
            flavor="profile"
            modalShown={this.state.loginModalShown}
            setShowModal={this.setLoginModal}
            toggleShowModal={this.toggleLoginModal}
            tempAction={this.props.tempAction}
            profile={this.props.profileInfo?.user?.username}
            emissions={[
              ...new Set([
                ...this.props.profileInfo.emissions.items.map(
                  (e) => e.emissionID
                ),
                ...this.props.profileInfo.likes.items.map((e) => e.emissionID),
              ]),
            ].sort((a, b) => a.emissionID - b.emissionID)}
          />
          {this.props.profileInfo.loaded ? (
            <motion.div
              transition={t.fade_out}
              initial={t.fade_out}
              animate={t.normalize}
              exit={t.fade_out_scale_1}
            >
              {this.props.profileInfo?.user?._id === this.props.userInfo._id ? (
                <ProfileInfoSelf updating={this.state.updating} />
              ) : (
                <ProfileInfoOther
                  tabSelected={this.state.tabSelected}
                  toggleLoginModal={this.toggleLoginModal}
                  updating={this.state.updating}
                />
              )}
              {String(env.READ_ONLY) === "true" &&
              !h.checkChadmin(this.props.userInfo) ? (
                <></>
              ) : (
                <>
                  {!this.props.userInfo.ban.banned &&
                    this.props.userInfo.username?.toLowerCase() ===
                      this.profile && (
                      <motion.div
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                        className="mx-auto mt-4"
                      >
                        <MDBCard className="cards-full-width">
                          <MDBCardBody>
                            <h5 className="display-6 fs-4 m-0">
                              New{" "}
                              <span className="text-capitalize">
                                {env.EMISSION_NAME}
                              </span>
                            </h5>
                            <hr></hr>
                            <TextInput
                              tabSelected={this.state.tabSelected}
                              selectTab={this.setTab}
                              flavor="main"
                              maxChars={Number(env.MAX_EMISSION_CHARS)}
                              newEmission={this.newEmission}
                              key={this.state.reset}
                              label="Enter Text"
                            />
                          </MDBCardBody>
                        </MDBCard>
                      </motion.div>
                    )}
                </>
              )}

              {(!(
                this.props.profileInfo?.user?.ban?.banned ||
                this.props.profileInfo.blocksMe ||
                this.props.profileInfo.isBlocked ||
                this.props.profileInfo?.user?.private
              ) ||
                this.props.userInfo.username?.toLowerCase() === this.profile ||
                h.checkJanny(this.props.userInfo)) &&
                !this.props.profileInfo?.user?.deleted && (
                  <ProfileFeed
                    tabSelected={this.state.tabSelected}
                    selectTab={this.setTab}
                    toggleLoginModal={this.toggleLoginModal}
                    tabExit={this.state.tabExit}
                    tabEnter={this.state.tabEnter}
                    updating={this.state.updating}
                  />
                )}
            </motion.div>
          ) : (
            <>
              <h5 className="my-4 text-center display-6">@{this.profile}</h5>
              <div className="d-flex justify-content-center px-5">
                <LogoLoader />
              </div>
            </>
          )}
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  route,
  new_emission,
  vote,
  signal_boost,
  set_user,
  remove_emission,
  restore_emission,
  update_emission,
  like_other,
  set_profile,
  ban_other,
  new_follow,
  private_profile,
  disable_messages,
  stream_start,
  stream_end,
  reply,
  user_edit,
  restore_user,
  unprivate,
  block_other,
  unblock_other,
  view,
  views,
  add_message_profile,
  set_unread_messages,
  clear_profile,
  update_socket_state,
  account_deleted,
  set_token,
  set_cache,
  notify,
  following,
})(withRouter(Profile));
