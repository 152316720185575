import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import { set_user, set_token } from "../../redux/actions";
import Spinner from "../Spinner";
import Form from "./Form";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import t from "../../utilities/transitions";
import ValidateEmail from "../../pages/ValidateEmail";

class LoginModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the user is in the process of logging in/registering
       * formSelected: String - "login" | "create" - Login or create account
       */
      working: false,
      formSelected: "login",
    };
  }

  /**
   * When the user resizes the page, reset the modal body height
   */
  componentDidMount() {
    window.addEventListener("resize", this.setModalBodyHeight);
  }

  /**
   * When modal is displayed, set body height
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.modalShown &&
      !this.props.modalShown &&
      this.props.flavor === "virgil-chad" &&
      !this.props.userInfo._id
    )
      this.props.route("/");
    if (prevProps.modalShown !== this.props.modalShown) {
      this.setModalBodyHeight();
    }
    if (prevProps.userInfo._id !== this.props.userInfo._id) {
      if (this.props.modalShown && !this.props.skipHide) {
        this.props.toggleShowModal();
      }
      this.setState((curr) => ({
        ...curr,
        formSelected: "login",
      }));
    }
  }

  /**
   * remove event listeners
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.setModalBodyHeight);
  }

  /**
   * Triggered when the user changes the form
   * Changes the form, then sets the modal body height
   */
  changeForm = () => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          formSelected:
            this.state.formSelected === "login" ? "create" : "login",
        }),
        () => setTimeout(this.setModalBodyHeight, 500)
      );
  };

  setWorking = (option) =>
    this.setState(
      (curr) => ({
        ...curr,
        working: option,
      }),
      () => setTimeout(this.setModalBodyHeight, 500)
    );

  /**
   * Set the body height to that of its immediate child
   * Causes the body to smoothly grow or shrink instead of pop
   */
  setModalBodyHeight = () => {
    const body = document.getElementById(
      "login-modal-body-" + this.props.flavor
    );
    if (body)
      body.style.height = `${
        document.getElementById("login-modal-body-child-" + this.props.flavor)
          .clientHeight
      }px`;
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={() => {
              if (!this.state.working && this.props.flavor !== "virgil-chad")
                this.props.toggleShowModal();
            }}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                    {this.props.verificationDetails ? (
                      "Email Verification Required"
                    ) : (
                      <>
                        {this.props.flavor === "virgil-chad" ? (
                          "Please login to continue"
                        ) : (
                          <>
                            {this.state.formSelected === "login" ? (
                              <motion.div
                                className="m-0"
                                transition={t.transition}
                                exit={t.fade_out_minimize}
                                animate={t.normalize}
                                initial={t.fade_out_minimize}
                              >
                                Login to {this.props.tempAction?.label || ""}
                              </motion.div>
                            ) : (
                              <motion.p
                                className="m-0"
                                transition={t.transition}
                                exit={t.fade_out_minimize}
                                animate={t.normalize}
                                initial={t.fade_out_minimize}
                              >
                                Create an Account to{" "}
                                {this.props.tempAction?.label || ""}
                              </motion.p>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </MDBModalTitle>
                  {this.props.flavor === "virgil-chad" ? (
                    <></>
                  ) : (
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={this.props.toggleShowModal}
                    ></MDBBtn>
                  )}
                </MDBModalHeader>
                {this.props.verificationDetails ? (
                  <ValidateEmail fromLoginModal={true} />
                ) : (
                  <div
                    id={"login-modal-body-" + this.props.flavor}
                    className={`transition-25 overflow-y-${
                      this.props.screenDimensions.width >
                      this.props.screenDimensions.modalBreak
                        ? "hidden"
                        : "auto"
                    }`}
                  >
                    <MDBModalBody
                      id={"login-modal-body-child-" + this.props.flavor}
                    >
                      <StaticRouter location={this.state.formSelected}>
                        <AnimatePresence exitBeforeEnter>
                          <Switch key={this.state.formSelected}>
                            <Route exact path=":form">
                              <Form
                                form={this.state.formSelected}
                                setSubmit={(f) => (this.submit = f)}
                                modalShown={this.props.modalShown}
                                setShowModal={this.props.setShowModal}
                                toggleShowModal={this.props.toggleShowModal}
                                setWorking={this.setWorking}
                                key={
                                  this.state.formSelected +
                                  String(this.props.userInfo._id)
                                }
                                notify={this.props.notify}
                                emissions={this.props.emissions}
                                profile={this.props.profile}
                                flavor={this.props.flavor}
                                setEmissions={this.props.setEmissions}
                                setModalBodyHeight={this.setModalBodyHeight}
                                changeForm={this.changeForm}
                                token={this.props.token}
                                set_token={this.props.set_token}
                              />
                            </Route>
                          </Switch>
                        </AnimatePresence>
                      </StaticRouter>
                    </MDBModalBody>
                  </div>
                )}
                {this.props.verificationDetails ? (
                  <MDBModalFooter>
                    <MDBBtn
                      className="bg-gray"
                      color="dark"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                ) : (
                  <MDBModalFooter className="d-flex justify-content-between login-modal-footer">
                    <MDBBtn onClick={this.changeForm} color="primary">
                      {this.state.formSelected === "login" ? (
                        <>
                          <i className="fas fa-user-plus me-2"></i>
                          Create Account
                        </>
                      ) : (
                        <>
                          <i className="fas fa-sign-in-alt me-2"></i>
                          Login
                        </>
                      )}
                    </MDBBtn>
                    <div className="d-flex">
                      {this.state.working ? (
                        <MDBBtn
                          rippleColor="light"
                          color="success"
                          disabled
                          className="me-2"
                        >
                          <Spinner size="sm" className="me-2" />
                          Working
                        </MDBBtn>
                      ) : (
                        <MDBBtn
                          onClick={this.submit}
                          rippleColor="light"
                          color="success"
                          className="me-2"
                        >
                          <i className="fas fa-paper-plane me-2"></i>Submit
                        </MDBBtn>
                      )}
                      <MDBBtn
                        className="bg-gray"
                        color="dark"
                        onClick={this.props.toggleShowModal}
                      >
                        Close
                      </MDBBtn>
                    </div>
                  </MDBModalFooter>
                )}
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    screenDimensions: state.screenDimensions,
    userInfo: state.userInfo,
    verificationDetails: state.verificationDetails,
  };
};

export default connect(mapStateToProps, { set_user, set_token })(LoginModal);
