import env from "../../env";
import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import SampleEmission from "../emission/SampleEmission";
import axios from "axios";
import { route, set_token } from "../../redux/actions";
import TextInput from "../textInput/TextInput";

const maxChars = Number(env.MAX_EMISSION_CHARS);
const modalBreak = 991;

class ReplyModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the reply is in the process of being submitted
       */
      working: false,
      reset: false,
    };
  }

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Validate files
   * Get captcha key
   * Make request to server
   * Hide modal
   * Navigate to new comment
   */
  submit = (files) => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        async () => {
          try {
            this.forceParse();
            if (
              files.reduce((prev, curr) => prev + curr.size, 0) >
              Number(env.MAX_TOTAL_FILE_SIZE)
            )
              throw `Max total file size exceeded. (Max: ${Math.round(
                Number(env.MAX_TOTAL_FILE_SIZE) / (1024 * 1024)
              )}MB)`;
            const emissionData = document.getElementById("input-reply");
            const length = String(emissionData.textContent)
              .split("")
              .filter((c) => {
                const checkWhiteSpace = c.match(/[\s]/);
                if (!checkWhiteSpace) return true;
                else {
                  return [" ", "\n"].indexOf(c) > -1;
                }
              }).length;
            if (
              (!emissionData.textContent ||
                emissionData.innerHTML === "<div><p><br /></p></div>") &&
              !files.length
            )
              throw "Please enter text or at least one file";
            if (length > maxChars)
              throw `Character limit exceeded (Max: ${maxChars} characters)`;
            const fd = new FormData();
            const captchaKey = await this.getRecaptcha();
            fd.append("captchaKey", captchaKey);
            fd.append("emission", emissionData.innerHTML);
            fd.append("replyID", this.props.emission.emissionID);
            files.forEach((file) => {
              fd.append("files", file.file, file.name);
              if (file.file.type.includes("video"))
                fd.append("thumbnails", file.thumbnail, file.name);
            });
            axios
              .post(process.env.REACT_APP_LAMBDA_EMISSIONS + "/reply", fd, {
                headers: {
                  Authorization: this.props.token,
                },
              })
              .then((res) => {
                this.props.toggleShowModal();
                this.props.set_token(res.data.token);
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                    reset: !this.state.reset,
                  }),
                  () => {
                    this.props.route(`/e/${res.data.emissionID}`);
                  }
                );
              })
              .catch((err) =>
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                  }),
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later.");
                  }
                )
              );
          } catch (err) {
            this.setState(
              (curr) => ({
                ...curr,
                working: false,
              }),
              () => alert(err)
            );
          }
        }
      );
  };

  render() {
    return (
      <MDBModal
        open={this.props.modalShown}
        staticBackdrop
        onClosePrevented={() => {
          if (!this.state.working) this.props.toggleShowModal();
        }}
        tabIndex="-1"
      >
        <MDBModalDialog
          size={
            this.props.screenDimensions.width > modalBreak ? "xl" : "fullscreen"
          }
        >
          <MDBModalContent>
            {this.props.emission ? (
              <>
                <MDBModalHeader>
                  <MDBModalTitle>
                    Replying to{" "}
                    <span className="text-pkmn">
                      #{this.props.emission.emissionID}
                    </span>
                  </MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <TextInput
                    key={this.props.emission._id + String(this.state.reset)}
                    submit={this.submit}
                    working={this.state.working}
                    flavor="reply"
                    maxChars={maxChars}
                    label="Reply"
                    setForceParse={(f) => (this.forceParse = f)}
                  />
                  <SampleEmission
                    emission={this.props.emission}
                    toggleModal={this.props.toggleShowModal}
                    test="hello"
                  />
                </MDBModalBody>
              </>
            ) : (
              <></>
            )}
            {this.props.screenDimensions.width <= modalBreak ? (
              <MDBModalFooter>
                <MDBBtn
                  className="bg-gray"
                  color="dark"
                  onClick={this.props.toggleShowModal}
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            ) : (
              <></>
            )}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route, set_token })(ReplyModal);
