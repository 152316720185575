import env from "../env";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import {
  route,
  set_token,
  set_virgil_chad,
  virgil_chad_load_more,
} from "../redux/actions";
import t from "../utilities/transitions";
import {
  MDBContainer,
  MDBTextArea,
  MDBValidation,
  MDBValidationItem,
  MDBBtn,
} from "mdb-react-ui-kit";
import ChatBox from "./messages/ChatBox";
import axios from "axios";
import { v4 as uuid } from "uuid";
import LoginModal from "../components/loginModal/LoginModal";
import h from "../utilities/helpers";

/**
 * virgilChad collection
 *
 * {
 *  _id
 *  timestamp
 *  to: ref Users._id | 'virgil' | 'chad'
 *  id: uuid()
 *  removed: false
 *  message: --- serverKey encrypted ---
 * }
 *
 * Server tags:
 * {
 *  encrypted: boolean
 * }
 */

const VirgilAndChad = ({
  captchaReady,
  chad,
  route,
  set_virgil_chad,
  set_token,
  token,
  userInfo,
  virgil,
  virgil_chad_load_more,
}) => {
  const [askee, setAskee] = useState("virgil");
  const [loadingMore, setLoadingMore] = useState([]);
  const [asking, setAsking] = useState([]);
  const [text, setText] = useState("");
  const [loginModalShown, setLoginModalShown] = useState(false);
  const [canRedirect, setCanRedirect] = useState(false);

  const toggleLoginModal = () => setLoginModalShown(!loginModalShown);

  useEffect(() => {
    if (!userInfo._id) setTimeout(() => toggleLoginModal(), 666);
    document
      .getElementById("input-virgil-chad")
      .setCustomValidity("Please enter a query");
  }, []);

  useEffect(() => {
    if (loginModalShown && !userInfo._id) {
      setCanRedirect(true);
    } else if (canRedirect && !loginModalShown) {
      route("/");
    }
  }, [loginModalShown]);

  const loadMore = (askee) => {
    const messageIDs = (askee === "virgil" ? virgil : chad).messages.map(
      (message) => message.id
    );
    setLoadingMore((curr) => [...new Set([...curr, askee])]);
    axios
      .post(
        process.env.REACT_APP_LAMBDA_SEARCH + "/virgil-chad-load-more",
        {
          askee,
          messageIDs,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        set_token(res.data.token);
        virgil_chad_load_more({
          askee,
          messages: res.data.newMessages,
        });
      })
      .catch((err) => {
        console.log("loadMore error", err);
        alert(
          "An error occurred while loading more messages. Please try again later."
        );
      })
      .finally(() => {
        setLoadingMore((curr) => curr.filter((c) => c !== askee));
      });
  };

  const changeHandler = (e) => {
    setText(e.target.value);
    const element = document.getElementById("input-virgil-chad");
    if (element) {
      if (!e.target.value.length) {
        element.setCustomValidity("Please enter a query");
      } else if (e.target.value.length > 10000) {
        element.setCustomValidity(
          "Query must be shorter than 10,000 characters"
        );
      } else document.getElementById("input-virgil-chad").setCustomValidity("");
    }
  };

  const getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  const ask = async (toAsk) => {
    try {
      document
        .getElementById("form-virgil-chad")
        .classList.add("was-validated");
      if (text.length && text.length <= 10000) {
        setAsking((curr) => [...new Set([...curr, ...toAsk])]);
        for (let a = 0; a < toAsk.length; a++) {
          const askee = toAsk[a];
          const captchaKey = await getRecaptcha();
          const message = {
            timestamp: new Date(),
            to: askee,
            id: uuid(),
            removed: false,
            message: text,
            decrypted: true,
            from: userInfo._id,
          };
          set_virgil_chad({
            askee,
            message,
          });
          axios
            .post(
              process.env.REACT_APP_LAMBDA_SEARCH + "/virgil-chad",
              {
                askee,
                captchaKey,
                message,
              },
              {
                headers: {
                  Authorization: token,
                },
              }
            )
            .then((res) => {
              set_token(res.data.token);
              set_virgil_chad({
                askee,
                message: res.data.message,
              });
            })
            .catch((err) => {
              console.log(
                "err",
                err,
                err.response,
                err.response?.data,
                err.response?.data?.error
              );
              alert(
                err.response?.data?.error ||
                  "An error occurred with " +
                    (askee === "virgil" ? "Virgil" : "Chad") +
                    "'s query"
              );
            })
            .finally(() =>
              setAsking((curr) => curr.filter((a) => a !== askee))
            );
        }
        setText("");
        document
          .getElementById("form-virgil-chad")
          .classList.remove("was-validated");
        document
          .getElementById("input-virgil-chad")
          .setCustomValidity("Please enter a query");
      }
    } catch (err) {
      console.log("ask error", err);
    }
  };
  return (
    <motion.div
      transition={t.transition}
      initial={t.fade_out}
      animate={t.normalize}
      exit={t.fade_out_scale_1}
      className="pt-2 h-100"
    >
      <LoginModal
        modalShown={loginModalShown}
        toggleShowModal={toggleLoginModal}
        flavor="virgin-chad"
      />
      <MDBContainer
        className="h-100 d-flex flex-column message-container"
        fluid
      >
        <div className="row h-0 flex-grow-1">
          <div
            className={`col-12 col-lg-6 h-100 ${
              askee === "virgil" ? "" : "askee-hide"
            }`}
          >
            <ChatBox
              conversation={{
                ...virgil,
                starter: userInfo._id,
                users: [
                  {
                    username: "virgil",
                  },
                ],
              }}
              loadingMore={loadingMore.includes("virgil")}
              loadMore={loadMore}
              virgilChad="virgil"
              toggleVirgilChad={() =>
                setAskee(askee === "virgil" ? "chad" : "virgil")
              }
              askee={askee}
            />
          </div>
          <div
            className={`col-12 col-lg-6 h-100 ${
              askee === "chad" ? "" : "askee-hide"
            }`}
          >
            <ChatBox
              conversation={{
                ...chad,
                starter: userInfo._id,
              }}
              loadingMore={loadingMore.includes("chad")}
              loadMore={loadMore}
              virgilChad="chad"
              toggleVirgilChad={() =>
                setAskee(askee === "virgil" ? "chad" : "virgil")
              }
              askee={askee}
            />
          </div>
        </div>

        {String(env.READ_ONLY) === "true" && !h.checkChadmin(userInfo) ? (
          <div
            style={{ height: "4.5rem" }}
            className="d-flex justify-content-center align-items-center"
          >
            <h5 className="m-0">
              The administrator of this instance has set it to read-only
            </h5>
          </div>
        ) : (
          <>
            {String(env.VIRGIL_CHAD_LOCKED) === "true" ? (
              <div
                style={{ height: "4.5rem" }}
                className="d-flex justify-content-center align-items-center"
              >
                <h5 className="m-0">
                  Max Virgil/Chad tokens for this free tier instance has been
                  reached or exceeded
                </h5>
              </div>
            ) : (
              <>
                {!userInfo._id ? (
                  <div
                    style={{ height: "4.5rem" }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <h5 className="m-0">
                      You must be logged in to use this feature
                    </h5>
                  </div>
                ) : (
                  <>
                    <MDBValidation
                      id="form-virgil-chad"
                      className="row pt-3"
                      method="dialog"
                      name="form-virgil-chad"
                    >
                      <MDBValidationItem
                        className="col-12 pb-4"
                        feedback={
                          !text.length
                            ? "Please enter a query"
                            : text.length > 10000
                            ? "Please enter a query that is less than 10,000 characters"
                            : ""
                        }
                        invalid={true}
                      >
                        <MDBTextArea
                          name="input-virgil-chad"
                          onChange={changeHandler}
                          id="input-virgil-chad"
                          label="Query"
                          style={{ minHeight: "5rem" }}
                          value={text}
                        />
                      </MDBValidationItem>
                    </MDBValidation>
                    <div className="row">
                      <div style={{ height: "4.5rem" }} className="col-4 px-0">
                        <MDBBtn
                          disabled={asking.includes("virgil")}
                          className="h-100 w-100 border-radius-0 box-shadow-none d-flex justify-content-center align-items-center"
                          color="primary"
                          onClick={() => ask(["virgil"])}
                        >
                          <img
                            src="/assets/images/virgil-head.png"
                            alt="Virgil Icon"
                            className={`d-block me-2 ${
                              asking.includes("virgil") ? "opacity-flash" : ""
                            }`}
                            style={{ height: "16px" }}
                          />
                          Ask Virgil
                        </MDBBtn>
                      </div>
                      <div style={{ height: "4.5rem" }} className="col-4 px-0">
                        <MDBBtn
                          disabled={asking.length}
                          className="h-100 w-100 border-radius-0 box-shadow-none d-flex justify-content-center align-items-center"
                          color="success"
                          onClick={() => ask(["chad", "virgil"])}
                        >
                          <img
                            src="/assets/images/virgil-head.png"
                            alt="Virgil Icon"
                            className={`d-block me-2 ${
                              asking.includes("virgil") ? "opacity-flash" : ""
                            }`}
                            style={{ height: "16px" }}
                          />
                          Ask Both
                          <img
                            src="/assets/images/chad-head.png"
                            alt="Chad Icon"
                            className={`d-block ms-2 ${
                              asking.includes("chad") ? "opacity-flash" : ""
                            }`}
                            style={{ height: "16px" }}
                          />
                        </MDBBtn>
                      </div>
                      <div style={{ height: "4.5rem" }} className="col-4 px-0">
                        <MDBBtn
                          disabled={asking.includes("chad")}
                          className="h-100 w-100 border-radius-0 box-shadow-none d-flex justify-content-center align-items-center"
                          color="danger"
                          onClick={() => ask(["chad"])}
                        >
                          Ask Chad
                          <img
                            src="/assets/images/chad-head.png"
                            alt="Chad Icon"
                            className={`d-block ms-2 ${
                              asking.includes("chad") ? "opacity-flash" : ""
                            }`}
                            style={{ height: "16px" }}
                          />
                        </MDBBtn>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </MDBContainer>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  captchaReady: state.captchaReady,
  chad: state.chad,
  token: state.token,
  userInfo: state.userInfo,
  virgil: state.virgil,
});

export default connect(mapStateToProps, {
  route,
  set_token,
  set_virgil_chad,
  virgil_chad_load_more,
})(VirgilAndChad);
