import env from "../../env";
import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import h from "../../utilities/helpers";
import t from "../../utilities/transitions";
import axios from "axios";
import {
  route,
  set_poll_modal,
  set_user,
  set_click_pass,
  set_token,
  notify,
  signal_boost,
  like_self,
} from "../../redux/actions";
import LoginModal from "../loginModal/LoginModal";
import FileModal from "../fileModal/FileModal";
import ReplyModal from "../actionModals/ReplyModal";
import RemoveModal from "../actionModals/RemoveModal";
import RestoreModal from "../actionModals/RestoreModal";
import ReportModal from "../actionModals/ReportModal";
import SignalBoostModal from "../actionModals/SignalBoostModal";
import EmissionList from "./EmissionList";
import Url from "url-parse";
import { accountDeletedEmission } from "../../utilities/constants";
import { v4 as uuid } from "uuid";

class EmissionPage extends React.Component {
  constructor(props) {
    super();
    this.state = props.initialEmissionPageState
      ? props.initialEmissionPageState
      : {
          /**
           * loaded: Boolean - Whether the initial emission data has been loaded
           * emissions: Array - All emissions tied to the main emission which have been loaded (replies, signalboosted, etc)
           * polls: Array - List of polls that the user is voting in
           * pollsSubmitting: Array - List of polls that are in the process of having their votes submitted
           * loginModalShown: Boolean - Whether the login modal is shown
           * fileModalShown: Boolean - Whether the file modal is shown
           * signalBoostModalShown: Boolean - Whether the signal boost modal is shown
           * replyModalShown: Boolean - Whether the reply modal is shown
           * removeModalShown: Boolean - Whether the remove modal is shown
           * reportModalShown: Boolean - Whether the report modal is shown
           * restoreModalShown: Boolean - Whether the restore modal is shown
           * fileSelected: false | Object - The file currently selected
           * fileList: Array - List of files on the emission that the fileSelected belongs to
           * emissionSelected: false | Object - The emission currently selected
           * emissionSignalBoosting: false | Object - The emission currently signal boosting
           * emissionReplying: false | Object - The emission currently replying
           * emissionCopied: false | Number - EmissionID of Emission copied
           * emissionRemoving: false | Object - The emission currently removing
           * emissionReporting: false | Object - The emission currently reporting
           * emissionRestoring: false | Object - The emission currently restoring
           * socketConnected: Boolean - Whether the socket is currently connected
           * tempAction: false | Object - Temporary action data that must be executed upon logging in
           */
          loaded: false,
          emissions: props.emissions || [],
          polls: [],
          pollsSubmitting: [],
          loginModalShown: false,
          fileModalShown: false,
          signalBoostModalShown: false,
          replyModalShown: false,
          removeModalShown: false,
          reportModalShown: false,
          restoreModalShown: false,
          fileSelected: false,
          fileList: [],
          emissionSelected: false,
          emissionSignalBoosting: false,
          emissionReplying: false,
          emissionCopied: false,
          emissionRemoving: false,
          emissionReporting: false,
          emissionRestoring: false,
          socketConnected: false,
          tempAction: false,
          socketEvents: [],
        };
    this.flavor = props.flavor;
  }

  /**
   * If emissionID is not numberic, redirect to not found
   * Connect socket
   * Load emission data
   */
  componentDidMount() {
    try {
      if (this.props.setGetEmissionPageState) {
        this.getEmissionPageState = this.getEmissionPageState.bind(this);
        this.props.setGetEmissionPageState(this.getEmissionPageState);
      }
    } catch (err) {
      console.log("EmissionPage", err);
    }

    if (this.props.socket)
      this.setState(
        (curr) => ({
          ...curr,
          socketConnected: true,
        }),
        () => {
          this.initializeSocket();
          if (this.props.emissions)
            this.setState(
              (curr) => ({
                ...curr,
                loaded: true,
              }),
              () => this.handleSocketEvents(this.props.socketStore)
            );
          else this.load();
        }
      );
    else if (this.props.emissions)
      this.setState(
        (curr) => ({
          ...curr,
          loaded: true,
        }),
        () => this.handleSocketEvents(this.props.socketStore)
      );
    else this.load();
  }

  /**
   * If socket is reconnected, re-initialize
   * If user logs in or out, reset polls and set metadata on emissions
   * If user logs in with temp action, trigger temp action
   */
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.socket && this.props.socket) this.initializeSocket();
    if (prevProps.userInfo._id && !this.props.userInfo._id) {
      this.setState((curr) => ({
        ...curr,
        polls: [],
        pollsSubmitting: [],
        emissions: this.state.emissions.map((e) => h.setMetadata(e, false)),
      }));
    }

    if (!prevProps.userInfo._id && this.props.userInfo._id) {
      this.setState(
        (curr) => ({
          ...curr,
          loginModalShown: false,
        }),
        () => {
          if (this.state.tempAction) this.triggerTempAction();
        }
      );
    }

    if (!prevProps.loaded && this.props.loaded) {
      this.setState((curr) => ({
        ...curr,
        loaded: true,
      }));
    }

    if (
      this.props.updateEmissions &&
      JSON.stringify(prevState.emissions) !==
        JSON.stringify(this.state.emissions)
    ) {
      this.props.updateEmissions(this.state.emissions);
    }

    if (
      prevProps.emissions?.length !== this.props.emissions?.length &&
      ["feed-following", "feed-popular", "feed-recent"].includes(this.flavor)
    )
      this.load(true);

    const emissionSelected = this.getEmissionSelected(this.state.emissions);
    const prevEmissionSelected = this.getEmissionSelected(prevState.emissions);
    if (
      emissionSelected?.files &&
      prevEmissionSelected?.files &&
      JSON.stringify(emissionSelected.files) !==
        JSON.stringify(prevEmissionSelected.files)
    ) {
      this.setState((curr) => ({
        ...curr,
        fileSelected:
          emissionSelected.files[
            this.state.fileList.indexOf(this.state.fileSelected)
          ],
        fileList: emissionSelected.files,
      }));
    }
  }

  componentWillUnmount() {
    if (this.props.socket) {
      this.props.socket.off("new-emission");
      this.props.socket.off("vote");
      this.props.socket.off("like");
      this.props.socket.off("signalboost");
      this.props.socket.off("update-user");
      this.props.socket.off("remove-emission");
      this.props.socket.off("restore-emission");
      this.props.socket.off("ban");
      this.props.socket.off("restore-user");
      this.props.socket.off("private");
      this.props.socket.off("reply");
      this.props.socket.off("view");
      this.props.socket.off("views");
      this.props.socket.off("unprivate");
      this.props.socket.off("block");
      this.props.socket.off("unblock");
      this.props.socket.off("account-deleted");
    }
  }

  getEmissionPageState = () => this.state;

  load = async (force) => {
    if (
      force ||
      !this.state.emissions?.length ||
      this.props.initialEmissionPageState
    ) {
      try {
        const initialData = await this.props.load();
        this.setState(
          (curr) => ({
            ...curr,
            emissions: h.updateArrayItems(
              this.state.emissions,
              initialData.emissions
            ),
          }),
          () => {
            this.setState(
              (curr) => ({
                ...curr,
                loaded: true,
              }),
              () => this.handleSocketEvents(this.props.socketStore)
            );
          }
        );
      } catch (err) {
        console.log("load error", err);
      }
    } else console.log("Already loaded" + this.state.emissions);
  };

  handleSocketEvents = async (socketEvents) => {
    if (socketEvents?.length) {
      const subsequentEvents = socketEvents.filter((event, e) => e);
      const socketEvent = socketEvents[0];
      try {
        let emission;
        let data;
        let userInfo;
        let emissions;
        let getEmissions;
        let restoredEmissions;
        if (
          socketEvent.data?.eventID &&
          this.state.socketEvents.find((e) => e === socketEvent.data?.eventID)
        )
          return;
        switch (socketEvent.event) {
          case "view":
            this.view(socketEvent.data, subsequentEvents);
            break;
          case "views":
            this.views(socketEvent.data, subsequentEvents);
            break;
          case "new-emission":
            this.newEmission(socketEvent.data, subsequentEvents);
            break;
          case "vote":
            this.voteOther(socketEvent.data, false, subsequentEvents);
            break;
          case "like":
            const likeData = socketEvent.data;
            this.likeOther(
              {
                ...likeData,
                emission: h.setMetadata(likeData.emission, this.props.userInfo),
              },
              subsequentEvents
            );
            break;
          case "signalboost":
            data = socketEvent.data;
            if (
              data?.eventID &&
              this.state.socketEvents.find((e) => e === data?.eventID)
            )
              return;
            else
              this.setState(
                (curr) => ({
                  ...curr,
                  socketEvents: data?.eventID
                    ? [...this.state.socketEvents, data?.eventID]
                    : this.state.socketEvents,
                }),
                () =>
                  this.signalBoostOther(
                    data.emissionID,
                    data.boostID,
                    false,
                    subsequentEvents
                  )
              );

            break;
          case "remove-emission":
            emission = socketEvent.data;
            if (
              h.checkJanny(this.props.userInfo) ||
              this.props.userInfo._id === emission.userID
            )
              this.updateEmission(emission, false, subsequentEvents);
            else this.removeEmission(emission, subsequentEvents);
            break;
          case "restore-emission":
            emission = socketEvent.data;
            this.updateEmission(emission, false, subsequentEvents);
            break;
          case "update-user":
            this.userEdit(socketEvent.data, subsequentEvents);
            break;
          case "ban":
            this.ban(socketEvent.data, subsequentEvents);
            break;
          case "restore-user":
            userInfo = socketEvent.data;
            if (
              data?.eventID &&
              this.state.socketEvents.find((e) => e === data?.eventID)
            )
              return;
            else
              this.setState(
                (curr) => ({
                  ...curr,
                  socketEvents: userInfo?.eventID
                    ? [...this.state.socketEvents, userInfo?.eventID]
                    : this.state.socketEvents,
                }),
                async () => {
                  try {
                    emissions = h.getThreadEmissions(
                      userInfo,
                      this.state.emissions
                    );
                    getEmissions = () =>
                      new Promise((resolve) =>
                        axios
                          .post(
                            process.env.REACT_APP_LAMBDA_API_EMISSIONS +
                              "/by-id",
                            {
                              emissions: emissions,
                            },
                            {
                              headers: {
                                Authorization: this.props.token,
                              },
                            }
                          )
                          .then((res) => {
                            this.props.set_token(res.data.token);
                            resolve(res.data.emissions);
                          })
                          .catch((err) => {
                            console.log("restored emissions err", err);
                            setTimeout(async () => {
                              const restoredEmissions = await getEmissions();
                              resolve(restoredEmissions);
                            }, 1500);
                          })
                      );
                    restoredEmissions = [];
                    if (emissions.length)
                      restoredEmissions = await getEmissions();
                    this.restoreUser(restoredEmissions, subsequentEvents);
                  } catch (err) {
                    console.log("restore-user setState callback error", err);
                  }
                }
              );

            break;
          case "private":
            this.privateProfile(socketEvent.data, subsequentEvents);
            break;
          case "unprivate":
            userInfo = socketEvent.data;
            this.setState(
              (curr) => ({
                ...curr,
                socketEvents: userInfo?.eventID
                  ? [...this.state.socketEvents, data?.eventID]
                  : this.state.socketEvents,
              }),
              async () => {
                try {
                  emissions = h.getThreadEmissions(
                    userInfo,
                    this.state.emissions
                  );
                  getEmissions = () =>
                    new Promise((resolve) =>
                      axios
                        .post(
                          process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/by-id",
                          {
                            emissions: emissions,
                          },
                          {
                            headers: {
                              Authorization: this.props.token,
                            },
                          }
                        )
                        .then((res) => {
                          this.props.set_token(res.data.token);
                          resolve(res.data.emissions);
                        })
                        .catch((err) => {
                          console.log("restored emissions err", err);
                          setTimeout(async () => {
                            const restoredEmissions = await getEmissions();
                            resolve(restoredEmissions);
                          }, 1500);
                        })
                    );
                  let restoredEmissions = [];
                  if (emissions.length)
                    restoredEmissions = await getEmissions();
                  this.unprivate(restoredEmissions, subsequentEvents);
                } catch (err) {
                  console.log("unprivate setState callback error", err);
                }
              }
            );

            break;
          case "reply":
            this.replyOther(socketEvent.data, subsequentEvents);
            break;
          case "block":
            this.blockOther(socketEvent.data, subsequentEvents);
            break;
          case "account-deleted":
            this.accountDeleted(socketEvent.data, subsequentEvents);
            break;
          case "unblock":
            userInfo = socketEvent.data;
            this.setState(
              (curr) => ({
                ...curr,
                socketEvents: userInfo?.eventID
                  ? [...this.state.socketEvents, userInfo?.eventID]
                  : this.state.socketEvents,
              }),
              async () => {
                try {
                  emissions = h.getThreadEmissions(
                    userInfo,
                    this.state.emissions
                  );
                  getEmissions = () =>
                    new Promise((resolve) =>
                      axios
                        .post(
                          process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/by-id",
                          {
                            emissions: emissions,
                          },
                          {
                            headers: {
                              Authorization: this.props.token,
                            },
                          }
                        )
                        .then((res) => {
                          this.props.set_token(res.data.token);
                          resolve(res.data.emissions);
                        })
                        .catch((err) => {
                          console.log("restored emissions err", err);
                          setTimeout(async () => {
                            const restoredEmissions = await getEmissions();
                            resolve(restoredEmissions);
                          }, 1500);
                        })
                    );
                  restoredEmissions = [];
                  if (emissions.length)
                    restoredEmissions = await getEmissions();
                  this.unblockOther(restoredEmissions, subsequentEvents);
                } catch (err) {
                  console.log("unblock setState callback error", err);
                }
              }
            );

            break;
          default:
            console.log("oob socket event", socketEvent);
            this.handleSocketEvents(subsequentEvents);
        }
      } catch (err) {
        console.log("error", socketEvent);
        this.handleSocketEvents(subsequentEvents);
      }
    }
  };

  loadMore = (...args) => {
    this.props.loadMore(...args, this.state.emissions, (data) => {
      this.setState(
        (curr) => ({
          ...curr,
          ...data,
          emissions: [...data.emissions, ...curr.emissions],
        }),
        () => {
          if (this.flavor === "feed-recent")
            document
              .getElementById(
                "emission-" +
                  data.emissions.sort((a, b) => b.emissionID - a.emissionID)[0]
                    .emissionID
              )
              .scrollIntoView();
        }
      );
    });
  };

  checkNewer = (...args) => {
    this.props.checkNewer(...args, this.state.emissions, (data) => {
      this.setState((curr) => ({
        ...curr,
        ...data,
        emissions: [...data.emissions, ...curr.emissions],
      }));
    });
  };

  seeMore = (...args) => {
    this.props.seeMore(...args, (data) => {
      this.setState((curr) => ({
        ...curr,
        ...data,
        emissions: [...data.emissions, ...curr.emissions],
      }));
    });
  };

  /**
   * Triggered when the user logs in with a temp action
   * Executes the temp action
   */
  triggerTempAction = () => {
    const tempAction = JSON.parse(JSON.stringify(this.state.tempAction));
    this.setState(
      (curr) => ({
        ...curr,
        tempAction: false,
      }),
      () => {
        if (this.props.userInfo.ban.banned)
          setTimeout(
            () =>
              this.props.notify(
                <i className="fas fa-gavel me-2 text-danger"></i>,
                "You are banned"
              ),
            250
          );
        else {
          let e = false;
          switch (tempAction.label) {
            case "Reply":
              this.state.emissions.forEach((emission) => {
                if (emission.emissionID === tempAction.emission.emissionID)
                  e = emission;
                if (
                  emission.signalBoost &&
                  emission.signalBoost.emissionID ===
                    tempAction.emission.emissionID
                )
                  e = emission.signalBoost;

                if (emission.replyEmission) {
                  if (
                    emission.replyEmission.emissionID ===
                    tempAction.emission.emissionID
                  )
                    e = emission.replyEmission;
                  if (
                    emission.replyEmission.signalBoost &&
                    emission.replyEmission.signalBoost.emissionID ===
                      tempAction.emission.emissionID
                  )
                    e = emission.replyEmission.signalBoost;

                  if (emission.replyEmission.replyEmission) {
                    if (
                      emission.replyEmission.replyEmission.emissionID ===
                      tempAction.emission.emissionID
                    )
                      e = emission.replyEmission.replyEmission;
                    if (
                      emission.replyEmission.replyEmission.signalBoost &&
                      emission.replyEmission.replyEmission.signalBoost
                        .emissionID === tempAction.emission.emissionID
                    )
                      e = emission.replyEmission.replyEmission.signalBoost;
                  }
                }
              });
              if (!e) e = tempAction.emission;
              if (e.blocksMe)
                setTimeout(
                  () =>
                    this.props.notify(
                      <i className="fas fa-ban me-2 text-danger"></i>,
                      "You are blocked"
                    ),
                  250
                );
              else if (e.isBlocked)
                setTimeout(
                  () =>
                    this.props.notify(
                      <i className="fas fa-ban me-2 text-danger"></i>,
                      `You have blocked ${e.displayName}`
                    ),
                  250
                );
              else this.reply(e);
              break;
            case "Signalboost":
              this.state.emissions.forEach((emission) => {
                if (emission.emissionID === tempAction.emission.emissionID)
                  e = emission;
                if (
                  emission.signalBoost &&
                  emission.signalBoost.emissionID ===
                    tempAction.emission.emissionID
                )
                  e = emission.signalBoost;

                if (emission.replyEmission) {
                  if (
                    emission.replyEmission.emissionID ===
                    tempAction.emission.emissionID
                  )
                    e = emission.replyEmission;
                  if (
                    emission.replyEmission.signalBoost &&
                    emission.replyEmission.signalBoost.emissionID ===
                      tempAction.emission.emissionID
                  )
                    e = emission.replyEmission.signalBoost;

                  if (emission.replyEmission.replyEmission) {
                    if (
                      emission.replyEmission.replyEmission.emissionID ===
                      tempAction.emission.emissionID
                    )
                      e = emission.replyEmission.replyEmission;
                    if (
                      emission.replyEmission.replyEmission.signalBoost &&
                      emission.replyEmission.replyEmission.signalBoost
                        .emissionID === tempAction.emission.emissionID
                    )
                      e = emission.replyEmission.replyEmission.signalBoost;
                  }
                }
              });
              if (!e) e = tempAction.emission;
              if (e.blocksMe)
                setTimeout(
                  () =>
                    this.props.notify(
                      <i className="fas fa-ban me-2 text-danger"></i>,
                      "You are blocked"
                    ),
                  250
                );
              else if (e.isBlocked)
                setTimeout(
                  () =>
                    this.props.notify(
                      <i className="fas fa-ban me-2 text-danger"></i>,
                      `You have blocked ${e.displayName}`
                    ),
                  250
                );
              else this.signalBoost(e);
              break;
            case "Vote":
              let voted = false;

              this.state.emissions.forEach((emission) => {
                if (
                  emission.emissionID === tempAction.emission.emissionID &&
                  emission.pollData
                )
                  voted = emission.pollData.voted;
                if (
                  emission.signalBoost &&
                  emission.signalBoost.emissionID ===
                    tempAction.emission.emissionID &&
                  emission.signalBoost.pollData
                )
                  voted = emission.signalBoost.pollData.voted;

                if (emission.replyEmission) {
                  if (
                    emission.replyEmission.emissionID ===
                      tempAction.emission.emissionID &&
                    emission.replyEmission.pollData
                  )
                    voted = emission.replyEmission.pollData.voted;
                  if (
                    emission.replyEmission.signalBoost &&
                    emission.replyEmission.signalBoost.emissionID ===
                      tempAction.emission.emissionID &&
                    emission.replyEmission.signalBoost.pollData
                  )
                    voted = emission.replyEmission.signalBoost.pollData.voted;

                  if (emission.replyEmission.replyEmission) {
                    if (
                      emission.replyEmission.replyEmission.emissionID ===
                        tempAction.emission.emissionID &&
                      emission.replyEmission.replyEmission.pollData
                    )
                      voted =
                        emission.replyEmission.replyEmission.pollData.voted;
                    if (
                      emission.replyEmission.replyEmission.signalBoost &&
                      emission.replyEmission.replyEmission.signalBoost
                        .emissionID === tempAction.emission.emissionID &&
                      emission.replyEmission.replyEmission.signalBoost.pollData
                    )
                      voted =
                        emission.replyEmission.replyEmission.signalBoost
                          .pollData.voted;
                  }
                }
              });

              if (!voted) this.vote(tempAction.emission, tempAction.option);

              break;
            case "Submit Votes":
              this.submitVotes(tempAction.emission, tempAction.participation);
              break;
            case "Like":
              let liked = false;

              this.state.emissions.forEach((emission) => {
                if (emission.emissionID === tempAction.emissionID)
                  liked = emission.liked;
                if (
                  emission.signalBoost &&
                  emission.signalBoost.emissionID === tempAction.emissionID
                )
                  liked = emission.signalBoost.liked;

                if (emission.replyEmission) {
                  if (
                    emission.replyEmission.emissionID === tempAction.emissionID
                  )
                    liked = emission.replyEmission.liked;
                  if (
                    emission.replyEmission.signalBoost &&
                    emission.replyEmission.signalBoost.emissionID ===
                      tempAction.emissionID
                  )
                    liked = emission.replyEmission.signalBoost.liked;

                  if (emission.replyEmission.replyEmission) {
                    if (
                      emission.replyEmission.replyEmission.emissionID ===
                      tempAction.emissionID
                    )
                      liked = emission.replyEmission.replyEmission.liked;
                    if (
                      emission.replyEmission.replyEmission.signalBoost &&
                      emission.replyEmission.replyEmission.signalBoost
                        .emissionID === tempAction.emissionID
                    )
                      liked =
                        emission.replyEmission.replyEmission.signalBoost.liked;
                  }
                }
              });
              if (!liked) this.like(tempAction.emissionID);
              break;
            default:
              console.log("oob temp action", tempAction);
              break;
          }
        }
      }
    );
  };

  /**
   * Turn off and then turn back on all socket actions
   */
  initializeSocket = () => {
    // console.log("socket", this.props.socket);
    if (!this.props.socket) return;
    this.props.socket.off("new-emission");
    this.props.socket.off("vote");
    this.props.socket.off("like");
    this.props.socket.off("signalboost");
    this.props.socket.off("update-user");
    this.props.socket.off("remove-emission");
    this.props.socket.off("restore-emission");
    this.props.socket.off("ban");
    this.props.socket.off("restore-user");
    this.props.socket.off("private");
    this.props.socket.off("reply");
    this.props.socket.off("view");
    this.props.socket.off("views");
    this.props.socket.off("unprivate");
    this.props.socket.off("block");
    this.props.socket.off("unblock");
    this.props.socket.off("account-deleted");

    this.props.socket.on("account-deleted", (data) => {
      try {
        this.accountDeleted(data);
      } catch (err) {
        console.log("account-deleted error", err, data);
      }
    });
    this.props.socket.on("view", (data) => {
      try {
        this.view(data);
      } catch (err) {
        console.log("view error", err, data);
      }
    });
    this.props.socket.on("views", (data) => {
      try {
        this.views(data);
      } catch (err) {
        console.log("views error", err, data);
      }
    });
    this.props.socket.on("new-emission", (data) => {
      try {
        this.newEmission(data);
      } catch (err) {
        console.log("new-emission error", err, data);
      }
    });
    this.props.socket.on("vote", (data) => {
      try {
        this.voteOther(data);
      } catch (err) {
        console.log("vote error", err, data);
      }
    });
    this.props.socket.on("like", (likeData) => {
      try {
        this.likeOther({
          ...likeData,
          emission: h.setMetadata(likeData.emission, this.props.userInfo),
        });
      } catch (err) {
        console.log("like error", err, likeData);
      }
    });
    this.props.socket.on("signalboost", (data) => {
      try {
        if (
          data?.eventID &&
          this.state.socketEvents.find((e) => e === data?.eventID)
        )
          return;
        else
          this.setState(
            (curr) => ({
              ...curr,
              socketEvents: data?.eventID
                ? [...this.state.socketEvents, data?.eventID]
                : this.state.socketEvents,
            }),
            () => {
              this.props.signal_boost({
                ...data,
                fromEmissionPage: true,
              });
              this.signalBoostOther(data.emissionID, data.boostID);
            }
          );
      } catch (err) {
        console.log("signalboost error", err, data);
      }
    });
    this.props.socket.on("remove-emission", (emission) => {
      try {
        if (emission.remove.user.userID !== this.props.userInfo._id) {
          if (
            h.checkJanny(this.props.userInfo) ||
            this.props.userInfo._id === emission.userID
          )
            this.updateEmission(emission);
          else this.removeEmission(emission);
        }
      } catch (err) {
        console.log("remove-emission error", err, emission);
      }
    });
    this.props.socket.on("restore-emission", (emission) => {
      try {
        // console.log("restore-emission", emission);
        this.updateEmission(emission);
      } catch (err) {
        console.log("restore emission error", err, emission);
      }
    });
    this.props.socket.on("update-user", (data) => {
      try {
        this.userEdit(data);
      } catch (err) {
        console.log("update-user error", err, data);
      }
    });
    this.props.socket.on("ban", (e) => {
      try {
        this.ban(e);
      } catch (err) {
        console.log("ban error", err, e);
      }
    });

    /**
     * When banned user has their account restored, request all of their emissions that were removed as a result of that user being banned
     */
    this.props.socket.on("restore-user", async (userInfo) => {
      try {
        if (
          userInfo?.eventID &&
          this.state.socketEvents.find((e) => e === userInfo?.eventID)
        )
          return;
        else
          this.setState(
            (curr) => ({
              ...curr,
              socketEvents: userInfo?.eventID
                ? [...this.state.socketEvents, userInfo?.eventID]
                : this.state.socketEvents,
            }),
            async () => {
              try {
                const emissions = h.getThreadEmissions(
                  userInfo,
                  this.state.emissions
                );
                const getEmissions = () =>
                  new Promise((resolve) =>
                    axios
                      .post(
                        process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/by-id",
                        {
                          emissions: emissions,
                        },
                        {
                          headers: {
                            Authorization: this.props.token,
                          },
                        }
                      )
                      .then((res) => {
                        this.props.set_token(res.data.token);
                        resolve(res.data.emissions);
                      })
                      .catch((err) => {
                        console.log("restored emissions err", err);
                        setTimeout(async () => {
                          const restoredEmissions = await getEmissions();
                          resolve(restoredEmissions);
                        }, 1500);
                      })
                  );
                let restoredEmissions = [];
                if (emissions.length) restoredEmissions = await getEmissions();
                this.restoreUser(restoredEmissions);
                if (this.props.restoreUser) this.props.restoreUser(userInfo);
              } catch (err) {
                console.log("restore-user setState callback error", err);
              }
            }
          );
      } catch (err) {
        console.log("restore user error", err, userInfo);
      }
    });
    this.props.socket.on("private", (data) => {
      try {
        this.privateProfile(data);
      } catch (err) {
        console.log("private error", err, data);
      }
    });

    /**
     * When user unprivates their account, request all of their emissions that were removed as a result of that user being banned
     */
    this.props.socket.on("unprivate", async (userInfo) => {
      try {
        if (
          userInfo?.eventID &&
          this.state.socketEvents.find((e) => e === userInfo?.eventID)
        )
          return;
        else
          this.setState(
            (curr) => ({
              ...curr,
              socketEvents: userInfo?.eventID
                ? [...this.state.socketEvents, userInfo?.eventID]
                : this.state.socketEvents,
            }),
            async () => {
              try {
                const emissions = h.getThreadEmissions(
                  userInfo,
                  this.state.emissions
                );
                const getEmissions = () =>
                  new Promise((resolve) =>
                    axios
                      .post(
                        process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/by-id",
                        {
                          emissions: emissions,
                        },
                        {
                          headers: {
                            Authorization: this.props.token,
                          },
                        }
                      )
                      .then((res) => {
                        this.props.set_token(res.data.token);
                        resolve(res.data.emissions);
                      })
                      .catch((err) => {
                        console.log("restored emissions err", err);
                        setTimeout(async () => {
                          const restoredEmissions = await getEmissions();
                          resolve(restoredEmissions);
                        }, 1500);
                      })
                  );
                let restoredEmissions = [];
                if (emissions.length) restoredEmissions = await getEmissions();
                this.unprivate(restoredEmissions);
                if (this.props.unprivate) this.props.unprivate(userInfo);
              } catch (err) {
                console.log("unprivate setState callback error", err);
              }
            }
          );
      } catch (err) {
        console.log("unprivate error", err);
      }
    });
    this.props.socket.on("reply", (data) => {
      try {
        this.replyOther(data);
      } catch (err) {
        console.log("reply error", err, data);
      }
    });
    this.props.socket.on("block", (data) => {
      try {
        this.blockOther(data);
      } catch (err) {
        console.log("block error", err, data);
      }
    });

    /**
     * When user is unblocked by another user, request all of their emissions that were removed as a result of that user being banned
     */
    this.props.socket.on("unblock", async (userInfo) => {
      try {
        if (
          userInfo?.eventID &&
          this.state.socketEvents.find((e) => e === userInfo?.eventID)
        )
          return;
        else
          this.setState(
            (curr) => ({
              ...curr,
              socketEvents: userInfo?.eventID
                ? [...this.state.socketEvents, userInfo?.eventID]
                : this.state.socketEvents,
            }),
            async () => {
              try {
                const emissions = h.getThreadEmissions(
                  userInfo,
                  this.state.emissions
                );
                const getEmissions = () =>
                  new Promise((resolve) =>
                    axios
                      .post(
                        process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/by-id",
                        {
                          emissions: emissions,
                        },
                        {
                          headers: {
                            Authorization: this.props.token,
                          },
                        }
                      )
                      .then((res) => {
                        this.props.set_token(res.data.token);
                        resolve(res.data.emissions);
                      })
                      .catch((err) => {
                        console.log("restored emissions err", err);
                        setTimeout(async () => {
                          const restoredEmissions = await getEmissions();
                          resolve(restoredEmissions);
                        }, 1500);
                      })
                  );
                let restoredEmissions = [];
                if (emissions.length) restoredEmissions = await getEmissions();
                this.unblockOther(restoredEmissions);
                if (this.props.unblock) this.props.unblock(userInfo);
              } catch (err) {
                console.log("unblock setState callback error", err);
              }
            }
          );
      } catch (err) {
        console.log("unblock error", err);
      }
    });
  };

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when a view event is received via socket
   * Increments the view count by 1 on all emissions/replies/signalboosts with emissionID
   */
  view = (emissionID, subsequentEvents) =>
    this.setState(
      (curr) => ({
        ...curr,
        emissions: this.state.emissions.map((emission) => {
          if (emission.emissionID === emissionID)
            emission = {
              ...emission,
              views: emission.views + 1,
            };
          if (
            emission.signalBoost &&
            emission.signalBoost.emissionID === emissionID
          )
            emission = {
              ...emission,
              signalBoost: {
                ...emission.signalBoost,
                views: emission.signalBoost.views + 1,
              },
            };
          if (emission.replyEmission) {
            if (emission.replyEmission.emissionID === emissionID)
              emission.replyEmission = {
                ...emission.replyEmission,
                views: emission.replyEmission.views + 1,
              };
            if (
              emission.replyEmission.signalBoost &&
              emission.replyEmission.signalBoost.emissionID === emissionID
            )
              emission.replyEmission = {
                ...emission.replyEmission,
                signalBoost: {
                  ...emission.replyEmission.signalBoost,
                  views: emission.replyEmission.signalBoost.views + 1,
                },
              };

            if (emission.replyEmission.replyEmission) {
              if (
                emission.replyEmission.replyEmission.emissionID === emissionID
              )
                emission.replyEmission.replyEmission = {
                  ...emission.replyEmission.replyEmission,
                  views: emission.replyEmission.replyEmission.views + 1,
                };
              if (
                emission.replyEmission.replyEmission.signalBoost &&
                emission.replyEmission.replyEmission.signalBoost.emissionID ===
                  emissionID
              )
                emission.replyEmission.replyEmission = {
                  ...emission.replyEmission.replyEmission,
                  signalBoost: {
                    ...emission.replyEmission.replyEmission.signalBoost,
                    views:
                      emission.replyEmission.replyEmission.signalBoost.views +
                      1,
                  },
                };
            }
          }

          return emission;
        }),
      }),
      () => {
        if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
      }
    );

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when a views event is received via socket
   * Increments the view count by 1 on all emissions/replies/signalboosts with emissionID
   */
  views = (data, subsequentEvents) => {
    const { emissionIDs, eventID } = data;
    if (eventID && this.state.socketEvents.find((e) => e === eventID)) return;
    else
      this.setState(
        (curr) => ({
          ...curr,
          socketEvents: eventID
            ? [...this.state.socketEvents, eventID]
            : this.state.socketEvents,
          emissions: this.state.emissions.map((emission) => {
            if (emissionIDs.indexOf(emission.emissionID) > -1)
              emission = {
                ...emission,
                views: emission.views + 1,
              };
            if (
              emission.signalBoost &&
              emissionIDs.indexOf(emission.signalBoost.emissionID) > -1
            )
              emission = {
                ...emission,
                signalBoost: {
                  ...emission.signalBoost,
                  views: emission.signalBoost.views + 1,
                },
              };
            if (emission.replyEmission) {
              if (emissionIDs.indexOf(emission.replyEmission.emissionID) > -1)
                emission.replyEmission = {
                  ...emission.replyEmission,
                  views: emission.replyEmission.views + 1,
                };
              if (
                emission.replyEmission.signalBoost &&
                emissionIDs.indexOf(
                  emission.replyEmission.signalBoost.emissionID
                ) > -1
              )
                emission.replyEmission = {
                  ...emission.replyEmission,
                  signalBoost: {
                    ...emission.replyEmission.signalBoost,
                    views: emission.replyEmission.signalBoost.views + 1,
                  },
                };

              if (emission.replyEmission.replyEmission) {
                if (
                  emissionIDs.indexOf(
                    emission.replyEmission.replyEmission.emissionID
                  ) > -1
                )
                  emission.replyEmission.replyEmission = {
                    ...emission.replyEmission.replyEmission,
                    views: emission.replyEmission.replyEmission.views + 1,
                  };
                if (
                  emission.replyEmission.replyEmission.signalBoost &&
                  emissionIDs.indexOf(
                    emission.replyEmission.replyEmission.signalBoost.emissionID
                  ) > -1
                )
                  emission.replyEmission.replyEmission = {
                    ...emission.replyEmission.replyEmission,
                    signalBoost: {
                      ...emission.replyEmission.replyEmission.signalBoost,
                      views:
                        emission.replyEmission.replyEmission.signalBoost.views +
                        1,
                    },
                  };
              }
            }

            return emission;
          }),
        }),
        () => {
          if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
        }
      );
  };

  /**
   * @param {Number} boostID - ref Emissions.emissionID
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when a signalboost event is received via socket
   * Increments the signalboost count by 1 on all emissions/replies/signalboosts with emissionID
   */
  signalBoostOther = (emissionID, boostID, toggleModal, subsequentEvents) => {
    this.setState(
      (curr) => ({
        ...curr,
        emissions: this.state.emissions.map((emission) => {
          if (emission.emissionID === emissionID)
            emission = {
              ...emission,
              signalBoosts: emission.signalBoosts + 1,
            };
          if (
            emission.signalBoost &&
            emission.signalBoost.emissionID === emissionID &&
            !(
              emission.emissionID === boostID &&
              emission.signalBoost.emissionID === emissionID
            )
          )
            emission = {
              ...emission,
              signalBoost: {
                ...emission.signalBoost,
                signalBoosts: emission.signalBoost.signalBoosts + 1,
              },
            };
          if (emission.replyEmission) {
            if (emission.replyEmission.emissionID === emissionID)
              emission.replyEmission = {
                ...emission.replyEmission,
                signalBoosts: emission.replyEmission.signalBoosts + 1,
              };
            if (
              emission.replyEmission.signalBoost &&
              emission.replyEmission.signalBoost.emissionID === emissionID
            )
              emission.replyEmission = {
                ...emission.replyEmission,
                signalBoost: {
                  ...emission.replyEmission.signalBoost,
                  signalBoosts:
                    emission.replyEmission.signalBoost.signalBoosts + 1,
                },
              };

            if (emission.replyEmission.replyEmission) {
              if (
                emission.replyEmission.replyEmission.emissionID === emissionID
              )
                emission.replyEmission.replyEmission = {
                  ...emission.replyEmission.replyEmission,
                  signalBoosts:
                    emission.replyEmission.replyEmission.signalBoosts + 1,
                };
              if (
                emission.replyEmission.replyEmission.signalBoost &&
                emission.replyEmission.replyEmission.signalBoost.emissionID ===
                  emissionID
              )
                emission.replyEmission.replyEmission = {
                  ...emission.replyEmission.replyEmission,
                  signalBoost: {
                    ...emission.replyEmission.replyEmission.signalBoost,
                    signalBoosts:
                      emission.replyEmission.replyEmission.signalBoost
                        .signalBoosts + 1,
                  },
                };
            }
          }

          return emission;
        }),
        // signalBoostModalShown: false,
      }),
      () => {
        if (subsequentEvents && !toggleModal)
          this.handleSocketEvents(subsequentEvents);
        // else this.setSignalBoostModal(false);
      }
    );
  };

  /**
   * @param {Object} userInfo - Users document
   *
   * Triggered when a block event is received via socket
   * Replace body of all emissions/replies/signalboosts with message that the user has been blocked
   */
  blockOther = (userInfo, subsequentEvents) => {
    try {
      if (
        userInfo?.eventID &&
        this.state.socketEvents.find((e) => e === userInfo?.eventID)
      )
        return;
      else
        this.setState(
          (curr) => ({
            ...curr,
            socketEvents: userInfo?.eventID
              ? [...this.state.socketEvents, userInfo?.eventID]
              : this.state.socketEvents,
            emissions: this.state.emissions.map((emission) => {
              if (emission.userID === userInfo._id)
                emission = h.checkJanny(this.props.userInfo)
                  ? {
                      ...emission,
                      blocksMe: true,
                    }
                  : {
                      ...emission,
                      html: `<h5 class="text-center display-6 my-4">@${userInfo.username} has blocked you</h5>`,
                      blocksMe: true,
                      files: false,
                      pollData: false,
                      signalBoost: false,
                    };
              if (
                emission.signalBoost &&
                emission.signalBoost.userID === userInfo._id
              )
                emission.signalBoost = h.checkJanny(this.props.userInfo)
                  ? {
                      ...emission.signalBoost,
                      blocksMe: true,
                    }
                  : {
                      ...emission.signalBoost,
                      html: `<h5 class="text-center display-6 my-4">@${userInfo.username} has blocked you</h5>`,
                      blocksMe: true,
                      files: false,
                      pollData: false,
                      signalBoost: false,
                    };

              if (emission.replyEmission) {
                if (emission.replyEmission.userID === userInfo._id)
                  emission.replyEmission = h.checkJanny(this.props.userInfo)
                    ? {
                        ...emission.replyEmission,
                        blocksMe: true,
                      }
                    : {
                        ...emission.replyEmission,
                        html: `<h5 class="text-center display-6 my-4">@${userInfo.username} has blocked you</h5>`,
                        blocksMe: true,
                        files: false,
                        pollData: false,
                        signalBoost: false,
                      };
                if (
                  emission.replyEmission.signalBoost &&
                  emission.replyEmission.signalBoost.userID === userInfo._id
                )
                  emission.replyEmission.signalBoost = h.checkJanny(
                    this.props.userInfo
                  )
                    ? {
                        ...emission.replyEmission.signalBoost,
                        blocksMe: true,
                      }
                    : {
                        ...emission.replyEmission.signalBoost,
                        html: `<h5 class="text-center display-6 my-4">@${userInfo.username} has blocked you</h5>`,
                        blocksMe: true,
                        files: false,
                        pollData: false,
                        signalBoost: false,
                      };

                if (emission.replyEmission.replyEmission) {
                  if (
                    emission.replyEmission.replyEmission.userID === userInfo._id
                  )
                    emission.replyEmission.replyEmission = h.checkJanny(
                      this.props.userInfo
                    )
                      ? {
                          ...emission.replyEmission.replyEmission,
                          blocksMe: true,
                        }
                      : {
                          ...emission.replyEmission.replyEmission,
                          html: `<h5 class="text-center display-6 my-4">@${userInfo.username} has blocked you</h5>`,
                          blocksMe: true,
                          files: false,
                          pollData: false,
                          signalBoost: false,
                        };
                  if (
                    emission.replyEmission.replyEmission.signalBoost &&
                    emission.replyEmission.replyEmission.signalBoost.userID ===
                      userInfo._id
                  )
                    emission.replyEmission.replyEmission.signalBoost =
                      h.checkJanny(this.props.userInfo)
                        ? {
                            ...emission.replyEmission.replyEmission.signalBoost,
                            blocksMe: true,
                          }
                        : {
                            ...emission.replyEmission.replyEmission.signalBoost,
                            html: `<h5 class="text-center display-6 my-4">@${userInfo.username} has blocked you</h5>`,
                            blocksMe: true,
                            files: false,
                            pollData: false,
                            signalBoost: false,
                          };
                }
              }
              return emission;
            }),
          }),
          () => {
            if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
            else if (this.props.blockOther) this.props.blockOther(userInfo);
          }
        );
    } catch (err) {
      console.log("blockOther error", err);
    }
  };

  /**
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when a reply event is received via socket
   * Increments the reply count by 1 on all emissions/replies/signalboosts with emissionID
   */
  replyOther = (data, subsequentEvents) => {
    if (
      data?.eventID &&
      this.state.socketEvents.find((e) => e === data?.eventID)
    )
      return;
    const emissionID = data.emissionID;
    this.setState(
      (curr) => ({
        ...curr,
        socketEvents: data?.eventID
          ? [...this.state.socketEvents, data?.eventID]
          : this.state.socketEvents,
        emissions: this.state.emissions.map((emission) => {
          if (emission.emissionID === emissionID)
            emission = {
              ...emission,
              replies: emission.replies + 1,
            };
          if (
            emission.signalBoost &&
            emission.signalBoost.emissionID === emissionID
          )
            emission = {
              ...emission,
              signalBoost: {
                ...emission.signalBoost,
                replies: emission.signalBoost.replies + 1,
              },
            };
          if (emission.replyEmission) {
            if (emission.replyEmission.emissionID === emissionID)
              emission.replyEmission = {
                ...emission.replyEmission,
                replies: emission.replyEmission.replies + 1,
              };
            if (
              emission.replyEmission.signalBoost &&
              emission.replyEmission.signalBoost.emissionID === emissionID
            )
              emission.replyEmission = {
                ...emission.replyEmission,
                signalBoost: {
                  ...emission.replyEmission.signalBoost,
                  replies: emission.replyEmission.signalBoost.replies + 1,
                },
              };

            if (emission.replyEmission.replyEmission) {
              if (
                emission.replyEmission.replyEmission.emissionID === emissionID
              )
                emission.replyEmission.replyEmission = {
                  ...emission.replyEmission.replyEmission,
                  replies: emission.replyEmission.replyEmission.replies + 1,
                };
              if (
                emission.replyEmission.replyEmission.signalBoost &&
                emission.replyEmission.replyEmission.signalBoost.emissionID ===
                  emissionID
              )
                emission.replyEmission.replyEmission = {
                  ...emission.replyEmission.replyEmission,
                  signalBoost: {
                    ...emission.replyEmission.replyEmission.signalBoost,
                    replies:
                      emission.replyEmission.replyEmission.signalBoost.replies +
                      1,
                  },
                };
            }
          }

          return emission;
        }),
      }),
      () => {
        if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
      }
    );
  };

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when the user reports an emission
   * Sets reported all emissions/replies/signalboosts with emissionID
   */
  setEmissionReported = (emissionID) => {
    this.toggleReportModal();
    this.setState(
      (curr) => ({
        ...curr,
        emissions: h.replaceEmissions(this.state.emissions, emissionID, {
          reported: true,
        }),
        // reportModalShown: false,
      })
      // () => console.log(this.state.reportModalShown)
    );
  };

  /**
   *
   * @param {Object} userInfo - Users document
   *
   * Triggered when a private event is received via socket
   * Replace body of all emissions/replies/signalboosts with message that the user has privated their account
   */
  privateProfile = (userInfo, subsequentEvents) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    )
      return;
    else
      this.setState(
        (curr) => ({
          ...curr,
          socketEvents: userInfo?.eventID
            ? [...this.state.socketEvents, userInfo?.eventID]
            : this.state.socketEvents,
          emissions: this.state.emissions.map((emission) => {
            if (emission.userID === userInfo._id)
              emission = h.checkJanny(this.props.userInfo)
                ? {
                    ...emission,
                    private: true,
                  }
                : {
                    ...emission,
                    html: `<h5 class="text-center display-6 my-4">@${userInfo.username} has made their profile private</h5>`,
                    private: true,
                    files: false,
                    pollData: false,
                    signalBoost: false,
                  };
            if (
              emission.signalBoost &&
              emission.signalBoost.userID === userInfo._id
            )
              emission.signalBoost = h.checkJanny(this.props.userInfo)
                ? {
                    ...emission.signalBoost,
                    private: true,
                  }
                : {
                    ...emission.signalBoost,
                    html: `<h5 class="text-center display-6 my-4">@${userInfo.username} has made their profile private</h5>`,
                    private: true,
                    files: false,
                    pollData: false,
                    signalBoost: false,
                  };

            if (emission.replyEmission) {
              if (emission.replyEmission.userID === userInfo._id)
                emission.replyEmission = h.checkJanny(this.props.userInfo)
                  ? {
                      ...emission.replyEmission,
                      private: true,
                    }
                  : {
                      ...emission.replyEmission,
                      html: `<h5 class="text-center display-6 my-4">@${userInfo.username} has made their profile private</h5>`,
                      private: true,
                      files: false,
                      pollData: false,
                      signalBoost: false,
                    };
              if (
                emission.replyEmission.signalBoost &&
                emission.replyEmission.signalBoost.userID === userInfo._id
              )
                emission.replyEmission.signalBoost = h.checkJanny(
                  this.props.userInfo
                )
                  ? {
                      ...emission.replyEmission.signalBoost,
                      private: true,
                    }
                  : {
                      ...emission.replyEmission.signalBoost,
                      html: `<h5 class="text-center display-6 my-4">@${userInfo.username} has made their profile private</h5>`,
                      private: true,
                      files: false,
                      pollData: false,
                      signalBoost: false,
                    };

              if (emission.replyEmission.replyEmission) {
                if (
                  emission.replyEmission.replyEmission.userID === userInfo._id
                )
                  emission.replyEmission.replyEmission = h.checkJanny(
                    this.props.userInfo
                  )
                    ? {
                        ...emission.replyEmission.replyEmission,
                        private: true,
                      }
                    : {
                        ...emission.replyEmission.replyEmission,
                        html: `<h5 class="text-center display-6 my-4">@${userInfo.username} has made their profile private</h5>`,
                        private: true,
                        files: false,
                        pollData: false,
                        signalBoost: false,
                      };
                if (
                  emission.replyEmission.replyEmission.signalBoost &&
                  emission.replyEmission.replyEmission.signalBoost.userID ===
                    userInfo._id
                )
                  emission.replyEmission.replyEmission.signalBoost =
                    h.checkJanny(this.props.userInfo)
                      ? {
                          ...emission.replyEmission.replyEmission.signalBoost,
                          private: true,
                        }
                      : {
                          ...emission.replyEmission.replyEmission.signalBoost,
                          html: `<h5 class="text-center display-6 my-4">@${userInfo.username} has made their profile private</h5>`,
                          private: true,
                          files: false,
                          pollData: false,
                          signalBoost: false,
                        };
              }
            }
            return emission;
          }),
        }),
        () => {
          if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
          else if (this.props.privateProfile)
            this.props.privateProfile(userInfo);
        }
      );
  };

  /**
   *
   * @param {Array} emissions - Array of Emissions documents
   *
   * Triggered after a banned user is restored and a fresh set of emissions has been retrieved from the server
   * Replace old emissions with the ones supplied
   */
  restoreUser = (emissions, subsequentEvents) =>
    this.setState(
      (curr) => ({
        ...curr,
        emissions: h.replaceUserEmissions(this.state.emissions, emissions),
      }),
      () => {
        if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
      }
    );

  /**
   *
   * @param {Array} emissions - Array of Emissions documents
   *
   * Triggered after a private user unprivates their profile and a fresh set of emissions has been retrieved from the server
   * Replace old emissions with the ones supplied
   */
  unprivate = (emissions, subsequentEvents) =>
    this.setState(
      (curr) => ({
        ...curr,
        emissions: h.replaceUserEmissions(this.state.emissions, emissions),
      }),
      () => {
        if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
      }
    );

  /**
   *
   * @param {Array} emissions - Array of Emissions documents
   *
   * Triggered after a the user is unblocked by a user who blocked them and a fresh set of emissions has been retrieved from the server
   * Replace old emissions with the ones supplied
   */
  unblockOther = (emissions, subsequentEvents) =>
    this.setState(
      (curr) => ({
        ...curr,
        emissions: h.replaceUserEmissions(this.state.emissions, emissions),
      }),
      () => {
        if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
      }
    );

  /**
   *
   * @param {Object} userInfo - Users document
   *
   * Triggered when a ban event is received via socket
   * Replace body of all emissions/replies/signalboosts with message that the user has been banned
   */
  ban = (userInfo, subsequentEvents) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    ) {
      if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
    } else {
      this.setState(
        (curr) => ({
          ...curr,
          socketEvents: userInfo?.eventID
            ? [...this.state.socketEvents, userInfo?.eventID]
            : this.state.socketEvents,
          emissions: this.state.emissions.map((emission) => {
            if (emission.userID === userInfo._id)
              emission = h.checkJanny(this.props.userInfo)
                ? {
                    ...emission,
                    remove: {
                      removed: true,
                      reason: "ban",
                      user: {
                        username: "",
                        userID: "",
                      },
                      details: false,
                    },
                  }
                : {
                    ...emission,
                    html: `<h5 class="text-center display-6 my-4">@${userInfo.username} is banned</h5>`,
                    files: false,
                    pollData: false,
                    remove: {
                      removed: true,
                      reason: "ban",
                      user: {
                        username: "",
                        userID: "",
                      },
                      details: false,
                    },
                    signalBoost: false,
                  };
            if (
              emission.signalBoost &&
              emission.signalBoost.userID === userInfo._id
            )
              emission.signalBoost = h.checkJanny(this.props.userInfo)
                ? {
                    ...emission.signalBoost,
                    remove: {
                      removed: true,
                      reason: "ban",
                      user: {
                        username: "",
                        userID: "",
                      },
                      details: false,
                    },
                  }
                : {
                    ...emission.signalBoost,
                    html: `<h5 class="text-center display-6 my-4">@${userInfo.username} is banned</h5>`,
                    files: false,
                    pollData: false,
                    remove: {
                      removed: true,
                      reason: "ban",
                      user: {
                        username: "",
                        userID: "",
                      },
                      details: false,
                    },
                    signalBoost: false,
                  };

            if (emission.replyEmission) {
              if (emission.replyEmission.userID === userInfo._id)
                emission.replyEmission = h.checkJanny(this.props.userInfo)
                  ? {
                      ...emission.replyEmission,
                      remove: {
                        removed: true,
                        reason: "ban",
                        user: {
                          username: "",
                          userID: "",
                        },
                        details: false,
                      },
                    }
                  : {
                      ...emission.replyEmission,
                      html: `<h5 class="text-center display-6 my-4">@${userInfo.username} is banned</h5>`,
                      files: false,
                      pollData: false,
                      remove: {
                        removed: true,
                        reason: "ban",
                        user: {
                          username: "",
                          userID: "",
                        },
                        details: false,
                      },
                      signalBoost: false,
                    };
              if (
                emission.replyEmission.signalBoost &&
                emission.replyEmission.signalBoost.userID === userInfo._id
              )
                emission.replyEmission.signalBoost = h.checkJanny(
                  this.props.userInfo
                )
                  ? {
                      ...emission.replyEmission.signalBoost,
                      remove: {
                        removed: true,
                        reason: "ban",
                        user: {
                          username: "",
                          userID: "",
                        },
                        details: false,
                      },
                    }
                  : {
                      ...emission.replyEmission.signalBoost,
                      html: `<h5 class="text-center display-6 my-4">@${userInfo.username} is banned</h5>`,
                      files: false,
                      pollData: false,
                      remove: {
                        removed: true,
                        reason: "ban",
                        user: {
                          username: "",
                          userID: "",
                        },
                        details: false,
                      },
                      signalBoost: false,
                    };

              if (emission.replyEmission.replyEmission) {
                if (
                  emission.replyEmission.replyEmission.userID === userInfo._id
                )
                  emission.replyEmission.replyEmission = h.checkJanny(
                    this.props.userInfo
                  )
                    ? {
                        ...emission.replyEmission.replyEmission,
                        remove: {
                          removed: true,
                          reason: "ban",
                          user: {
                            username: "",
                            userID: "",
                          },
                          details: false,
                        },
                      }
                    : {
                        ...emission.replyEmission.replyEmission,
                        html: `<h5 class="text-center display-6 my-4">@${userInfo.username} is banned</h5>`,
                        files: false,
                        pollData: false,
                        remove: {
                          removed: true,
                          reason: "ban",
                          user: {
                            username: "",
                            userID: "",
                          },
                          details: false,
                        },
                        signalBoost: false,
                      };
                if (
                  emission.replyEmission.replyEmission.signalBoost &&
                  emission.replyEmission.replyEmission.signalBoost.userID ===
                    userInfo._id
                )
                  emission.replyEmission.replyEmission.signalBoost =
                    h.checkJanny(this.props.userInfo)
                      ? {
                          ...emission.replyEmission.replyEmission.signalBoost,
                          remove: {
                            removed: true,
                            reason: "ban",
                            user: {
                              username: "",
                              userID: "",
                            },
                            details: false,
                          },
                        }
                      : {
                          ...emission.replyEmission.replyEmission.signalBoost,
                          html: `<h5 class="text-center display-6 my-4">@${userInfo.username} is banned</h5>`,
                          files: false,
                          pollData: false,
                          remove: {
                            removed: true,
                            reason: "ban",
                            user: {
                              username: "",
                              userID: "",
                            },
                            details: false,
                          },
                          signalBoost: false,
                        };
              }
            }
            return emission;
          }),
        }),
        () => {
          if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
          else if (this.props.ban) this.props.ban(userInfo);
        }
      );
    }
  };

  accountDeleted = (userID, subsequentEvents) => {
    console.log("account deleteddd", userID);
    this.setState(
      (curr) => ({
        ...curr,
        emissions: this.state.emissions.map((e) => {
          if (e.userID === userID)
            e = {
              ...e,
              ...accountDeletedEmission(e.username, e.userID),
            };
          if (e.signalBoost && e.signalBoost.userID === userID)
            e.signalBoost = {
              ...e.signalBoost,
              ...accountDeletedEmission(e.username, e.userID),
            };
          if (e.replyEmission) {
            if (e.replyEmission.userID === userID)
              e.replyEmission = {
                ...e.replyEmission,
                ...accountDeletedEmission(e.username, e.userID),
              };
            if (
              e.replyEmission.signalBoost &&
              e.replyEmission.signalBoost.userID === userID
            )
              e.replyEmission.signalBoost = {
                ...e.replyEmission.signalBoost,
                ...accountDeletedEmission(e.username, e.userID),
              };
            if (e.replyEmission.replyEmission) {
              if (e.replyEmission.replyEmission.userID === userID)
                e.replyEmission.replyEmission = {
                  ...e.replyEmission.replyEmission,
                  ...accountDeletedEmission(e.username, e.userID),
                };
              if (
                e.replyEmission.replyEmission.signalBoost &&
                e.replyEmission.replyEmission.signalBoost === userID
              )
                e.replyEmission.replyEmission.signalBoost = {
                  ...e,
                  ...accountDeletedEmission(e.username, e.userID),
                };
            }
          }
          return e;
        }),
      }),
      () => {
        console.log("after", this.state.emissions);
        if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
        else if (this.props.accountDeleted) this.props.accountDeleted(userID);
      }
    );
  };

  /**
   *
   * @param {Object} userInfo - Users document
   *
   * Triggered when an update-user event is received via socket
   * Update avatar/display name/verified/role of all emissions/replies/signalboosts with updated user info
   */
  userEdit = (userInfo, subsequentEvents) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    ) {
      if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
      else if (this.props.userEdit) this.props.userEdit(userInfo);
    } else
      this.setState(
        (curr) => ({
          ...curr,
          socketEvents: userInfo?.eventID
            ? [...this.state.socketEvents, userInfo?.eventID]
            : this.state.socketEvents,
          emissions: this.state.emissions.map((emission) => {
            if (emission.userID === userInfo._id)
              emission = {
                ...emission,
                avatar: userInfo.avatar,
                displayName: userInfo.displayName,
                verified: userInfo.verified,
                role: userInfo.role,
              };
            if (
              emission.signalBoost &&
              emission.signalBoost.userID === userInfo._id
            )
              emission.signalBoost = {
                ...emission.signalBoost,
                avatar: userInfo.avatar,
                displayName: userInfo.displayName,
                verified: userInfo.verified,
                role: userInfo.role,
              };

            if (emission.replyEmission) {
              if (emission.replyEmission.userID === userInfo._id)
                emission.replyEmission = {
                  ...emission.replyEmission,
                  avatar: userInfo.avatar,
                  displayName: userInfo.displayName,
                  verified: userInfo.verified,
                  role: userInfo.role,
                };
              if (
                emission.replyEmission.signalBoost &&
                emission.replyEmission.signalBoost.userID === userInfo._id
              )
                emission.replyEmission.signalBoost = {
                  ...emission.replyEmission.signalBoost,
                  avatar: userInfo.avatar,
                  displayName: userInfo.displayName,
                  verified: userInfo.verified,
                  role: userInfo.role,
                };

              if (emission.replyEmission.replyEmission) {
                if (
                  emission.replyEmission.replyEmission.userID === userInfo._id
                )
                  emission.replyEmission.replyEmission = {
                    ...emission.replyEmission.replyEmission,
                    avatar: userInfo.avatar,
                    displayName: userInfo.displayName,
                    verified: userInfo.verified,
                    role: userInfo.role,
                  };
                if (
                  emission.replyEmission.replyEmission.signalBoost &&
                  emission.replyEmission.replyEmission.signalBoost.userID ===
                    userInfo._id
                )
                  emission.replyEmission.replyEmission.signalBoost = {
                    ...emission.replyEmission.replyEmission.signalBoost,
                    avatar: userInfo.avatar,
                    displayName: userInfo.displayName,
                    verified: userInfo.verified,
                    role: userInfo.role,
                  };
              }
            }
            return emission;
          }),
        }),
        () => {
          if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
          else if (this.props.userEdit) this.props.userEdit(userInfo);
        }
      );
  };

  /**
   *
   * @param {Number} emissionID - Users document
   *
   * Triggered when a remove-emission event is received via socket
   * Replace body of all emissions/replies/signalboosts with message that the emission has been removed
   */
  removeEmission = (emission, subsequentEvents) => {
    if (
      emission?.eventID &&
      this.state.socketEvents.find((e) => e === emission?.eventID)
    ) {
      if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
    } else
      this.setState(
        (curr) => ({
          ...curr,
          socketEvents: emission?.eventID
            ? [...this.state.socketEvents, emission?.eventID]
            : this.state.socketEvents,
          emissions: h.replaceEmissions(
            this.state.emissions,
            emission.emissionID,
            {
              html: `<h5 class="text-center display-6 my-4"><span class="text-capitalize">${env.EMISSION_NAME}</span> Removed</h5>`,
              files: false,
              pollData: false,
              remove: {
                ...emission.remove,
                details: false,
              },
              signalBoost: false,
            }
          ),
        }),
        () => {
          if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
        }
      );
  };

  /**
   *
   * @param {Object} emission - Emissions document
   *
   * Triggered when a new-emission event is received via socket
   * Insert the emission into state with metadata applied
   */
  newEmission = (emission, subsequentEvents) => {
    if (
      !(
        emission?.eventID &&
        this.state.socketEvents.find((e) => e === emission?.eventID)
      )
    )
      this.setState(
        (curr) => ({
          ...curr,
          emissions: [
            ...curr.emissions,
            h.setMetadata(emission, this.props.userInfo),
          ],
          socketEvents: emission?.eventID
            ? [...curr.socketEvents, emission?.eventID]
            : curr.socketEvents,
        }),
        () => {
          if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
        }
      );
    else if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
  };

  /**
   *
   * @param {Object} emission - Emissions document
   *
   * Triggered when a remove-emission or update-emission event is received via socket
   * Update the emission in state
   */
  updateEmission = (emission, fromModal, subsequentEvents) => {
    if (
      emission?.eventID &&
      this.state.socketEvents.find((e) => e === emission?.eventID)
    ) {
      if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
    } else
      this.setState(
        (curr) => ({
          ...curr,
          emissions: h.updateEmission(
            this.state.emissions,
            emission,
            this.props.userInfo
          ),
          socketEvents: emission?.eventID
            ? [...this.state.socketEvents, emission?.eventID]
            : this.state.socketEvents,
          removeModalShown: fromModal ? false : this.state.removeModalShown,
          restoreModalShown: fromModal ? false : this.state.restoreModalShown,
        }),
        () => {
          if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
        }
      );
  };

  /**
   * @param {Object} data - Like object with emissionID and value (-1 or 1)
   *
   * Triggered when a like event is received via socket
   * Increments or decrements the like count as appropriate on all emissions/replies/signalboosts with emissionID
   */
  likeOther = (data, subsequentEvents) => {
    if (
      data?.eventID &&
      this.state.socketEvents.find((e) => e === data?.eventID)
    ) {
      if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
    } else {
      const self =
        this.props.userInfo._id && data.userID === this.props.userInfo._id;
      const liked = data.value > 0;
      this.setState(
        (curr) => ({
          ...curr,
          socketEvents: data?.eventID
            ? [...this.state.socketEvents, data?.eventID]
            : this.state.socketEvents,
          emissions: this.state.emissions.map((emission) => {
            if (emission.emissionID === data.emissionID)
              emission = {
                ...emission,
                likes: emission.likes + data.value,
                liked: self ? liked : emission.liked,
              };

            if (
              emission.signalBoost &&
              emission.signalBoost.emissionID === data.emissionID
            )
              emission.signalBoost = {
                ...emission.signalBoost,
                likes: emission.signalBoost.likes + data.value,
                liked: self ? liked : emission.signalBoost.liked,
              };

            if (emission.replyEmission) {
              if (emission.replyEmission.emissionID === data.emissionID)
                emission.replyEmission = {
                  ...emission.replyEmission,
                  likes: emission.replyEmission.likes + data.value,
                  liked: self ? liked : emission.replyEmission.liked,
                };
              if (
                emission.replyEmission.signalBoost &&
                emission.replyEmission.signalBoost.emissionID ===
                  data.emissionID
              )
                emission.replyEmission.signalBoost = {
                  ...emission.replyEmission.signalBoost,
                  likes: emission.replyEmission.signalBoost.likes + data.value,
                };
            }

            if (
              emission.replyEmission &&
              emission.replyEmission.replyEmission
            ) {
              if (
                emission.replyEmission.replyEmission.emissionID ===
                data.emissionID
              )
                emission.replyEmission.replyEmission = {
                  ...emission.replyEmission.replyEmission,
                  likes:
                    emission.replyEmission.replyEmission.likes + data.value,
                  liked: self
                    ? liked
                    : emission.replyEmission.replyEmission.liked,
                };
              if (
                emission.replyEmission.replyEmission.signalBoost &&
                emission.replyEmission.replyEmission.signalBoost.emissionID ===
                  data.emissionID
              )
                emission.replyEmission.replyEmission.signalBoost = {
                  ...emission.replyEmission.replyEmission.signalBoost,
                  likes:
                    emission.replyEmission.replyEmission.signalBoost.likes +
                    data.value,
                  liked: self
                    ? liked
                    : emission.replyEmission.replyEmission.signalBoost.liked,
                };
            }

            return emission;
          }),
        }),
        () => {
          if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
        }
      );
    }
  };

  /**
   * @param {Object} e - Emissions document
   *
   * Triggered when a vote event is received via socket
   * Increments or decrements the vote count as appropriate on all emissions/replies/signalboosts with emissionID
   */
  voteOther = (e, emissionID, subsequentEvents) => {
    if (
      e?.eventID &&
      this.state.socketEvents.find((event) => event === e?.eventID)
    ) {
      if (emissionID) this.setPollVoted(emissionID, subsequentEvents);
      else if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
    } else
      this.setState(
        (curr) => ({
          ...curr,
          socketEvents: e?.eventID
            ? [...this.state.socketEvents, e?.eventID]
            : this.state.socketEvents,
          emissions: this.state.emissions.map((emission) => {
            if (emission._id === e.emissionID)
              emission = {
                ...emission,
                pollData: {
                  ...emission.pollData,
                  options: e.options,
                  voters: [...emission.pollData.voters, e.voterDetails],
                },
              };
            if (
              emission.signalBoost &&
              emission.signalBoost._id === e.emissionID
            )
              emission = {
                ...emission,
                signalBoost: {
                  ...emission.signalBoost,
                  pollData: {
                    ...emission.signalBoost.pollData,
                    options: e.options,
                    voters: [
                      ...emission.signalBoost.pollData.voters,
                      e.voterDetails,
                    ],
                  },
                },
              };
            if (emission.replyEmission) {
              if (emission.replyEmission._id === e.emissionID)
                emission.replyEmission = {
                  ...emission.replyEmission,
                  pollData: {
                    ...emission.replyEmission.pollData,
                    options: e.options,
                    voters: [
                      ...emission.replyEmission.pollData.voters,
                      e.voterDetails,
                    ],
                  },
                };
              if (
                emission.replyEmission.signalBoost &&
                emission.replyEmission.signalBoost._id === e.emissionID
              )
                emission.replyEmission = {
                  ...emission.replyEmission,
                  signalBoost: {
                    ...emission.replyEmission.signalBoost,
                    pollData: {
                      ...emission.replyEmission.signalBoost.pollData,
                      options: e.options,
                      voters: [
                        ...emission.replyEmission.signalBoost.pollData.voters,
                        e.voterDetails,
                      ],
                    },
                  },
                };

              if (emission.replyEmission.replyEmission) {
                if (emission.replyEmission.replyEmission._id === e.emissionID)
                  emission.replyEmission.replyEmission = {
                    ...emission.replyEmission.replyEmission,
                    pollData: {
                      ...emission.replyEmission.replyEmission.pollData,
                      options: e.options,
                      voters: [
                        ...emission.replyEmission.replyEmission.pollData.voters,
                        e.voterDetails,
                      ],
                    },
                  };
                if (
                  emission.replyEmission.replyEmission.signalBoost &&
                  emission.replyEmission.replyEmission.signalBoost._id ===
                    e.emissionID
                )
                  emission.replyEmission.replyEmission = {
                    ...emission.replyEmission.replyEmission,
                    signalBoost: {
                      ...emission.replyEmission.replyEmission.signalBoost,
                      pollData: {
                        ...emission.replyEmission.replyEmission.signalBoost
                          .pollData,
                        options: e.options,
                        voters: [
                          ...emission.replyEmission.replyEmission.signalBoost
                            .pollData.voters,
                          e.voterDetails,
                        ],
                      },
                    },
                  };
              }
            }
            return emission;
          }),
        }),
        () => {
          if (emissionID) this.setPollVoted(emissionID, subsequentEvents);
          else if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
        }
      );
  };

  setLoginModal = (option) =>
    this.setState(
      (curr) => ({
        ...curr,
        loginModalShown: option,
      }),
      h.hideToolTips
    );

  toggleLoginModal = () =>
    this.setState(
      (curr) => ({
        ...curr,
        loginModalShown: !this.state.loginModalShown,
      }),
      h.hideToolTips
    );

  setReplyModal = (option) =>
    this.setState(
      (curr) => ({
        ...curr,
        replyModalShown: option,
      }),
      h.hideToolTips
    );

  toggleReplyModal = () =>
    this.setState(
      (curr) => ({
        ...curr,
        replyModalShown: !this.state.replyModalShown,
      }),
      h.hideToolTips
    );

  setSignalBoostModal = (option) => {
    if (option !== this.state.signalBoostModalShown)
      this.setState(
        (curr) => ({
          ...curr,
          signalBoostModalShown: option,
        }),
        h.hideToolTips
      );
  };

  toggleSignalBoostModal = () => {
    this.setState(
      (curr) => ({
        ...curr,
        signalBoostModalShown: !this.state.signalBoostModalShown,
      }),
      h.hideToolTips
    );
  };

  setFileModal = (option) =>
    this.setState(
      (curr) => ({
        ...curr,
        fileModalShown: option,
      }),
      h.hideToolTips
    );

  toggleFileModal = () =>
    this.setState(
      (curr) => ({
        ...curr,
        fileModalShown: !this.state.fileModalShown,
      }),
      h.hideToolTips
    );

  /**
   * @param {Array} emissions - List of Emissions
   *
   * Triggered by the login modal when the user logs in
   * Replaces emissions currently in state with emissions supplied
   */
  setEmissions = (data) =>
    this.setState(
      (curr) => ({
        ...curr,
        emissions: data.emissions.map((emission) => {
          const old = curr.emissions.find(
            (e) => e.emissionID === emission.emissionID
          );
          if (old.following) emission.following = old.following;
          if (old.popular) emission.popular = old.popular;

          return emission;
        }),
      }),
      () => {
        this.props.set_user(data.userInfo);
      }
    );

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks inside the emission body
   * If the user clicked a link, route to the href
   */
  clickEmissionBody = (e, emissionID) => {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target;
    if (e.target.tagName === "SPAN") element = e.target.parentElement;
    if (element.tagName === "A") {
      const href = element.getAttribute("href");
      if (href) {
        const url = new Url(href);
        if (url.hostname === window.location.hostname)
          this.props.route(url.pathname);
        else {
          console.log("native route EmissionPage");
          window.location = href;
        }
      }
    } else if (emissionID) this.props.route("/e/" + emissionID);
  };

  /**
   *
   * @param {Object} emission - Emissions document
   * @param {String} option - id of vote option
   *
   * Find poll object in state.
   * If exists, insert vote.
   * Else, insert poll object with single vote
   */
  vote = (emission, option) => {
    if (this.props.userInfo.ban.banned)
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
    else {
      if (this.props.userInfo._id) {
        if (
          !this.state.pollsSubmitting.find(
            (poll) => poll.emissionID === emission.emissionID
          )
        ) {
          let voteObj = this.state.polls.find(
            (vote) => vote.emissionID === emission.emissionID
          );
          if (voteObj) {
            if (voteObj.votes.find((v) => v === option))
              this.setState((curr) => ({
                ...curr,
                polls: [
                  ...curr.polls.filter(
                    (p) => p.emissionID !== emission.emissionID
                  ),
                  {
                    ...voteObj,
                    votes: voteObj.votes.filter((v) => v !== option),
                  },
                ],
              }));
            else
              this.setState((curr) => ({
                ...curr,
                polls: [
                  ...curr.polls.filter(
                    (p) => p.emissionID !== emission.emissionID
                  ),
                  {
                    ...voteObj,
                    votes: [
                      option,
                      ...voteObj.votes.filter(
                        (vote, v) => v < emission.pollData.votesAllowed - 1
                      ),
                    ],
                  },
                ],
              }));
          } else
            this.setState((curr) => ({
              ...curr,
              polls: [
                ...curr.polls,
                {
                  emissionID: emission.emissionID,
                  votes: [option],
                },
              ],
            }));
        }
      } else {
        this.setState((curr) => ({
          ...curr,
          tempAction: {
            label: "Vote",
            emission: emission,
            option: option,
          },
          loginModalShown: !this.state.loginModalShown,
        }));
      }
    }
  };

  /**
   *
   * @param {Object} emission - Emissions document
   * @param {Boolean} participation - Whether the user is participating in the poll
   *
   * Triggered when the user submits a poll
   *
   * Submit poll data to server
   * Update emission
   */
  submitVotes = (emission, participation) => {
    if (this.props.userInfo.ban.banned)
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
    else {
      if (this.props.userInfo._id) {
        if (
          !this.state.pollsSubmitting.find(
            (poll) => poll.emissionID === emission.emissionID
          )
        ) {
          if (participation) {
            const pollObj = this.state.polls.find(
              (poll) => poll.emissionID === emission.emissionID
            );
            if (!pollObj || !pollObj.votes.length)
              alert("You must enter at least one vote.");
            else
              this.setState(
                (curr) => ({
                  ...curr,
                  pollsSubmitting: [
                    ...curr.pollsSubmitting,
                    {
                      emissionID: emission.emissionID,
                      participating: true,
                    },
                  ],
                }),
                () =>
                  axios
                    .post(
                      process.env.REACT_APP_LAMBDA_EMISSIONS + "/vote",
                      {
                        ...pollObj,
                        participating: true,
                        emissionID: emission.emissionID,
                        pollID: emission.pollID,
                      },
                      {
                        headers: {
                          Authorization: this.props.token,
                        },
                      }
                    )
                    .then((res) => {
                      this.props.set_token(res.data.token);
                      this.setState(
                        (curr) => ({
                          ...curr,
                          pollsSubmitting: this.state.pollsSubmitting.filter(
                            (poll) => poll.emissionID !== emission.emissionID
                          ),
                        }),
                        () =>
                          this.voteOther(res.data.newPoll, emission.emissionID)
                      );
                    })
                    .catch((err) =>
                      this.setState(
                        (curr) => ({
                          ...curr,
                          pollsSubmitting: this.state.pollsSubmitting.filter(
                            (poll) => poll.emissionID !== emission.emissionID
                          ),
                        }),
                        () => {
                          console.log(err);
                          alert("An error occurred. Please try again later.");
                        }
                      )
                    )
              );
          } else
            this.setState(
              (curr) => ({
                ...curr,
                pollsSubmitting: [
                  ...curr.pollsSubmitting,
                  {
                    emissionID: emission.emissionID,
                    participating: false,
                  },
                ],
              }),
              () =>
                axios
                  .post(
                    process.env.REACT_APP_LAMBDA_EMISSIONS + "/vote",
                    {
                      emissionID: emission.emissionID,
                      participating: false,
                      pollID: emission.pollID,
                    },
                    {
                      headers: {
                        Authorization: this.props.token,
                      },
                    }
                  )
                  .then((res) => {
                    this.props.set_token(res.data.token);
                    this.setState(
                      (curr) => ({
                        ...curr,
                        pollsSubmitting: this.state.pollsSubmitting.filter(
                          (poll) => poll.emissionID !== emission.emissionID
                        ),
                      }),
                      () => this.setPollVoted(emission.emissionID)
                    );
                  })
                  .catch((err) =>
                    this.setState(
                      (curr) => ({
                        ...curr,
                        pollsSubmitting: this.state.pollsSubmitting.filter(
                          (poll) => poll.emissionID !== emission.emissionID
                        ),
                      }),
                      () => {
                        console.log(err);
                        alert("An error occurred. Please try again later.");
                      }
                    )
                  )
            );
        }
      } else {
        this.setState((curr) => ({
          ...curr,
          tempAction: {
            label: "Submit Votes",
            emission: emission,
            participation: participation,
          },
          loginModalShown: !this.state.loginModalShown,
        }));
      }
    }
  };

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Updates all emissions with emissionID with vote details
   */
  setPollVoted = (emissionID, subsequentEvents) =>
    this.setState(
      (curr) => ({
        ...curr,
        emissions: h.setVoted(this.state.emissions, emissionID),
      }),
      () => {
        if (subsequentEvents) this.handleSocketEvents(subsequentEvents);
      }
    );

  setTempAction = (data) =>
    this.setState((curr) => ({
      ...curr,
      tempAction: data,
    }));

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when the user likes or unlikes an emission
   * Increments or decrements all emissions with that emissionID as necessary
   */
  likeSelf = (emissionID, eventID) =>
    this.setState(
      (curr) => ({
        ...curr,
        socketEvents: [...this.state.socketEvents, eventID],
        emissions: this.state.emissions.map((emission) => {
          if (emission.emissionID === emissionID) {
            console.log("hit", emission);
            emission = {
              ...emission,
              liked: !emission.liked,
              likes: emission.liked ? emission.likes - 1 : emission.likes + 1,
            };
          }

          if (
            emission.signalBoost &&
            emission.signalBoost.emissionID === emissionID
          )
            emission.signalBoost = {
              ...emission.signalBoost,
              liked: !emission.signalBoost.liked,
              likes: emission.signalBoost.liked
                ? emission.signalBoost.likes - 1
                : emission.signalBoost.likes + 1,
            };

          if (emission.replyEmission) {
            if (emission.replyEmission.emissionID === emissionID)
              emission.replyEmission = {
                ...emission.replyEmission,
                liked: !emission.replyEmission.liked,
                likes: emission.replyEmission.liked
                  ? emission.replyEmission.likes - 1
                  : emission.replyEmission.likes + 1,
              };
            if (
              emission.replyEmission.signalBoost &&
              emission.replyEmission.signalBoost.emissionID === emissionID
            )
              emission.replyEmission.signalBoost = {
                ...emission.replyEmission.signalBoost,
                liked: !emission.replyEmission.signalBoost.liked,
                likes: emission.replyEmission.signalBoost.liked
                  ? emission.replyEmission.signalBoost.likes - 1
                  : emission.replyEmission.signalBoost.likes + 1,
              };
          }

          if (emission.replyEmission && emission.replyEmission.replyEmission) {
            if (emission.replyEmission.replyEmission.emissionID === emissionID)
              emission.replyEmission.replyEmission = {
                ...emission.replyEmission.replyEmission,
                liked: !emission.replyEmission.replyEmission.liked,
                likes: emission.replyEmission.replyEmission.liked
                  ? emission.replyEmission.replyEmission.likes - 1
                  : emission.replyEmission.replyEmission.likes + 1,
              };
            if (
              emission.replyEmission.replyEmission.signalBoost &&
              emission.replyEmission.replyEmission.signalBoost.emissionID ===
                emissionID
            )
              emission.replyEmission.replyEmission.signalBoost = {
                ...emission.replyEmission.replyEmission.signalBoost,
                liked: !emission.replyEmission.replyEmission.signalBoost.liked,
                likes: emission.replyEmission.replyEmission.signalBoost.liked
                  ? emission.replyEmission.replyEmission.signalBoost.likes - 1
                  : emission.replyEmission.replyEmission.signalBoost.likes + 1,
              };
          }

          return emission;
        }),
      }),
      () =>
        console.log(
          "after",
          this.state.emissions
            .filter((e) => e.score)
            .sort((a, b) => b.score - a.score)
        )
    );

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when the user likes or unlikes an emission
   * Triggers this.likeSelf
   * Make network request
   */
  like = (emissionID) => {
    if (this.props.userInfo.ban.banned)
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
    else {
      if (this.props.userInfo._id) {
        const eventID = uuid();
        this.likeSelf(emissionID, eventID);
        this.props.like_self(emissionID, eventID);
        const likeOnNetwork = () =>
          axios
            .post(
              `${process.env.REACT_APP_LAMBDA_EMISSIONS}/like`,
              { eventID, emissionID },
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => this.props.set_token(res.data.token))
            .catch((err) => {
              console.log("Error committing the like", err);
              if (err.response?.status === 401) console.log("Forbidden.");
              else setTimeout(likeOnNetwork, 250);
            });

        likeOnNetwork();
      } else {
        this.setState((curr) => ({
          ...curr,
          tempAction: {
            label: "Like",
            emissionID: emissionID,
          },
          loginModalShown: !this.state.loginModalShown,
        }));
      }
    }
  };

  /**
   *
   * @param {Object} file - Emission file object
   * @param {Array} fileList - List of files on emission that the file belongs to
   * @param {Emission} emission - Emission that the file belongs to
   *
   * Set file, fileList and emission into state
   * Toggle file modal
   */
  selectFile = (file, fileList, emission) =>
    this.setState((curr) => ({
      ...curr,
      fileSelected: file,
      fileList: fileList,
      emissionSelected: emission._id,
      fileModalShown: !this.state.fileModalShown,
    }));

  /**
   *
   * @param {Object} emission - Emissions document
   *
   * Triggered when the user clicks the Signalboost button
   * Opens the Signalboost modal if logged in
   * Opens the Login modal if not logged in
   */
  signalBoost = (emission) => {
    if (!this.props.userInfo.ban.banned) {
      if (this.props.userInfo._id)
        this.setState((curr) => ({
          ...curr,
          emissionSignalBoosting: emission,
          signalBoostModalShown: !this.state.signalBoostModalShown,
        }));
      else {
        this.setState((curr) => ({
          ...curr,
          tempAction: {
            label: "Signalboost",
            emission: emission,
          },
          loginModalShown: !this.state.loginModalShown,
        }));
      }
    } else
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
  };

  /**
   *
   * @param {Boolean} forward - Whether the user is navigating forward
   *
   * Triggered when the user clicks one of the arrows in the file modal
   * Changes the file selected to the previous or next depending on forward
   */
  fileNav = (forward) =>
    this.setState((curr) => ({
      ...curr,
      fileSelected:
        this.state.fileList[
          forward
            ? this.state.fileList.indexOf(this.state.fileSelected) + 1
            : this.state.fileList.indexOf(this.state.fileSelected) - 1
        ],
    }));

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionId
   *
   * Triggered when the user clicks the Copy Link button on an emission
   * Copies the link to the clipboard
   */
  copyEmissionLink = (emissionID) =>
    this.setState(
      (curr) => ({
        ...curr,
        emissionCopied: emissionID,
      }),
      () => {
        navigator.clipboard.writeText(env.HOST + "/e/" + emissionID);
        window.dispatchEvent(new Event("scroll"));
      }
    );

  /**
   *
   * @param {Object} emission - Emissions document
   *
   * Triggered when the user clicks the Reply button
   * Opens the Reply modal if logged in
   * Opens the Login modal if not logged in
   */
  reply = (emission) => {
    if (!this.props.userInfo.ban.banned) {
      if (this.props.userInfo._id)
        this.setState((curr) => ({
          ...curr,
          emissionReplying: emission,
          fileModalShown: false,
          replyModalShown: !this.state.replyModalShown,
        }));
      else {
        this.setState((curr) => ({
          ...curr,
          tempAction: {
            label: "Reply",
            emission: emission,
          },
          loginModalShown: !this.state.loginModalShown,
        }));
      }
    } else
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
  };

  toggleRemoveModal = () => {
    this.setState(
      (curr) => ({
        ...curr,
        removeModalShown: !this.state.removeModalShown,
      }),
      h.hideToolTips
    );
  };

  setRemoveModal = (option) => {
    this.setState((curr) => ({
      ...curr,
      removeModalShown: option,
    }));
  };

  remove = (emission) =>
    this.setState((curr) => ({
      ...curr,
      emissionRemoving: emission,
      removeModalShown: !this.state.removeModalShown,
    }));

  toggleRestoreModal = () => {
    this.setState(
      (curr) => ({
        ...curr,
        restoreModalShown: !this.state.restoreModalShown,
      }),
      () => h.hideToolTips()
    );
  };

  setRestoreModal = (option) => {
    this.setState((curr) => ({
      ...curr,
      restoreModalShown: option,
    }));
  };

  restore = (emission) =>
    this.setState((curr) => ({
      ...curr,
      emissionRestoring: emission,
      restoreModalShown: !this.state.restoreModalShown,
    }));

  toggleReportModal = () => {
    this.setState(
      (curr) => ({
        ...curr,
        reportModalShown: !this.state.reportModalShown,
      }),
      h.hideToolTips
    );
  };

  setReportModal = (option) => {
    this.setState((curr) => ({
      ...curr,
      reportModalShown: option,
    }));
  };

  report = (emission) =>
    this.setState((curr) => ({
      ...curr,
      emissionReporting: emission,
      reportModalShown: !this.state.reportModalShown,
    }));

  fileAction = (action) =>
    this.setState(
      (curr) => ({
        ...curr,
        fileModalShown: false,
      }),
      action
    );

  getEmissionSelected = (emissions) => {
    let emissionSelected;
    emissionSelected = emissions.find(
      (e) => e._id === this.state.emissionSelected
    );
    if (!emissionSelected)
      emissionSelected = emissions.find(
        (e) => e.signalBoost?._id === this.state.emissionSelected
      )?.signalBoost;
    if (!emissionSelected)
      emissionSelected = emissions.find(
        (e) => e.replyEmission?._id === this.state.emissionSelected
      )?.replyEmission;
    if (!emissionSelected)
      emissionSelected = emissions.find(
        (e) => e.replyEmission?.signalBoost?._id === this.state.emissionSelected
      )?.replyEmission?.signalBoost;
    if (!emissionSelected)
      emissionSelected = emissions.find(
        (e) =>
          e.replyEmission?.replyEmission?._id === this.state.emissionSelected
      )?.replyEmission?.replyEmission;
    if (!emissionSelected)
      emissionSelected = emissions.find(
        (e) =>
          e.replyEmission?.replyEmission?.signalBoost?._id ===
          this.state.emissionSelected
      )?.replyEmission?.replyEmission?.signalBoost;

    return emissionSelected;
  };

  render() {
    const listProps = {
      flavor: this.flavor,
      emissions: this.state.emissions,
      emissionID: this.props.emissionID,
      loaded: this.state.loaded,
      clickEmissionBody: this.clickEmissionBody,
      vote: this.vote,
      polls: this.state.polls,
      submitVotes: this.submitVotes,
      pollsSubmitting: this.state.pollsSubmitting,
      selectFile: this.selectFile,
      signalBoost: this.signalBoost,
      like: this.like,
      copyEmissionLink: this.copyEmissionLink,
      emissionCopied: this.state.emissionCopied,
      reply: this.reply,
      index: true,
      userInfo: this.props.userInfo,
      route: this.props.route,
      remove: this.remove,
      restore: this.restore,
      report: this.report,
      updateEmission: this.updateEmission,
      loadingMore: this.props.loadingMore,
      seeMore: this.seeMore,
      setPollModal: this.props.set_poll_modal,
      animation: t.fade_out,
      checkNewer: this.checkNewer,
      checkingNewer: this.props.checkingNewer,
      totalEnd: this.props.totalEnd,
      followEnd: this.props.followEnd,
      popularEnd: this.props.popularEnd,
      loadMore: this.loadMore,
      emissionEndReached: this.props.emissionEndReached,
      endReached: this.props.endReached,
      tag: this.props.tag,
      loading: this.props.loading,
      setClickPass: this.props.set_click_pass,
      clickPass: this.props.clickPass,
      token: this.props.token,
      set_token: this.props.set_token,
    };

    const emissionSelected = this.getEmissionSelected(this.state.emissions);
    let replies = [];
    if (emissionSelected) {
      this.state.emissions.forEach((emission) => {
        if (
          !replies.find((r) => r._id === emission._id) &&
          emission.replyID === emissionSelected.emissionID
        )
          replies.push(emission);
        if (
          !replies.find((r) => r._id === emission.signalBoost?._id) &&
          emission.signalBoost?.replyID === emissionSelected.emissionID
        )
          replies.push(emission.signalBoost);
        if (emission.replyEmission) {
          if (
            !replies.find((r) => r._id === emission.replyEmission._id) &&
            emission.replyEmission.replyID === emissionSelected.emissionID
          )
            replies.push(emission.replyEmission);
          if (
            !replies.find(
              (r) => r._id === emission.replyEmission.signalBoost?._id
            ) &&
            emission.replyEmission.signalBoost?.replyID ===
              emissionSelected.emissionID
          )
            replies.push(emission.replyEmission.signalBoost);
          if (emission.replyEmission.replyEmission) {
            if (
              !replies.find(
                (r) => r._id === emission.replyEmission.replyEmission._id
              ) &&
              emission.replyEmission.replyEmission.replyID ===
                emissionSelected.emissionID
            )
              replies.push(emission.replyEmission.replyEmission);
            if (
              !replies.find(
                (r) =>
                  r._id ===
                  emission.replyEmission.replyEmission.signalBoost?._id
              ) &&
              emission.replyEmission.replyEmission.signalBoost?.replyID ===
                emissionSelected.emissionID
            )
              replies.push(emission.replyEmission.replyEmission.signalBoost);
          }
        }
      });

      replies = replies.sort((a, b) => b.emissionID - a.emissionID);
    }
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
        className="pt-4 h-100"
      >
        <LoginModal
          modalShown={this.state.loginModalShown}
          toggleShowModal={this.toggleLoginModal}
          setShowModal={this.setLoginModal}
          emissions={this.state.emissions.map((e) => e.emissionID)}
          setEmissions={this.setEmissions}
          tempAction={this.state.tempAction}
          flavor="generic"
        />
        <FileModal
          modalShown={this.state.fileModalShown}
          setShowModal={this.setFileModal}
          toggleShowModal={this.toggleFileModal}
          file={this.state.fileSelected}
          fileList={this.state.fileList}
          fileNav={this.fileNav}
          emission={emissionSelected}
          signalBoost={this.signalBoost}
          like={this.like}
          report={this.report}
          updateEmission={this.updateEmission}
          emissions={this.state.emissions}
          reply={this.reply}
          fileAction={this.fileAction}
          replies={replies}
        />
        <SignalBoostModal
          modalShown={this.state.signalBoostModalShown}
          setShowModal={this.setSignalBoostModal}
          toggleShowModal={this.toggleSignalBoostModal}
          emission={this.state.emissionSignalBoosting}
          signalBoost={this.signalBoostOther}
        />
        <ReplyModal
          modalShown={this.state.replyModalShown}
          setShowModal={this.setReplyModal}
          toggleShowModal={this.toggleReplyModal}
          emission={this.state.emissionReplying}
        />
        <RemoveModal
          modalShown={this.state.removeModalShown}
          setShowModal={this.setRemoveModal}
          toggleShowModal={this.toggleRemoveModal}
          emission={this.state.emissionRemoving}
          captchaReady={this.props.captchaReady}
          updateEmission={this.updateEmission}
        />
        <RestoreModal
          modalShown={this.state.restoreModalShown}
          setShowModal={this.setRestoreModal}
          toggleShowModal={this.toggleRestoreModal}
          emission={this.state.emissionRestoring}
          captchaReady={this.props.captchaReady}
          updateEmission={this.updateEmission}
          token={this.props.token}
          set_token={this.props.set_token}
        />
        <ReportModal
          modalShown={this.state.reportModalShown}
          setShowModal={this.setReportModal}
          toggleShowModal={this.toggleReportModal}
          emission={this.state.emissionReporting}
          captchaReady={this.props.captchaReady}
          setEmissionReported={this.setEmissionReported}
        />
        <EmissionList {...listProps} />
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  route,
  set_poll_modal,
  set_user,
  set_click_pass,
  set_token,
  notify,
  signal_boost,
  like_self,
})(EmissionPage);
