import React from "react";
import env from "../env";

export default function LogoLoader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "2rem",
      }}
    >
      <img
        style={{
          width: "86px",
          height: "86px",
          display: "block",
          margin: "0 auto",
        }}
        src={`${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/icons/favicon-96x96.png`}
        class="logo-flash"
      />
    </div>
  );
}
