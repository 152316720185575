import React from "react";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import { route, purge_user, set_token } from "../redux/actions";
import { connect } from "react-redux";
import LogoLoader from "../components/LogoLoader";
import axios from "axios";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

class Delete extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: "",
      working: true,
      attempts: 0,
    };
    this.requestID = props.match.params.id;
  }

  componentDidMount() {
    this.run();
  }

  run = () =>
    axios
      .get(
        process.env.REACT_APP_LAMBDA_AUTH +
          "/delete-cancel-action/" +
          this.requestID,
        {
          headers: {
            Authorization: this.props.token,
          },
        }
      )
      .then((res) => {
        this.props.set_token(res.data.token);
        this.setState((curr) => ({
          ...curr,
          working: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        if (this.state.attempts >= 3)
          this.setState((curr) => ({
            ...curr,
            error:
              "An unknown error occurred. Please check your connection and try again later.",
            working: false,
          }));
        else
          this.setState(
            (curr) => ({
              ...curr,
              attempts: this.state.attempts + 1,
            }),
            () => setTimeout(this.run, 1000)
          );
      });

  render() {
    return (
      <motion.div
        transition={t.transition}
        initial={t.fade_out}
        animate={t.normalize}
        exit={t.fade_out_scale_1}
        className="page-container"
      >
        <MDBContainer>
          {this.state.working ? (
            <motion.section
              transition={t.transition}
              initial={t.fade_out}
              animate={t.normalize}
              exit={t.fade_out_scale_1}
            >
              <h5 className="text-center display-6 my-4">Cancelling Request</h5>
              <LogoLoader />
            </motion.section>
          ) : (
            <motion.div
              transition={t.transition}
              initial={t.fade_out}
              animate={t.normalize}
              exit={t.fade_out_scale_1}
            >
              <h5 className="text-center my-4">
                {this.state.error ||
                  "Your account deletion request was successfully canceled"}
              </h5>
              <MDBBtn
                className="d-block mx-auto"
                rippleColor="primary"
                color="link"
                onClick={() => this.props.route("/")}
              >
                <i className="fas fa-home me-2" />
                Return
              </MDBBtn>
            </motion.div>
          )}
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { route, purge_user, set_token })(
  withRouter(Delete)
);
