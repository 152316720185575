import React, { useState } from "react";
import { Collapse } from "@mui/material";
import { MDBBtn } from "mdb-react-ui-kit";
import { connect } from "react-redux";
import Spinner from "./Spinner";
import axios from "axios";
import { set_token } from "../redux/actions";

const RemoveFile = ({
  file,
  callback,
  centered,
  className,
  set_token,
  token,
}) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [working, setWorking] = useState(false);

  const confirm = () => {
    setCollapseOpen(false);
    setWorking(true);
    axios
      .post(
        process.env.REACT_APP_LAMBDA_SUPPORT + "/delete-file-permanent",
        { file },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        set_token(res.data.token);
        if (callback) callback();
        setWorking(false);
      })
      .catch((err) => {
        setWorking(false);
        console.log(err);
        alert("An error occurred. Please try again later");
      });
  };

  return (
    <div
      className={`d-flex flex-column justify-content-start ${
        centered ? "mx-auto" : ""
      } ${className}`}
    >
      <MDBBtn
        onClick={() => setCollapseOpen(!collapseOpen)}
        disabled={working}
        className="bg-orange"
        type="button"
      >
        {working ? (
          <>
            <Spinner className="me-2" size="sm" />
            Deleting
          </>
        ) : (
          <>
            {collapseOpen ? (
              "ARE YOU SURE?"
            ) : (
              <>
                <i className="fas fa-fire me-2" />
                PERMANENTLY DELETE
              </>
            )}
          </>
        )}
      </MDBBtn>
      <Collapse in={collapseOpen}>
        <MDBBtn
          disabled={working}
          color="link"
          rippleColor="primary"
          className={`d-block ${centered ? "mx-auto" : ""}`}
          onClick={() => setCollapseOpen(false)}
          type="button"
        >
          No
        </MDBBtn>
        <MDBBtn
          disabled={working}
          color="link"
          rippleColor="primary"
          onClick={confirm}
          className={`d-block ${centered ? "mx-auto" : ""}`}
          type="button"
        >
          Yes
        </MDBBtn>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.token,
});

export default connect(mapStateToProps, { set_token })(RemoveFile);
