import env from "../../env";
import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import TextInput from "../textInput/TextInput";
import axios from "axios";
import { route, set_token } from "../../redux/actions";

const maxChars = Number(env.MAX_EMISSION_CHARS);

class GlobalEmissionInputModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether an emission is in the process of being submitted to or processed by the server
       * reset: Boolean - When flipped, the emission input will reset
       */
      working: false,
      reset: false,
    };
  }

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   *
   * @param {Array} files - List of files to be submitted with the emission
   * @param {false | Object} pollData - Poll data, if poll was added
   *
   * Triggered when the user submits an emission
   *
   * Validate files, if any
   * Validate emission
   * Grab captcha key
   * Submit emission to server
   * Hide modal
   * Navigate to new emission
   */
  submit = (files, pollData) => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        async () => {
          try {
            if (
              files.reduce((prev, curr) => prev + curr.size, 0) >
              Number(env.MAX_TOTAL_FILE_SIZE)
            )
              throw `Max total file size exceeded. (Max: ${Math.round(
                Number(env.MAX_TOTAL_FILE_SIZE) / (1024 * 1024)
              )}MB)`;
            const emissionData = document.getElementById("input-global");
            const length = String(emissionData.textContent)
              .split("")
              .filter((c) => {
                const checkWhiteSpace = c.match(/[\s]/);
                if (!checkWhiteSpace) return true;
                else {
                  return [" ", "\n"].indexOf(c) > -1;
                }
              }).length;
            if (
              (!emissionData.textContent ||
                emissionData.innerHTML === "<div><p><br /></p></div>") &&
              !files.length &&
              !pollData
            )
              throw "Please enter text at least one file, or a poll";
            if (length > maxChars)
              throw `Character limit exceeded (Max: ${maxChars} characters)`;
            const fd = new FormData();
            const captchaKey = await this.getRecaptcha();
            fd.append("captchaKey", captchaKey);
            fd.append("emission", emissionData.innerHTML);
            if (pollData) fd.append("pollData", JSON.stringify(pollData));
            files.forEach((file) => {
              fd.append("files", file.file, file.name);
              if (file.file.type.includes("video"))
                fd.append("thumbnails", file.thumbnail, file.name);
            });
            axios
              .post(process.env.REACT_APP_LAMBDA_EMISSIONS, fd, {
                headers: {
                  Authorization: this.props.token,
                },
              })
              .then((res) => {
                this.props.toggleShowModal();
                this.props.set_token(res.data.token);
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                    reset: !this.state.reset,
                  }),
                  () => {
                    this.props.route(`/e/${res.data.emission.emissionID}`);
                  }
                );
              })
              .catch((err) =>
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                  }),
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later.");
                  }
                )
              );
          } catch (err) {
            this.setState(
              (curr) => ({
                ...curr,
                working: false,
              }),
              () => alert(err)
            );
          }
        }
      );
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={() => {
              if (!this.state.working) this.props.toggleShowModal();
            }}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>New {env.EMISSION_NAME}</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <TextInput
                    submit={this.submit}
                    working={this.state.working}
                    key={this.state.reset}
                    modalShown={this.props.modalShown}
                    label={
                      <span className="text-capitalize">
                        {env.EMISSION_NAME}
                      </span>
                    }
                    flavor="global"
                    maxChars={maxChars}
                  />
                </MDBModalBody>
                {this.props.screenDimensions.width <=
                this.props.screenDimensions.modalBreak ? (
                  <MDBModalFooter>
                    <MDBBtn
                      className="bg-gray"
                      color="dark"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                ) : (
                  <></>
                )}
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route, set_token })(
  GlobalEmissionInputModal
);
