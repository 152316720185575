import env from "../../env";
import React from "react";
import {
  MDBPopover,
  MDBPopoverBody,
  MDBTooltip,
  MDBRipple,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBListGroup,
  MDBListGroupItem,
  MDBSpinner,
  MDBContainer,
} from "mdb-react-ui-kit";
import { Collapse } from "@mui/material";
import EmojiPicker from "../EmojiPicker";
import LinkInsertForm from "./LinkInsertForm";
import h from "../../utilities/helpers";
import t from "../../utilities/transitions";
import { motion } from "framer-motion";
import PollForm from "./PollForm";
import { connect } from "react-redux";
import Spinner from "../Spinner";
import PollModal from "./PollModal";
import LinkModal from "./LinkModal";
import axios from "axios";
import {
  toggle_go_live_modal,
  new_emission,
  set_token,
  set_click_pass,
} from "../../redux/actions";
import LogoLoader from "../LogoLoader";

const allowedExtensions =
  typeof window === "undefined" ? [] : env.ALLOWED_EXTENSIONS.split(" ");
let Quill;
let spaces = 0;

class RichTextInput extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * input: String - HTML of bio
       * cursorLocation: Number - Index of cursor in bio input
       * editor: false | Quill Object - The Quill editor
       * working: Boolean - Whether a new account is being sent to or processed by the server
       * popoverOpen: Boolean - Whether the Link Insert popover is open
       * files: Array - List of files that the user has selected
       * processingFiles: Boolean - Whether files are being processed and md5s generated
       * mediaPlaying: String - id of audio/video file that is currently being played, if any
       * reset: Boolean - When flipped, Quill editor will clear
       * pollReset: Boolean - When flipped, PollForm will clear and set state back to its default values
       * pollFormShown: Boolean - Whether the poll form is shown
       * pollData: Object - Poll data object
       */
      input: "",
      cursorLocation: 0,
      editor: "",
      working: false,
      popoverOpen: false,
      files: [],
      processingFiles: false,
      mediaPlaying: "",
      reset: false,
      pollReset: false,
      pollFormShown: false,
      pollData: false,
      pollReset: false,
      linkModalShown: false,
      pollModalShown: false,
      loaded: false,
      holdingShift: false,
    };
    /**
     * this.insertSpace - When user presses the space button after inserting a link, inserts a space (fixes bug with hashtag/mention parser)
     * this.forceParse - Allows the parent to call the forceParse function
     */
    this.insertSpace = false;

    this.flavor = props.flavor;
    this.maxChars = props.maxChars;
  }

  /**
   * props.setForceParse - Allows the parent to call this.forceParse
   * Initialize Quill editor
   */
  componentDidMount() {
    try {
      if (this.props.setForceParse) {
        this.forceParse = this.forceParse.bind(this);
        this.props.setForceParse(this.forceParse);
      }
    } catch (err) {
      console.log("TextInput", err);
    }
    Quill = window.Quill;
    this.setEditor();
  }

  componentDidUpdate(prevProps) {
    if (
      this.flavor === "global" &&
      !prevProps.modalShown &&
      this.props.modalShown &&
      document.getElementById("input-" + this.flavor)?.firstChild?.innerHTML &&
      this.checkUserPage()
    ) {
      this.state.editor.setContents(
        this.state.editor.clipboard.convert({
          html: `<div><p><a class="text-success ql-mention">@${
            window.location.pathname.split("/")[1]
          }</a><span> </span></p></div>`,
        }),
        "silent"
      );
      this.state.editor.insertText(
        this.state.editor.getSelection()
          ? this.state.editor.getSelection().index
          : this.state.cursorLocation,
        "\\",
        "user"
      );
      document.getElementById("input-" + this.flavor).innerHTML = document
        .getElementById("input-" + this.flavor)
        .innerHTML.split("\\")
        .join("");
      this.moveToEnd();
    }

    if (prevProps.contentKey !== this.props.contentKey) {
      this.setEditor(true);
    }

    if (
      prevProps.clickPass?.action !== "emission-file-input" &&
      this.props.clickPass?.action === "emission-file-input" &&
      this.props.clickPass.id === this.flavor
    ) {
      this.selectFiles();
      this.props.set_click_pass(false);
    }

    if (
      prevProps.clickPass?.action !== "go-live" &&
      this.props.clickPass?.action === "go-live" &&
      this.props.clickPass.id === this.flavor
    ) {
      this.props.toggle_go_live_modal();
      this.props.set_click_pass(false);
    }
  }

  moveToEnd = () => {
    try {
      this.focus();
      const editor = document.getElementById("input-" + this.flavor).firstChild;
      const range = document.createRange();
      const sel = window.getSelection();

      range.setStart(editor, editor.childNodes.length);
      range.collapse(true);

      sel.removeAllRanges();
      sel.addRange(range);
    } catch (err) {
      console.log("moveToEnd error", err);
    }
  };

  /**
   * Button text indicates the number of seconds left if there is cooldown, shows paper plane button if none
   * Transition between div/section so that framer-motion animation plays
   */
  getButtonText = (cooldown) => {
    switch (cooldown) {
      case 0:
        return (
          <motion.div
            transition={t.transition}
            exit={t.fade_out_minimize}
            animate={t.normalize}
            initial={t.fade_out_minimize}
          >
            <i className="fas fa-paper-plane" />
          </motion.div>
        );
      case 1:
        return (
          <motion.section
            transition={t.transition}
            exit={t.fade_out_minimize}
            animate={t.normalize}
            initial={t.fade_out_minimize}
          >
            1
          </motion.section>
        );
      case 2:
        return (
          <motion.div
            transition={t.transition}
            exit={t.fade_out_minimize}
            animate={t.normalize}
            initial={t.fade_out_minimize}
          >
            2
          </motion.div>
        );
      case 3:
        return (
          <motion.article
            transition={t.transition}
            exit={t.fade_out_minimize}
            animate={t.normalize}
            initial={t.fade_out_minimize}
          >
            3
          </motion.article>
        );
      default:
        console.log("oob button text", this.state.cooldown);
        return (
          <motion.div
            transition={t.transition}
            exit={t.fade_out_minimize}
            animate={t.normalize}
            initial={t.fade_out_minimize}
          >
            <i className="fas fa-paper-plane" />
          </motion.div>
        );
    }
  };

  /**
   *
   * @returns Whether the user is currently on a profile page that is not their own
   */
  checkUserPage = () =>
    [
      "/",
      "/search",
      "/info",
      "/logs",
      "/login",
      "/forgot-password",
      "/check-email",
      "/awaiting-approval-email",
      "/awaiting-approval",
      "/received",
      "/validate-email",
      "/create-account",
      "/messages",
      "/contact",
      "/reports",
      "/notifications",
      "/not-found",
      "/null",
    ].indexOf(window.location.pathname) === -1 &&
    window.location.pathname.split("/").length === 2 &&
    window.location.pathname.split("/")[1].split("#")[0] !==
      this.props.userInfo.username;

  togglePollForm = () => {
    h.hideToolTips();
    this.setState((curr) => ({
      ...curr,
      pollFormShown: !this.state.pollFormShown,
    }));
  };

  /**
   * Removes all event listeners
   * Initializes new Quill editor
   * Adds event listeners for keypress and selection change events
   */
  setEditor = (focusAfter, html) => {
    try {
      if (this.state.editor) {
        this.state.editor.off("selection-change");
        this.state.editor.off("text-change");
        const keyNode = document.getElementById("input-" + this.flavor)
          .childNodes[0];
        if (keyNode) {
          keyNode.removeEventListener("keydown", this.keyDown);
          keyNode.removeEventListener("keyup", this.keyUp);
          keyNode.removeEventListener("keypress", this.keyPress);
        }
        spaces = 0;
      }
      this.setState(
        (curr) => ({
          ...curr,
          editor: new Quill("#input-" + this.flavor, {
            modules: {
              autoformat: {
                hashtag: {
                  trigger: /[\s.,;:!?\\]/,
                  find: /(?:^|\s)#[^\s.,;:!?]+/i,
                  extract: /#([^\s.,;:!?]+)/i,
                  transform: "$1",
                  insert: "hashtag",
                },
                mention: {
                  trigger: /[\s.,;:!?\\]/,
                  find: /(?:^|\s)@[^\s.,;:!?]+/i,
                  extract: /@([^\s.,;:!?]+)/i,
                  transform: "$1",
                  insert: "mention",
                },
                link: {
                  trigger: /[\s\\]/,
                  find: /https?:\/\/[\S]+|(www\.[\S]+)/gi,
                  transform: function (value, noProtocol) {
                    return noProtocol ? "https://" + value : value;
                  },
                  format: "link",
                },
              },
              keyboard: {
                bindings: {
                  "list autofill": {
                    prefix: /^\s*()$/,
                  },
                },
              },
            },
          }),
        }),
        () => {
          try {
            if (this.props.prefilledHTML) {
              this.focus();
              this.state.editor.setContents(
                this.state.editor.clipboard.convert({
                  html: this.props.prefilledHTML,
                }),
                "silent"
              );
            }
            this.state.editor.on("selection-change", this.selectionChange);
            this.state.editor.on("text-change", this.textChange);
            if (this.state.loaded) this.blur();
            Array.from(
              document.getElementById("input-" + this.flavor).childNodes
            ).forEach((node) => {
              if (node.getAttribute("contenteditable")) {
                node.addEventListener("keydown", this.keyDown);
                node.addEventListener("keyup", this.keyUp);
                node.addEventListener("keypress", this.keyPress);
              }
            });
            this.setState(
              (curr) => ({
                ...curr,
                loaded: true,
              }),
              () => {
                try {
                  if (focusAfter) {
                    this.focus();
                    const editor = document.getElementById(
                      "input-" + this.flavor
                    );
                    const range = document.createRange();
                    const sel = window.getSelection();

                    range.setStart(editor.childNodes[0], 0);
                    range.collapse(true);

                    sel.removeAllRanges();
                    sel.addRange(range);
                  }
                } catch (err) {
                  console.log("setEditor callback 2 error", err);
                }
              }
            );
          } catch (err) {
            console.log("setEditor callback 1 error", err);
          }
        }
      );
    } catch (err) {
      console.log("setEditor error", err);
    }
  };

  // Closes the link insert popover and removes the mousedown event listener
  closePopover = (e) => {
    h.hideToolTips();
    if (
      !(e && e.target && e.target.classList.contains("page-popover-triggers"))
    ) {
      if (
        e &&
        e.target &&
        e.target.classList.contains("page-popover-triggers-timeout")
      )
        setTimeout(() => {
          this.setState(
            (curr) => ({
              ...curr,
              popoverOpen: false,
            }),
            () => {
              document.body.removeEventListener("mousedown", this.closePopover);
            }
          );
        }, 200);
      else
        this.setState(
          (curr) => ({
            ...curr,
            popoverOpen: false,
          }),
          () => {
            document.body.removeEventListener("mousedown", this.closePopover);
          }
        );
    }
  };

  // Opens the link insert popover and adds an event listener which closes the form if the user clicks outside it
  showPopover = () => {
    h.hideToolTips();
    this.setState(
      (curr) => ({
        ...curr,
        popoverOpen: true,
      }),
      () => {
        setTimeout(
          () => document.body.addEventListener("mousedown", this.closePopover),
          500
        );
      }
    );
  };

  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   *
   * @param {Event} e - Javascript keypress event
   *
   * Updates the number of spaces in the editor.
   */
  keyDown = (e) => {
    spaces = e.target.textContent.split(" ").length;
  };

  /**
   *
   * @param {Event} e - Javascript keypress event
   *
   * Detects when the user presses the space bar
   * If spacebar is pressed, preventDefault and manually insert a space into the editor
   * Prevents bug that occurs with mention/link/hashtag parser that leaves the cursor stuck at the end of links
   *
   * Does not work on android as android does not pass keyCodes
   *
   */
  keyPress = (e) => {
    if (
      e.key === " "
      // &&
      // !(
      //   /iPad|iPhone|iPod/.test(
      //     navigator.userAgent || navigator.vendor || window.opera
      //   ) && !window.MSStream
      // )
    ) {
      e.preventDefault();
      spaces++;
      this.state.editor.insertText(
        this.state.editor.getSelection()
          ? this.state.editor.getSelection().index
          : this.state.cursorLocation,
        " ",
        "user"
      );
      this.insertSpace = true;
      setTimeout(
        () =>
          this.state.editor.setSelection(
            (this.state.editor.getSelection()
              ? this.state.editor.getSelection().index
              : this.state.cursorLocation) + 1
          ),
        0
      );
    }
  };

  /**
   *
   * @param {Event} e - Javascript keypress event
   *
   * Workaround for the above keyPress function as android does not pass keyCodes
   */
  keyUp = (e) => {
    if (
      e.target.textContent.split(" ").length > spaces &&
      /android/i.test(navigator.userAgent || navigator.vendor || window.opera)
    ) {
      e.preventDefault();
      const location = this.state.editor.getSelection()
        ? this.state.editor.getSelection().index
        : this.state.cursorLocation;
      this.insertSpace = true;
      setTimeout(() => this.state.editor.setSelection(location), 0);
    }
  };

  /**
   * Triggered whenever the text in the editor changes after parsers have run
   * Adds text color classes to parsed mentions and hashtags
   */
  textChange = () => {
    this.setState(
      (curr) => ({
        ...curr,
        input: document.getElementById("input-" + this.flavor).textContent,
      }),
      () => {
        Array.from(document.getElementsByClassName("ql-mention")).forEach(
          (mention) => {
            if (!mention.classList.contains("text-success"))
              mention.classList.add("text-success");
          }
        );
        Array.from(document.getElementsByClassName("ql-hashtag")).forEach(
          (hashtag) => {
            if (!hashtag.classList.contains("text-secondary"))
              hashtag.classList.add("text-secondary");
            if (
              Array.from(hashtag.childNodes).find(
                (node) => node.tagName === "SPAN"
              )
            )
              hashtag.setAttribute(
                "href",
                `/tag/${
                  Array.from(hashtag.childNodes)
                    .find((node) => node.tagName === "SPAN")
                    .textContent.split("#")[1]
                }`
              );
          }
        );
      }
    );
  };

  /**
   *
   * @param {Object} range - Quill range object
   *
   * Set cursor location into state
   */
  selectionChange = (range) => {
    if (range)
      this.setState((curr) => ({
        ...curr,
        cursorLocation: range.index,
      }));
  };

  /**
   * Triggered when the text input is put into focus
   * Adds appropriate styles and classes
   */
  focus = () => {
    document.getElementById("label-" + this.flavor).style.top = "-0.5rem";
    document
      .getElementById("label-" + this.flavor)
      .classList.add("text-primary");
    document.getElementById("label-" + this.flavor).style.fontSize = "0.75rem";
    document.getElementById("label-" + this.flavor).style.padding = "0px 5px";
    document.getElementById("wrapper-" + this.flavor).style.border =
      "1px solid rgba(18, 102, 241, 1)";
    document
      .getElementById("input-" + this.flavor)
      .classList.add("emission-focus");
  };

  /**
   * Triggered when the text input is put into focus
   * Removes styles and classes which were added on focus
   */
  blur = () => {
    document
      .getElementById("label-" + this.flavor)
      .classList.remove("text-primary");
    document.getElementById("wrapper-" + this.flavor).style.border =
      "1px solid rgba(18, 102, 241, 0)";
    document
      .getElementById("input-" + this.flavor)
      .classList.remove("emission-focus");
    if (
      !document.getElementById("input-" + this.flavor).textContent &&
      !this.state.linkShown &&
      !this.state.cursorLocation
    ) {
      document.getElementById("label-" + this.flavor).style.top = "1rem";
      document.getElementById("label-" + this.flavor).style.fontSize =
        "var(--mdb-body-font-size)";
      document.getElementById("label-" + this.flavor).style.padding = "0px";
    }
    if (this.state.holdingShift)
      this.setState((curr) => ({
        ...curr,
        holdingShift: false,
      }));
  };

  /**
   *
   * @param {Object} e - Emoji picker object
   *
   * Inserts emoji into editor at current cursor location
   */
  selectEmoji = (e) => {
    if (!this.props.working) {
      this.state.editor.focus();
      this.state.editor.insertText(
        this.state.editor.getSelection()
          ? this.state.editor.getSelection().index
          : this.state.cursorLocation,
        e.char
      );
    }
  };

  /**
   *
   * @returns Character count of text only in the input
   */
  getCharCount = () => {
    if (typeof document === "undefined") return 0;
    const input = document.getElementById("input-" + this.flavor);
    if (!input) return 0;
    return String(input.textContent)
      .split("")
      .filter((c) => {
        const checkWhiteSpace = c.match(/[\s]/);
        if (!checkWhiteSpace) return true;
        else {
          return [" ", "\n"].indexOf(c) > -1;
        }
      }).length;
  };

  getCountStats = () => {
    const length = this.getCharCount();
    return length > this.maxChars ? (
      <span className="text-danger">{length}</span>
    ) : (
      length
    );
  };

  togglePollModal = () => {
    h.hideToolTips();
    if (!this.state.working)
      this.setState((curr) => ({
        ...curr,
        pollModalShown: !this.state.pollModalShown,
      }));
  };

  setPollModal = (option) => {
    h.hideToolTips();
    if (!this.state.working)
      this.setState((curr) => ({
        ...curr,
        pollModalShown: option,
      }));
  };

  toggleLinkModal = () => {
    h.hideToolTips();
    if (!this.state.working)
      this.setState((curr) => ({
        ...curr,
        linkModalShown: !this.state.linkModalShown,
      }));
  };

  setLinkModal = (option) => {
    h.hideToolTips();
    if (!this.state.working)
      this.setState((curr) => ({
        ...curr,
        linkModalShown: option,
      }));
  };

  /**
   *
   * @param {String} link - URL of link
   * @param {String} text - Text of anchor tag
   *
   * Close the link insert popover
   * Insert the link
   */
  insertLink = (link, text) => {
    const insert = () =>
      setTimeout(() => {
        this.state.editor.focus();
        this.state.editor.insertText(
          this.state.editor.getSelection()
            ? this.state.editor.getSelection().index
            : this.state.cursorLocation,
          text,
          "link",
          link
        );
      }, 100);
    if (this.flavor !== "main") {
      this.closePopover();
      insert();
    } else
      this.setState(
        (curr) => ({
          ...curr,
          linkModalShown: false,
        }),
        () => insert()
      );
  };

  /**
   * Triggered when the form is submitted
   * Inserts then immediately removes a back slash
   * Forces the parsers to run
   */
  forceParse = () => {
    try {
      // this.state.editor.insertText(
      //   this.state.editor.getSelection()
      //     ? this.state.editor.getSelection().index
      //     : this.state.cursorLocation,
      //   "\\",
      //   "user"
      // );
      // document.getElementById("input-" + this.flavor).innerHTML = document
      //   .getElementById("input-" + this.flavor)
      //   .innerHTML.split("\\")
      //   .join("");
      // this.state.editor.setContents(
      //   this.state.editor.clipboard.convert({
      //     html: h.sanitizeHTML(this.state.editor.root.innerHTML),
      //   }),
      //   "silent"
      // );
      this.setEditor(
        false,
        document.getElementById("input-" + this.flavor).innerHTML
      );
    } catch (err) {
      console.log("parse error", err);
    }
  };

  /**
   *
   * @param {Object} data - Poll data object
   *
   * Set poll data into state
   * Reset the poll form
   * Hide the poll form
   */
  setPoll = (data) => {
    if (this.flavor === "main") this.togglePollModal();
    this.setState((curr) => ({
      ...curr,
      pollData: data,
      pollFormShown: false,
    }));
  };

  removePoll = () => {
    h.hideToolTips();
    this.setState((curr) => ({
      ...curr,
      pollData: false,
      pollReset: !this.state.pollReset,
      pollFormShown: false,
      pollModalShown: false,
    }));
  };

  /**
   * Fired when the user clicks the Insert Files button
   *
   * Creates a virtual file input
   * Adds a change event that sets the selected file into state
   * Appends to document body (necessary for iDevices and possibly others)
   * Clicks the input
   * Removes the input after the files are selected
   */
  selectFiles = () => {
    if (String(env.UPLOADS_LOCKED) === "true")
      return alert(
        "File uploads are disabled because this free tier instance has reached or exceeded the free tier limit."
      );
    h.hideToolTips();
    if (!this.props.working && !this.state.processingFiles) {
      let input = document.createElement("input");
      input.type = "file";
      input.multiple = true;
      input.style.visibility = "hidden";
      input.style.position = "fixed";
      document.body.appendChild(input);
      input.onchange = (e) => {
        this.setState(
          (curr) => ({
            ...curr,
            processingFiles: true,
          }),
          async () => {
            let files = [];
            const fileArray = [].slice.call(e.target.files);
            for (let i = 0; i < fileArray.length; i++) {
              const file = fileArray[i];
              if (allowedExtensions.indexOf(file.type.toLowerCase()) === -1) {
                alert(
                  `Invalid file format. Allowed: ${allowedExtensions
                    .map((e) => "." + e.split("/")[1].split("+")[0])
                    .join(", ")}`
                );
              } else if (file.size > Number(env.MAX_INDIVIDUAL_FILE_SIZE))
                alert(
                  `Max individual file size exceeded. (Max: ${Math.round(
                    Number(env.MAX_INDIVIDUAL_FILE_SIZE) / (1024 * 1024)
                  )}MB)`
                );
              else {
                const md5 = await h.getMD5(file);
                if (
                  !files.find((file) => file.md5 === md5) &&
                  !this.state.files.find((file) => file.md5 === md5)
                )
                  files.push({
                    name: file.name,
                    file: file,
                    path: URL.createObjectURL(file),
                    md5: md5,
                    size: file.size,
                    type: file.type,
                    thumbnail: file.type.includes("video")
                      ? await h.getVideoThumbnail(file)
                      : false,
                  });
              }
            }
            if (
              this.state.files.length +
                files.filter(
                  (file) => !this.state.files.find((f) => f.md5 === file.md5)
                ).length >
              Number(env.MAX_FILE_COUNT)
            )
              alert(
                `You can only upload ${env.MAX_FILE_COUNT} files at a time`
              );
            this.setState(
              (curr) => ({
                ...curr,
                files: [
                  ...curr.files,
                  ...files.filter(
                    (file) => !this.state.files.find((f) => f.md5 === file.md5)
                  ),
                ].filter((file, f) => f < Number(env.MAX_FILE_COUNT)),
                processingFiles: false,
              }),
              () => document.body.removeChild(input)
            );
          }
        );
      };
      input.click();
    }
  };

  /**
   *
   * @param {String} md5 - md5 hash of the file that the user is hovering over with their cursor
   *
   * Triggered when the user hovers over a file with their cursor
   * Sets into state that file as being hovered (displays the trash can button to remove the file)
   */
  setImageHovered = (md5) => {
    if (!this.props.working)
      this.setState((curr) => ({
        ...curr,
        imageHovered: md5,
      }));
  };

  /**
   *
   * @param {String} md5 - md5 hash of the file to be removed
   *
   * Triggered when the user clicks the trash can in the top right corner of one of the files
   * Removes the file and stops the file from being played if it is playing
   */
  removeFile = (md5) => {
    if (!this.props.working)
      this.setState((curr) => ({
        ...curr,
        files: this.state.files.filter((file) => file.md5 !== md5),
        imageHovered: "",
        mediaPlaying:
          this.state.mediaPlaying === md5 ? "" : this.state.mediaPlaying,
      }));
  };

  /**
   * Triggered when the user clicks the Clear All button when there are files selected
   * Clears all the files
   */
  clearFiles = () => {
    if (!this.props.working)
      this.setState((curr) => ({
        ...curr,
        imageHovered: "",
        files: [],
        mediaPlaying: "",
      }));
  };

  /**
   *
   * @param {File} file - Javascript file object
   * @returns A file thumbnail/preview depending on the type of file that it is
   */
  getFileThumbnail = (file) => {
    switch (file.type.split("/")[0]) {
      case "image":
        return (
          <div
            className="fit-images"
            style={{ backgroundImage: `url("${file.path}")` }}
          ></div>
        );
      case "video":
        return (
          <MDBRipple
            onClick={() => this.selectMedia(file.md5)}
            className="h-100 w-100 d-flex justify-content-center align-items-center position-relative cursor-pointer"
          >
            <video
              className="position-absolute max-w-100 max-h-100"
              src={file.path}
              id={file.md5}
            ></video>
            {this.state.mediaPlaying === file.md5 ? (
              <>
                {this.state.imageHovered === file.md5 ? (
                  <motion.i
                    transition={t.transition}
                    exit={t.fade_out_scale_1}
                    animate={t.normalize}
                    initial={t.fade_out}
                    style={{ color: "rgba(255, 255, 255, 0.5)", zIndex: 30 }}
                    className="fas fa-pause fa-5x"
                  ></motion.i>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <i
                style={{ color: "rgba(255, 255, 255, 0.5)", zIndex: 30 }}
                className="fas fa-play fa-5x"
              ></i>
            )}
          </MDBRipple>
        );
      case "audio":
        return (
          <>
            <audio src={file.path} id={file.md5}></audio>
            <MDBRipple
              onClick={() => this.selectMedia(file.md5)}
              className="h-100 d-flex w-100 justify-content-center align-items-center cursor-pointer"
            >
              {this.state.mediaPlaying === file.md5 ? (
                <i className="fas fa-pause fa-5x"></i>
              ) : (
                <i className="fas fa-play fa-5x"></i>
              )}
            </MDBRipple>
          </>
        );
    }
  };

  /**
   *
   * @param {String} md5 - md5 hash of the file selected
   *
   * Triggered when the user clicks an audio or video file
   * Stop the file that is currently playing, if any
   * Play the file
   */
  selectMedia = (md5) => {
    if (this.state.mediaPlaying)
      document.getElementById(this.state.mediaPlaying)?.pause();
    this.setState(
      (curr) => ({
        ...curr,
        mediaPlaying: this.state.mediaPlaying === md5 ? "" : md5,
      }),
      () => {
        if (this.state.mediaPlaying)
          document.getElementById(this.state.mediaPlaying)?.play();
      }
    );
  };

  submit = (enterPressed) => {
    switch (this.flavor) {
      case "reply":
      case "boost":
        this.props.submit(this.state.files);
        break;

      case "reply-file-modal":
        this.props.submit(enterPressed);
        break;
      case "global":
        this.setState(
          (curr) => ({
            ...curr,
            pollFormShown: false,
          }),
          () => {
            this.forceParse();
            this.props.submit(this.state.files, this.state.pollData);
          }
        );
        break;
      case "main":
        if (!this.state.working)
          this.setState(
            (curr) => ({
              ...curr,
              working: true,
            }),
            async () => {
              try {
                this.forceParse();
                if (
                  this.state.files.reduce((prev, curr) => prev + curr.size, 0) >
                  Number(env.MAX_TOTAL_FILE_SIZE)
                )
                  throw `Max total file size exceeded. (Max: ${Math.round(
                    Number(env.MAX_TOTAL_FILE_SIZE) / (1024 * 1024)
                  )}MB)`;
                try {
                  this.state.editor.insertText(
                    this.state.editor.getSelection()
                      ? this.state.editor.getSelection().index
                      : this.state.cursorLocation,
                    "\\",
                    "user"
                  );
                  document.getElementById("input-" + this.flavor).innerHTML =
                    document
                      .getElementById("input-" + this.flavor)
                      .innerHTML.split("\\")
                      .join("");
                } catch (err) {
                  console.log("parse error", err);
                }

                const emissionData = document.getElementById(
                  "input-" + this.flavor
                );
                const length = String(emissionData.textContent)
                  .split("")
                  .filter((c) => {
                    const checkWhiteSpace = c.match(/[\s]/);
                    if (!checkWhiteSpace) return true;
                    else {
                      return [" ", "\n"].indexOf(c) > -1;
                    }
                  }).length;
                if (
                  (!emissionData.textContent ||
                    emissionData.innerHTML === "<div><p><br /></p></div>") &&
                  !this.state.files.length &&
                  !this.state.pollData
                )
                  throw "Please enter text, at least one file, or a poll";
                if (length > this.maxChars)
                  throw `Character limit exceeded (Max: ${this.maxChars} characters)`;
                const fd = new FormData();
                const captchaKey = await this.getRecaptcha();
                fd.append("captchaKey", captchaKey);
                if (this.state.pollData)
                  fd.append("pollData", JSON.stringify(this.state.pollData));
                this.state.files.forEach((file) => {
                  fd.append("files", file.file, file.name);
                  if (file.file.type.includes("video"))
                    fd.append("thumbnails", file.thumbnail, file.name);
                });
                const files = this.state.files.length;
                fd.append("emission", h.sanitizeHTML(emissionData.innerHTML));
                axios
                  .post(process.env.REACT_APP_LAMBDA_EMISSIONS, fd, {
                    headers: {
                      Authorization: this.props.token,
                    },
                  })
                  .then((res) => {
                    this.props.set_token(res.data.token);
                    this.props.newEmission(
                      h.setMetadata(res.data.emission, this.props.userInfo),
                      files
                    );
                  })
                  .catch((err) =>
                    this.setState(
                      (curr) => ({
                        ...curr,
                        working: false,
                      }),
                      () => {
                        console.log(err);
                        alert("An error occurred. Please try again later.");
                      }
                    )
                  );
              } catch (err) {
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                  }),
                  () => alert(err)
                );
              }
            }
          );
        break;
      default:
        console.log("oob flavor", this.flavor);
    }
  };

  press = (e) => {
    if (!this.props.working && !this.props.sending && !this.state.working) {
      if (e.key === "Shift" && !this.state.holdingShift)
        this.setState((curr) => ({
          ...curr,
          holdingShift: true,
        }));
      else if (
        !this.state.holdingShift &&
        ["message", "new-message", "reply-file-modal"].indexOf(this.flavor) >
          -1 &&
        e.key === "Enter" &&
        Array.from(
          document.getElementById("input-" + this.flavor)?.firstChild
            ?.childNodes || []
        ).length <= 2
      ) {
        const emissionData = document.getElementById("input-" + this.flavor);
        const length = String(emissionData.textContent)
          .split("")
          .filter((c) => {
            const checkWhiteSpace = c.match(/[\s]/);
            if (!checkWhiteSpace) return true;
            else {
              return [" ", "\n"].indexOf(c) > -1;
            }
          }).length;
        if (!length || emissionData.innerHTML === "<div><p><br /></p></div>")
          return;
        document.getElementById("input-" + this.flavor).innerHTML = document
          .getElementById("input-" + this.flavor)
          .innerHTML.split("<p><br></p>")
          .join("");
        if (!this.props.cooldown) {
          if (this.flavor === "reply-file-modal") this.submit(true);
          else this.props.submit(true);
        } else {
          this.moveToEnd();
        }
      }
    }
  };

  up = (e) => {
    if (e.key === "Shift" && this.state.holdingShift)
      this.setState((curr) => ({
        ...curr,
        holdingShift: false,
      }));
  };

  render() {
    return (
      <div
        className={`position-relative ${
          ["message", "new-message"].indexOf(this.flavor) > -1 ? "pt-2" : ""
        }`}
      >
        {this.flavor === "main" && (
          <>
            <PollModal
              pollReset={this.state.pollReset}
              setPoll={this.setPoll}
              modalShown={this.state.pollModalShown}
              setShowModal={this.setPollModal}
              toggleShowModal={this.togglePollModal}
            />
            <LinkModal
              insertLink={this.insertLink}
              modalShown={this.state.linkModalShown}
              setShowModal={this.setLinkModal}
              toggleShowModal={this.toggleLinkModal}
            />
          </>
        )}
        <label
          className={`position-absolute quill-labels${
            this.flavor === "reply-file-modal" ? "-dark" : ""
          }`}
          htmlFor={"input-" + this.flavor}
          id={"label-" + this.flavor}
          style={{
            transition: "0.33s",
            top: "1rem",
            left: "1rem",
            cursor: "text",
          }}
          onClick={() => this.state.editor.focus()}
        >
          {this.props.label}
        </label>
        <div
          style={{
            transition: "0.33s",
            border: "1px solid rgba(18, 102, 241, 0)",
          }}
          className="rounded"
          id={"wrapper-" + this.flavor}
        >
          <div
            id={"input-" + this.flavor}
            className="rounded quill-inputs"
            style={{
              minHeight: "4.5rem",
              transition: "border-width 0.33s ease-in-out",
              whiteSpace: "pre-wrap",
            }}
            onFocus={this.focus}
            onBlur={this.blur}
            key={"input-" + this.flavor + this.props.reset}
            onKeyDown={this.press}
            onKeyUp={this.up}
          ></div>
        </div>
        <div
          id={"text-input-buttons-" + this.flavor}
          className={`d-flex justify-content-end align-items-start mt-3 ${
            this.flavor === "global" ? "emission-modal-buttons" : ""
          }`}
        >
          {[
            "bio-main",
            "bio-modal-generic",
            "bio-modal-emission-input",
            "bio-modal-profile",
            "bio-edit-self",
            "bio-edit-other",
          ].indexOf(this.flavor) > -1 && (
            <div className="flex-grow-1 align-self-start">
              <h6
                className={`m-0 ${
                  this.getCharCount() > this.maxChars ? "text-danger" : ""
                }`}
              >
                {this.maxChars - this.getCharCount()}
              </h6>
            </div>
          )}
          {["message"].indexOf(this.flavor) > -1 && this.props.clearMessage && (
            <div className="flex-grow-1">
              <MDBBtn
                color="dark"
                className="message-list-buttons"
                onClick={this.props.clearMessage}
              >
                <i className="fas fa-list me-2"></i>
                Messages
              </MDBBtn>
            </div>
          )}
          {["main"].indexOf(this.flavor) > -1 &&
            String(env.STREAMING_ENABLED) === "true" &&
            !this.props.userInfo.private && (
              <div id="go-live-button-desktop" className="flex-grow-1">
                <MDBBtn
                  onClick={this.props.toggle_go_live_modal}
                  size="lg"
                  className="bg-darkblu"
                >
                  Go Live
                  <i
                    id={"broadcast-tower-icon-" + this.flavor}
                    className="fas fa-broadcast-tower ms-2"
                  ></i>
                </MDBBtn>
              </div>
            )}

          <div
            className={
              "text-input-tooltips-send text-input-tooltips-send-" + this.flavor
            }
          >
            <div
              className={
                "text-input-tooltips text-input-tooltips-" + this.flavor
              }
            >
              {["global", "main", "reply", "boost"].indexOf(this.flavor) >
                -1 && (
                <MDBTooltip
                  wrapperProps={{
                    className: "emission-file-select",
                    id: "emission-file-select-" + this.flavor,
                    color: "link",
                    rippleColor: "primary",
                    onClick: this.selectFiles,
                    disabled:
                      this.props.working ||
                      this.state.working ||
                      this.props.sending,
                  }}
                  title="Media"
                >
                  <i
                    style={{ fontSize: "1.75em" }}
                    className="fas fa-photo-video fa-lg"
                  ></i>
                </MDBTooltip>
              )}
              {["global", "main"].indexOf(this.flavor) > -1 && (
                <MDBTooltip
                  options={{ trigger: "hover" }}
                  wrapperProps={{
                    color: "link",
                    rippleColor: "primary",
                    className: "mx-2",
                    onClick:
                      this.flavor === "main"
                        ? this.togglePollModal
                        : this.togglePollForm,
                    disabled:
                      this.props.working ||
                      this.state.working ||
                      this.props.sending,
                  }}
                  title="Poll"
                >
                  <i
                    style={{ fontSize: "1.75em" }}
                    className="fas fa-poll fa-lg"
                  ></i>
                </MDBTooltip>
              )}
              {this.flavor === "main" ? (
                <MDBTooltip
                  wrapperProps={{
                    color: "link",
                    rippleColor: "primary",
                    onClick: this.toggleLinkModal,
                    disabled: this.state.working || this.props.working,
                  }}
                  title="Link"
                >
                  <i
                    style={{ fontSize: "1.75em" }}
                    className="fas fa-link fa-lg"
                  ></i>
                </MDBTooltip>
              ) : (
                <MDBTooltip
                  tag="span"
                  wrapperProps={{
                    color: "link",
                    rippleColor: "primary",
                    disabled:
                      this.props.working ||
                      this.state.working ||
                      this.props.sending,
                  }}
                  title="Link"
                >
                  <MDBPopover
                    color="link"
                    placement="bottom"
                    btnChildren={
                      <i
                        style={{ fontSize: "1.75em" }}
                        className="fas fa-link fa-lg page-popover-triggers-timeout"
                      ></i>
                    }
                    rippleColor="primary"
                    disabled={
                      this.props.working ||
                      this.state.working ||
                      this.props.sending
                    }
                    onOpen={this.showPopover}
                    open={this.state.popoverOpen}
                    btnClassName="page-popover-triggers-timeout"
                    type="button"
                  >
                    <MDBPopoverBody className="page-popover-triggers">
                      <LinkInsertForm insertLink={this.insertLink} />
                    </MDBPopoverBody>
                  </MDBPopover>
                </MDBTooltip>
              )}
              <MDBTooltip
                className="mb-2"
                tag="span"
                wrapperProps={{
                  className: "emoji-triggers-" + this.flavor,
                  disabled:
                    this.props.working ||
                    this.state.working ||
                    this.props.sending,
                  onClick: h.hideToolTips,
                }}
                title="Emoji"
              >
                <EmojiPicker
                  emojiID={"emoji-" + this.flavor}
                  className="mx-2"
                  trigger={
                    <i
                      style={{ fontSize: "1.75em" }}
                      className={`fas fa-smile fa-lg emoji-triggers-emoji-${this.flavor}-timeout`}
                    ></i>
                  }
                  onEmojiSelect={this.selectEmoji}
                  disabled={
                    this.props.working ||
                    this.state.working ||
                    this.props.sending
                  }
                />
              </MDBTooltip>
            </div>
            {["global", "main", "reply", "boost", "reply-file-modal"].indexOf(
              this.flavor
            ) > -1 && (
              <div
                id={"send-live-buttons-mobile-" + this.flavor}
                className="d-flex justify-content-between align-items-center"
              >
                {["main"].indexOf(this.flavor) > -1 &&
                  String(env.STREAMING_ENABLED) === "true" &&
                  !this.props.userInfo.private && (
                    <MDBBtn
                      onClick={this.props.toggle_go_live_modal}
                      className="bg-darkblu go-live-buttons-mobile"
                      id={"go-live-button-mobile-" + this.props.flavor}
                    >
                      Go Live
                      <i className="fas fa-broadcast-tower ms-2"></i>
                    </MDBBtn>
                  )}

                <hr
                  className="d-none"
                  id={"live-send-buttons-divider-" + this.flavor}
                />
                <MDBBtn
                  id={"send-button-" + this.flavor}
                  disabled={
                    this.props.working ||
                    this.state.working ||
                    this.props.sending
                  }
                  color="success"
                  onClick={this.submit}
                >
                  {this.props.working ||
                  this.state.working ||
                  this.props.sending ? (
                    <>
                      <Spinner className="me-2" size="sm" />
                      Sending
                    </>
                  ) : (
                    <>
                      <i className="fas fa-paper-plane me-2"></i>
                      Send
                    </>
                  )}
                </MDBBtn>
              </div>
            )}
          </div>

          {["message", "new-message"].indexOf(this.flavor) > -1 && (
            <div className="d-flex justify-content-between align-items-center messages-send">
              {this.props.clearMessage && (
                <MDBBtn
                  color="dark"
                  className="message-list-buttons-mobile"
                  onClick={this.props.clearMessage}
                >
                  <i className="fas fa-list me-2"></i>
                  Messages
                </MDBBtn>
              )}
              {this.props.cooldown ? (
                <MDBBtn disabled color="success">
                  {this.getButtonText(this.props.cooldown)}
                </MDBBtn>
              ) : (
                <MDBBtn
                  id="send-button-message"
                  onClick={this.props.submit}
                  color="success"
                  disabled={
                    this.props.working ||
                    this.props.sending ||
                    this.props.sending
                  }
                >
                  <motion.div
                    transition={t.transition}
                    exit={t.fade_out_minimize}
                    animate={t.normalize}
                    initial={t.fade_out_minimize}
                    className="d-flex align-items-center"
                  >
                    {this.props.working ||
                    this.props.sending ||
                    this.props.sending ? (
                      <>
                        {/* <Spinner className="me-2" size="sm" />
                        Sending */}
                        <MDBSpinner size="sm" className="me-2" />
                        Encrypting
                      </>
                    ) : (
                      <>
                        <i className="fas fa-paper-plane me-2"></i>
                        Send
                      </>
                    )}
                  </motion.div>
                </MDBBtn>
              )}
            </div>
          )}
        </div>
        {["global", "main", "reply", "boost"].indexOf(this.flavor) > -1 && (
          <>
            <h5 className={"text-end mt-2 mb-4 count-stats-" + this.flavor}>
              {this.getCountStats()}/{this.maxChars}
            </h5>
            <small className="my-2 d-block mx-auto text-center">
              This site is protected by reCAPTCHA and the Google{" "}
              <a href="https://policies.google.com/privacy">Privacy Policy</a>{" "}
              and{" "}
              <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
              apply.
            </small>
          </>
        )}
        {["message", "new-message"].indexOf(this.flavor) > -1 && (
          <h5 className="text-end mt-2 mb-0 count-message">
            {this.getCountStats()}/{this.maxChars}
          </h5>
        )}
        {["global"].indexOf(this.flavor) > -1 && (
          <>
            <Collapse in={this.state.pollFormShown} id="collapse-poll-main">
              <PollForm
                pollFormShown={this.state.pollFormShown}
                pollReset={this.state.pollReset}
                setPoll={this.setPoll}
              />
            </Collapse>
          </>
        )}
        {this.state.pollData && (
          <motion.div
            transition={t.transition}
            initial={t.fade_out_minimize}
            animate={t.normalize}
            exit={t.fade_out_minimize}
          >
            <hr></hr>
            <MDBCard className="mb-4">
              <MDBCardBody>
                <div className="d-flex justify-content-between">
                  <h5>
                    <i className="fas fa-poll me-2 text-secondary"></i>
                    {this.state.pollData.question}
                  </h5>
                  <div className="d-flex align-items-center">
                    <MDBTooltip
                      wrapperProps={{
                        className: "me-2",
                        color: "link",
                        rippleColor: "primary",
                        onClick: () => {
                          h.hideToolTips();
                          this.setState((curr) => ({
                            ...curr,
                            [this.flavor === "global"
                              ? "pollFormShown"
                              : "pollModalShown"]: true,
                          }));
                        },
                      }}
                      title="Edit Poll"
                    >
                      <i className="fas fa-edit fa-lg" />
                    </MDBTooltip>
                    <MDBTooltip
                      wrapperProps={{
                        className: "me-2 text-danger",
                        color: "link",
                        rippleColor: "danger",
                        onClick: this.removePoll,
                      }}
                      title="Delete Poll"
                    >
                      <i className="far fa-trash-alt fa-lg" />
                    </MDBTooltip>
                  </div>
                </div>

                <div className="mb-2">
                  <p className="m-0">
                    Votes Allowed: {this.state.pollData.votesAllowed}
                  </p>
                  {this.state.pollData.expirationInfo ? (
                    <p className="text-blusteel m-0">
                      Expires in{" "}
                      {this.state.pollData.expirationInfo.expiryLength}{" "}
                      {this.state.pollData.expirationInfo.expiryUnits}
                      {this.state.pollData.expirationInfo.expiryLength === 1
                        ? ""
                        : "s"}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <MDBListGroup flush>
                  {this.state.pollData.options.map((option, o) => (
                    <MDBListGroupItem>{option}</MDBListGroupItem>
                  ))}
                </MDBListGroup>
              </MDBCardBody>
            </MDBCard>
          </motion.div>
        )}
        {(this.state.files.length || this.state.processingFiles) && (
          <>
            <hr></hr>
            <>
              {this.state.processingFiles ? (
                <MDBContainer className="mb-2">
                  <div className="d-flex justify-content-center">
                    <LogoLoader />
                  </div>
                </MDBContainer>
              ) : (
                <>
                  <MDBBtn
                    onClick={this.clearFiles}
                    color="link"
                    size="lg"
                    rippleColor="danger"
                    className="text-danger"
                  >
                    <i className="fas fa-times me-2"></i>
                    Clear Files
                  </MDBBtn>
                  <MDBContainer fluid>
                    <div className="row">
                      {this.state.files.map((file, f) => (
                        <div
                          id={`file-${f}`}
                          key={file.md5}
                          className={
                            this.flavor === "main"
                              ? "col-12 col-md-6 col-lg-4 col-xl-3 my-2"
                              : "col-12 col-sm-6 col-md-4 col-xl-3 my-2"
                          }
                        >
                          <div
                            onMouseEnter={() => this.setImageHovered(file.md5)}
                            onMouseLeave={() => this.setImageHovered("")}
                            style={{ border: "1px solid #607D8B" }}
                            className={`mx-auto p-2 d-flex justify-content-center align-items-center square-15 position-relative ${
                              this.state.imageHovered === file.md5
                                ? "image-hover"
                                : ""
                            }`}
                          >
                            {this.getFileThumbnail(file)}
                            <motion.div
                              transition={t.transition}
                              exit={t.fade_out_scale_1}
                              animate={t.normalize}
                              initial={t.fade_out}
                              className="file-labels-dark position-absolute top-0 m-0 w-100"
                            >
                              <p
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  width: "80%",
                                }}
                                className="ms-1 my-1 text-nowrap text-light"
                              >
                                {file.name}
                              </p>
                            </motion.div>
                            {this.state.imageHovered === file.md5 ? (
                              <motion.div
                                transition={t.transition}
                                exit={t.fade_out_scale_1}
                                animate={t.normalize}
                                initial={t.fade_out}
                                className="position-absolute top-0 end-0 m-0 w-100"
                              >
                                <MDBBtn
                                  onClick={(e) => this.removeFile(file.md5, e)}
                                  className="text-danger p-2 ms-auto d-block"
                                  color="link"
                                  style={{ zIndex: 20 }}
                                  size="lg"
                                >
                                  <i className="far fa-trash-alt fa-lg" />
                                </MDBBtn>
                              </motion.div>
                            ) : (
                              <></>
                            )}
                            <motion.p
                              transition={t.transition}
                              exit={t.fade_out_scale_1}
                              animate={t.normalize}
                              initial={t.fade_out}
                              style={{ textOverflow: "ellipsis" }}
                              className="file-labels-dark position-absolute bottom-0 m-0 w-100 text-center text-light"
                            >
                              {h.getFileSize(file.size)}
                            </motion.p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </MDBContainer>
                </>
              )}
            </>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
  captchaReady: state.captchaReady,
  profileInfo: state.profileInfo,
  token: state.token,
  clickPass: state.clickPass,
});

export default connect(mapStateToProps, {
  toggle_go_live_modal,
  new_emission,
  set_token,
  set_click_pass,
})(RichTextInput);
