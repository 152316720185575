import env from "../../../../env";
import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBRipple,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import Spinner from "../../../../components/Spinner";
import {
  change_user_details,
  notify,
  set_token,
} from "../../../../redux/actions";
import axios from "axios";
import { Collapse } from "@mui/material";
import RemoveFile from "../../../../components/RemoveFile";
import h from "../../../../utilities/helpers";

const allowedExtensions = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/bmp",
  "image/webp",
  "image/svg+xml",
];

class ChangeBackgroundModal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * background: String, Path to the user's background
       * backgroundName: String, "Click to Change", or the name of the file the user has selected, if any
       * backgroundFile: false | File object that contains an background file that the user selected
       * working: Boolean - Whether a new background is in the process of being uploaded to and processed on the server
       * newFileSelected: Boolean - Whether the current file selected is different from the user's current background
       */
      background: `${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/images/${props.userInfo.background.main}`,
      backgroundName: "Click to Change",
      backgroundFile: "",
      working: false,
      newFileSelected: false,
      removeConfirmOpen: false,
      removing: false,
    };
  }

  /**
   * If user's background changes, update the default values
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.userInfo.background.main !== this.props.userInfo.background.main
    )
      this.setState((curr) => ({
        ...curr,
        background: `${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/images/${this.props.userInfo.background.main}`,
        backgroundName: "Click to Change",
        backgroundFile: "",
      }));
  }

  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   * Fired when the user clicks their background
   *
   * Creates a virtual file input
   * Adds a change event that sets the selected file into state
   * Appends to document body (necessary for iDevices and possibly others)
   * Clicks the input
   * Removes the input after the file is selected
   */
  selectFile = () => {
    if (String(env.UPLOADS_LOCKED) === "true")
      return alert(
        "File uploads are disabled because this free tier instance has reached or exceeded the free tier limit."
      );
    let input = document.createElement("input");
    input.type = "file";
    input.style.visibility = "hidden";
    input.style.position = "fixed";
    document.body.appendChild(input);
    input.onchange = (e) => {
      let file = e.target.files[0];
      if (allowedExtensions.indexOf(file.type) !== -1) {
        if (file.size > Number(env.MAX_INDIVIDUAL_FILE_SIZE))
          alert(
            `Max individual file size exceeded. (Max: ${Math.round(
              Number(env.MAX_INDIVIDUAL_FILE_SIZE) / (1024 * 1024)
            )}MB)`
          );
        else
          this.setState(
            (curr) => ({
              ...curr,
              backgroundName: e.target.files[0].name,
              backgroundFile: e.target.files[0],
              background: URL.createObjectURL(e.target.files[0]),
              newFileSelected: true,
            }),
            () => document.body.removeChild(input)
          );
      } else {
        document.body.removeChild(input);
        alert("Please select a valid image file (png, jpg, gif, bmp, webp)");
      }
    };
    input.click();
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Make request to server
   * Update user details with new background
   * Hide modal
   */
  submit = () => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        async () => {
          try {
            const captchaKey = await this.getRecaptcha();
            const fd = new FormData();
            fd.append(
              "background",
              this.state.backgroundFile,
              this.state.backgroundName
            );
            fd.append("captchaKey", captchaKey);
            axios
              .post(
                process.env.REACT_APP_LAMBDA_DASHBOARD + "/change-background",
                fd,
                {
                  headers: {
                    Authorization: this.props.token,
                  },
                }
              )
              .then((res) => {
                this.props.toggleShowModal();
                this.props.set_token(res.data.token);
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                  }),
                  () => {
                    this.props.change_user_details(res.data.userInfo);
                    this.props.notify(
                      <i className="fas fa-image text-success" />,
                      "Background Updated"
                    );
                  }
                );
              })
              .catch((err) =>
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                  }),
                  () => {
                    console.log(err);
                    if (err.response?.status === 403)
                      return alert(
                        "Human verification failed. Refresh the page and try again"
                      );
                    alert("An error occurred. Please try again later");
                  }
                )
              );
          } catch (err) {
            console.log(err);
            alert("An error occurred. Please try again later.");
          }
        }
      );
  };

  toggleOpenRemoveConfirm = () =>
    this.setState((curr) => ({
      ...curr,
      removeConfirmOpen: !this.state.removeConfirmOpen,
    }));

  closeRemoveConfirm = () =>
    this.setState((curr) => ({
      ...curr,
      removeConfirmOpen: false,
    }));

  remove = () =>
    this.setState(
      (curr) => ({
        ...curr,
        removeConfirmOpen: false,
        removing: true,
      }),
      () =>
        axios
          .post(
            process.env.REACT_APP_LAMBDA_DASHBOARD + "/remove-own",
            { file: "background" },
            {
              headers: {
                Authorization: this.props.token,
              },
            }
          )
          .then((res) => {
            this.props.toggleShowModal();
            this.props.set_token(res.data.token);
            this.setState(
              (curr) => ({
                ...curr,
                removing: false,
              }),
              () => {
                this.props.change_user_details({
                  ...this.props.userInfo,
                  background: {
                    main: "default-background.webp",
                    thumbnail: "default-background.webp",
                  },
                });
                this.props.notify(
                  <i className="far fa-times-circle text-danger" />,
                  "Background Removed"
                );
              }
            );
          })
          .catch((err) =>
            this.setState(
              (curr) => ({
                ...curr,
                remove: false,
              }),
              () => {
                console.log(err);
                if (err.response?.status === 403)
                  return alert(
                    "Human verification failed. Refresh the page and try again"
                  );
                alert("An error occurred. Please try again later");
              }
            )
          )
    );

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            id="change-background-modal"
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={() => {
              if (!this.state.working) this.props.toggleShowModal();
            }}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Change Background</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="d-flex justify-content-center">
                    <MDBRipple
                      onClick={this.selectFile}
                      className="mx-auto max-w-100 cursor-pointer"
                      rippleColor="light"
                    >
                      <p className="file-labels-dark position-absolute bottom-0 text-center w-100 mb-0 text-light cursor-pointer">
                        {this.state.backgroundName}
                      </p>
                      <img
                        style={{ maxHeight: "50vh", minWidth: "10rem" }}
                        src={this.state.background}
                        className="max-w-100 d-block mx-auto cursor-pointer"
                      />
                    </MDBRipple>
                  </div>
                </MDBModalBody>
                <MDBModalFooter className="justify-content-between align-items-center">
                  <div className="d-flex justify-content-center align-items-center">
                    {this.props.userInfo.background.main !==
                      "default-background.webp" && (
                      <>
                        <div className="d-flex flex-column justify-content-start">
                          <MDBBtn
                            disabled={this.state.removing || this.state.working}
                            color="danger"
                            onClick={this.toggleOpenRemoveConfirm}
                          >
                            {this.state.removing ? (
                              <>
                                <Spinner size="sm" className="me-2" />
                                Removing
                              </>
                            ) : (
                              <>
                                {this.state.removeConfirmOpen ? (
                                  "Are You Sure?"
                                ) : (
                                  <>
                                    <i className="far fa-trash-alt me-2" />
                                    Remove Background
                                  </>
                                )}
                              </>
                            )}
                          </MDBBtn>
                          <Collapse in={this.state.removeConfirmOpen}>
                            <MDBBtn
                              onClick={this.closeRemoveConfirm}
                              color="link"
                              rippleColor="primary"
                              className="d-block"
                              disabled={
                                this.state.removing || this.state.working
                              }
                            >
                              No
                            </MDBBtn>
                            <MDBBtn
                              onClick={this.remove}
                              color="link"
                              rippleColor="primary"
                              className="d-block"
                              disabled={
                                this.state.removing || this.state.working
                              }
                            >
                              Yes
                            </MDBBtn>
                          </Collapse>
                        </div>
                        {h.checkChadmin(this.props.userInfo) &&
                          this.props.userInfo.background.main !==
                            "removed.png" && (
                            <RemoveFile
                              file={`${env.INSTANCE_ID}/images/${this.props.userInfo.background.main}`}
                              className="ms-2"
                              callback={this.props.toggleShowModal}
                            />
                          )}
                      </>
                    )}
                  </div>
                  <div className="d-flex justify-content-end align-items-center">
                    {this.state.newFileSelected ? (
                      <>
                        {this.state.working ? (
                          <MDBBtn className="me-2" disabled color="primary">
                            <Spinner size="sm" className="me-2" />
                            Saving
                          </MDBBtn>
                        ) : (
                          <MDBBtn
                            className="me-2"
                            onClick={this.submit}
                            color="primary"
                          >
                            <i className="fas fa-save me-2"></i>Save
                          </MDBBtn>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <MDBBtn
                      className="bg-gray"
                      color="dark"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </div>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  change_user_details,
  notify,
  set_token,
})(ChangeBackgroundModal);
