import env from "../../env";
import React from "react";
import { connect } from "react-redux";
import { route, clear_temp_action, set_temp_action } from "../../redux/actions";
import { MDBTooltip, MDBToast, MDBBtn } from "mdb-react-ui-kit";
import LoginModal from "../loginModal/LoginModal";
import GlobalEmissionInputModal from "./GlobalEmissionInputModal";

class GlobalEmissionInput extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether an emission is in the process of being submitted
       * emissionModalShown: Boolean - Whether the emission input modal is shown
       * loginModalShown: Boolean - Whether the login modal is shown
       * notificationIcon: JSX - The notification icon
       * notificationText: String - String of the notification
       */
      working: false,
      emissionModalShown: false,
      loginModalShown: false,
      notificationIcon: <></>,
      notificationText: "",
      testOpen: false,
    };
  }

  /**
   * If user logs in using the login modal:
   * * Hide login modal
   * * Notify user that they have logged in
   * * Open Emission modal
   * * Clear temp action
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.tempAction?.label !== `make a new ${env.EMISSION_NAME}` &&
      this.props.tempAction?.label === `make a new ${env.EMISSION_NAME}` &&
      !this.state.loginModalShown
    )
      this.toggleLoginModal();
    if (
      this.state.loginModalShown &&
      !prevProps.userInfo._id &&
      this.props.userInfo._id
    ) {
      this.toggleEmissionModal();
      this.setState((curr) => ({
        ...curr,
        notificationIcon: (
          <i className="fas fa-check-circle text-success me-2" />
        ),
        notificationText: `Logged in as @${this.props.userInfo.username}`,
        loginModalShown: false,
      }));
    }

    if (prevState.loginModalShown && !this.state.loginModalShown)
      this.props.clear_temp_action();
  }

  setLoginModal = (option) =>
    this.setState((curr) => ({
      ...curr,
      loginModalShown: option,
    }));

  toggleLoginModal = () =>
    this.setState((curr) => ({
      ...curr,
      loginModalShown: !this.state.loginModalShown,
    }));

  setEmissionModal = (option) =>
    this.setState((curr) => ({
      ...curr,
      emissionModalShown: option,
    }));

  toggleEmissionModal = () => {
    this.setState(
      (curr) => ({
        ...curr,
        emissionModalShown: !this.state.emissionModalShown,
      }),
      () => this.props.clear_temp_action()
    );
  };

  render() {
    return (
      <>
        <MDBTooltip
          wrapperProps={{
            size: "lg",
            floating: true,
            className: "global-input-button position-fixed p-2",
            style: {
              bottom: "1rem",
              right: "1rem",
              width: "4rem",
              height: "4rem",
            },
            onClick: () => {
              if (this.props.userInfo._id) this.toggleEmissionModal();
              else
                this.props.set_temp_action({
                  label: `make a new ${env.EMISSION_NAME}`,
                });
            },
          }}
          title={`New ${env.EMISSION_NAME}`}
          placement="left"
        >
          <div
            style={{
              backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/icons/newjizz.png")`,
            }}
            className="fit-images"
          ></div>
        </MDBTooltip>

        <LoginModal
          modalShown={this.state.loginModalShown}
          toggleShowModal={this.toggleLoginModal}
          setShowModal={this.setLoginModal}
          tempAction={this.props.tempAction}
          flavor="emission-input"
          skipHide={true}
        />
        <GlobalEmissionInputModal
          modalShown={this.state.emissionModalShown}
          toggleShowModal={this.toggleEmissionModal}
          setShowModal={this.setEmissionModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  route,
  clear_temp_action,
  set_temp_action,
})(GlobalEmissionInput);
