import env from "../../env";
import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBRipple,
  MDBTooltip,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBContainer,
} from "mdb-react-ui-kit";
import { AnimatePresence } from "framer-motion";
import { StaticRouter, Switch, Route, Link } from "react-router-dom";
import File from "./File";
import t from "../../utilities/transitions";
import { connect } from "react-redux";
import { route, set_token, notify } from "../../redux/actions";
import h from "../../utilities/helpers";
import { motion } from "framer-motion";
import axios from "axios";
import Spinner from "../Spinner";
import Url from "url-parse";
import SampleEmission from "../emission/SampleEmission";
import TextInput from "../textInput/TextInput";
import { accountDeletedEmission } from "../../utilities/constants";
import Count from "../Count";

const maxChars = Number(env.MAX_EMISSION_CHARS);

class FileModal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * enter: Object - framer-motion entrance animation
       * exit: Object - framer-motion exit animation
       * emissionCopied: Boolean - Whether the emission direct link has been copied
       * actionFormOpened: Boolean - Whether the remove/report form has been opened
       */
      enter: t.fade_out,
      exit: t.fade_out,
      emissionCopied: false,
      actionFormOpened: false,
      loading: false,
      replies: props.replies || [],
      totalReplies: 0,
      reset: false,
      working: false,
      refListenersAdded: false,
      contentKey: false,
      socketEvents: [],
    };
    this.scrollRef = React.createRef();
    this.scrollRefMobile = React.createRef();
  }

  componentDidMount() {
    if (this.scrollRef?.current && this.scrollRefMobile?.current) {
      this.setState(
        (curr) => ({
          ...curr,
          refListenersAdded: true,
        }),
        () => {
          this.scrollRef.current.addEventListener("scroll", this.scroll);
          this.scrollRefMobile.current.addEventListener("scroll", this.scroll);
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.state.refListenersAdded &&
      this.scrollRef?.current &&
      this.scrollRefMobile?.current
    ) {
      this.setState(
        (curr) => ({
          ...curr,
          refListenersAdded: true,
        }),
        () => {
          this.scrollRef.current.addEventListener("scroll", this.scroll);
          this.scrollRefMobile.current.addEventListener("scroll", this.scroll);
        }
      );
    }

    if (!prevProps.modalShown && this.props.modalShown) {
      window.addEventListener("keydown", this.arrowNav);
    }

    if (prevProps.modalShown && !this.props.modalShown) {
      window.removeEventListener("keydown", this.arrowNav);
    }

    if (
      this.props.emission &&
      prevProps.emission?._id !== this.props.emission._id
    ) {
      this.loadReplies(true);
      if (this.props.socket) this.setSocket();
    }
  }

  componentWillUnmount() {
    if (this.scrollRef?.current && this.scrollRefMobile?.current) {
      this.scrollRef.current.removeEventListener("scroll", this.scroll);
      this.scrollRefMobile.current.removeEventListener("scroll", this.scroll);
    }
    if (this.props.socket) this.props.socket.offAny();
  }

  setSocket = () => {
    if (!this.props.socket) return;
    this.props.socket.offAny();
    this.props.socket.onAny(async (event, data) => {
      try {
        if (
          data?.eventID &&
          this.state.socketEvents.find((e) => e === data?.eventID)
        )
          return;
        switch (event) {
          case "reply":
            this.setState((curr) => ({
              ...curr,
              totalReplies: curr.totalReplies + 1,
              socketEvents: data?.eventID
                ? [...this.state.socketEvents, data?.eventID]
                : this.state.socketEvents,
            }));
            break;
          case "account-deleted":
            this.setState((curr) => ({
              ...curr,
              replies: curr.replies.map((reply) => {
                if (reply.userID === data)
                  reply = {
                    ...reply,
                    ...accountDeletedEmission(reply.username, reply.userID),
                  };
                if (reply.signalBoost?.userID === data)
                  reply.signalBoost = {
                    ...reply.signalBoost,
                    ...accountDeletedEmission(
                      reply.signalBoost.username,
                      reply.signalBoost.userID
                    ),
                  };
                return reply;
              }),
            }));
            break;
          case "remove-emission":
            if (
              data?.eventID &&
              this.state.socketEvents.find((e) => e === data?.eventID)
            )
              return;
            else
              this.setState(
                (curr) => ({
                  ...curr,
                  socketEvents: data?.eventID
                    ? [...this.state.socketEvents, data?.eventID]
                    : this.state.socketEvents,
                }),
                () => {
                  if (h.checkJanny(this.props.userInfo))
                    this.setState((curr) => ({
                      ...curr,
                      replies: h.updateEmission(
                        this.state.replies,
                        data,
                        this.props.userInfo
                      ),
                    }));
                  else
                    this.setState((curr) => ({
                      ...curr,
                      replies: h.replaceEmissions(
                        curr.replies,
                        data.emissionID,
                        {
                          html: `<h5 class="text-center display-6 my-4"><span class="text-capitalize">${env.EMISSION_NAME}</span> Removed</h5>`,
                          remove: {
                            ...data.remove,
                            details: false,
                          },
                          signalBoost: false,
                        }
                      ),
                    }));
                }
              );

            break;
          case "restore-emission":
            this.setState((curr) => ({
              ...curr,
              socketEvents: data?.eventID
                ? [...this.state.socketEvents, data?.eventID]
                : this.state.socketEvents,
              replies: h.updateEmission(
                this.state.replies,
                data,
                this.props.userInfo
              ),
            }));
            break;
          case "update-user":
            if (
              data?.eventID &&
              this.state.socketEvents.find((e) => e === data?.eventID)
            )
              return;
            this.setState((curr) => ({
              ...curr,
              socketEvents: data?.eventID
                ? [...this.state.socketEvents, data?.eventID]
                : this.state.socketEvents,
              replies: this.state.replies.map((reply) => {
                if (reply.userID === data._id)
                  reply = {
                    ...reply,
                    avatar: data.avatar,
                    displayName: data.displayName,
                    verified: data.verified,
                    role: data.role,
                  };

                return reply;
              }),
            }));
            break;
          case "ban":
            if (
              data?.eventID &&
              this.state.socketEvents.find((e) => e === data?.eventID)
            )
              return;
            this.setState((curr) => ({
              ...curr,
              socketEvents: data?.eventID
                ? [...this.state.socketEvents, data?.eventID]
                : this.state.socketEvents,
              replies: this.state.replies.map((reply) => {
                if (reply.userID === data._id)
                  reply = h.checkJanny(this.props.userInfo)
                    ? {
                        ...reply,
                        remove: {
                          removed: true,
                          reason: "ban",
                          user: {
                            username: "",
                            userID: "",
                          },
                          details: false,
                        },
                      }
                    : {
                        ...reply,
                        html: `<h5 class="text-center display-6 my-4">@${data.username} is banned</h5>`,
                        files: false,
                        pollData: false,
                        remove: {
                          removed: true,
                          reason: "ban",
                          user: {
                            username: "",
                            userID: "",
                          },
                          details: false,
                        },
                        signalBoost: false,
                      };

                if (reply.signalBoost?.userID === data._id)
                  reply.signalBoost = h.checkJanny(this.props.userInfo)
                    ? {
                        ...reply.signalBoost,
                        remove: {
                          removed: true,
                          reason: "ban",
                          user: {
                            username: "",
                            userID: "",
                          },
                          details: false,
                        },
                      }
                    : {
                        ...reply.signalBoost,
                        html: `<h5 class="text-center display-6 my-4">@${data.username} is banned</h5>`,
                        files: false,
                        pollData: false,
                        remove: {
                          removed: true,
                          reason: "ban",
                          user: {
                            username: "",
                            userID: "",
                          },
                          details: false,
                        },
                        signalBoost: false,
                      };

                return reply;
              }),
            }));
            break;
          case "private":
            this.setState((curr) => ({
              ...curr,
              socketEvents: data?.eventID
                ? [...this.state.socketEvents, data?.eventID]
                : this.state.socketEvents,
              replies: curr.replies.map((reply) => {
                if (reply.userID === data._id) {
                  reply = h.checkJanny(this.props.userInfo)
                    ? {
                        ...reply,
                        private: true,
                      }
                    : {
                        ...reply,
                        html: `<h5 class="text-center display-6 my-4">@${data.username} has made their profile private</h5>`,
                        private: true,
                        files: false,
                        pollData: false,
                        signalBoost: false,
                      };
                }
                if (reply.signalBoost?.userID === data._id) {
                  reply = h.checkJanny(this.props.userInfo)
                    ? {
                        ...reply.signalBoost,
                        private: true,
                      }
                    : {
                        ...reply.signalBoost,
                        html: `<h5 class="text-center display-6 my-4">@${data.username} has made their profile private</h5>`,
                        private: true,
                        files: false,
                        pollData: false,
                        signalBoost: false,
                      };
                }
                return reply;
              }),
            }));
          case "restore-user":
          case "unprivate":
          case "unblock":
            this.restoreEmissions(data);
            break;
          case "block":
            if (
              data?.eventID &&
              this.state.socketEvents.find((e) => e === data?.eventID)
            )
              return;
            else
              this.setState((curr) => ({
                ...curr,
                socketEvents: data?.eventID
                  ? [...this.state.socketEvents, data?.eventID]
                  : this.state.socketEvents,
                replies: this.state.replies.map((reply) => {
                  if (reply.userID === data._id)
                    reply = h.checkJanny(this.props.userInfo)
                      ? {
                          ...reply,
                          blocksMe: true,
                        }
                      : {
                          ...reply,
                          html: `<h5 class="text-center display-6 my-4">@${data.username} has blocked you</h5>`,
                          blocksMe: true,
                          files: false,
                          pollData: false,
                          signalBoost: false,
                        };
                  if (reply.signalBoost?.userID === data._id)
                    reply.signalBoost = h.checkJanny(this.props.userInfo)
                      ? {
                          ...reply.signalBoost,
                          blocksMe: true,
                        }
                      : {
                          ...reply.signalBoost,
                          html: `<h5 class="text-center display-6 my-4">@${data.username} has blocked you</h5>`,
                          blocksMe: true,
                          files: false,
                          pollData: false,
                          signalBoost: false,
                        };
                  return reply;
                }),
              }));
        }
      } catch (err) {
        console.log("onAny error", err);
      }
    });
  };

  scroll = (e) => {
    if (
      !this.state.loading &&
      this.props.modalShown &&
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) <
        0.1 * e.target.clientHeight &&
      this.state.replies.length &&
      this.state.replies.length < this.state.totalReplies
    ) {
      this.setState(
        (curr) => ({
          ...curr,
          loading: true,
        }),
        this.loadReplies
      );
    }
  };

  restoreEmissions = async (data) => {
    try {
      if (
        data?.eventID &&
        this.state.socketEvents.find((e) => e === data?.eventID)
      )
        return;
      else
        this.setState(
          (curr) => ({
            ...curr,
            socketEvents: data?.eventID
              ? [...this.state.socketEvents, data?.eventID]
              : this.state.socketEvents,
          }),
          async () => {
            try {
              const emissions = h.getThreadEmissions(data, this.state.replies);
              const getEmissions = () =>
                new Promise((resolve) =>
                  axios
                    .post(
                      process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/by-id",
                      {
                        emissions: emissions,
                      },
                      {
                        headers: {
                          Authorization: this.props.token,
                        },
                      }
                    )
                    .then((res) => {
                      this.props.set_token(res.data.token);
                      resolve(res.data.emissions);
                    })
                    .catch((err) => {
                      console.log("restored emissions err", err);
                      setTimeout(async () => {
                        const restoredEmissions = await getEmissions();
                        resolve(restoredEmissions);
                      }, 1500);
                    })
                );
              let restoredEmissions = [];
              if (emissions.length) restoredEmissions = await getEmissions();
              this.setState((curr) => ({
                ...curr,
                replies: h.replaceUserEmissions(
                  this.state.replies,
                  restoredEmissions
                ),
              }));
            } catch (err) {
              console.log("restoreEmissions setState callback error", err);
            }
          }
        );
    } catch (err) {
      console.log("restore user error", err);
    }
  };

  loadReplies = (first) => {
    this.setState(
      (curr) => ({
        ...curr,
        loading: true,
        replies: first ? this.props.replies || [] : this.state.replies,
      }),
      () =>
        axios
          .post(
            process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/file-replies",
            {
              ids: this.state.replies.map((reply) => reply._id),
              replyID: this.props.emission.emissionID,
            },
            {
              headers: {
                Authorization: this.props.token,
              },
            }
          )
          .then((res) => {
            /**
             * socket to increase state.totalReplies
             * userInfo changes, ban/block/private/delete
             */
            this.props.set_token(res.data.token);
            this.setState((curr) => ({
              ...curr,
              loading: false,
              totalReplies: res.data.total,
              replies: [...this.state.replies, ...res.data.replies],
            }));
          })
          .catch((err) => {
            console.log("load replies error", err);
            setTimeout(() => this.loadReplies(first), 1000);
          })
    );
  };

  arrowNav = (e) => {
    if (
      e.key === "ArrowRight" &&
      this.props.fileList[this.props.fileList.indexOf(this.props.file) + 1]
    )
      this.next();
    if (
      e.key === "ArrowLeft" &&
      this.props.fileList[this.props.fileList.indexOf(this.props.file) - 1]
    )
      this.back();
  };

  // Toggles the remove/report form
  openActionForm = () =>
    this.setState((curr) => ({
      ...curr,
      actionFormOpened: !this.state.actionFormOpened,
    }));

  /**
   * Triggered when the user clicks the back button
   * Adjust animations to slide right
   * Triggers the fileNav method in the parent
   *
   */
  back = () =>
    this.setState(
      (curr) => ({
        ...curr,
        exit: t.fade_out_right,
        enter: t.fade_out_left,
      }),
      () => this.props.fileNav(false)
    );

  /**
   * Triggered when the user clicks the next button
   * Adjust animations to slide right
   * Triggers the fileNav method in the parent
   *
   */
  next = () =>
    this.setState(
      (curr) => ({
        ...curr,
        enter: t.fade_out_right,
        exit: t.fade_out_left,
      }),
      () => this.props.fileNav(true)
    );

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks inside the modal
   * If the user clicked blank space, close the modal
   */
  clickToClose = (e) => {
    if (e.target.classList.contains("file-modal-blank-space")) {
      this.props.toggleShowModal();
      this.setState((curr) => ({
        ...curr,
        enter: t.fade_out,
        exit: t.fade_out,
      }));
    }
  };

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks inside the emission body
   * If the user clicked a link, route to the href
   */
  clickEmissionBody = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target;
    if (e.target.tagName === "SPAN") element = e.target.parentElement;
    if (element.tagName === "A") {
      const href = element.getAttribute("href");
      if (!href) {
        console.log("no link found", element, e.target);
        return;
      }
      const url = new Url(href);
      if (url.hostname === window.location.hostname)
        this.props.route(url.pathname);
      else {
        console.log("native route FileModal");
        window.location = href;
      }
    }
  };

  /**
   *
   * @param {Click Event} e
   * @param {String} path - href/URL
   *
   * Triggered when the user clicks a link
   * Override default behavior and use redux props.route method
   */
  route = (e, path) => {
    e.preventDefault();
    this.props.route(path);
  };

  /**
   * Triggered when the user clicks the Copy Link button
   *
   * Copies the link to the user's clipboard
   * Dispatches blank scoll event which updates the tooltip
   *
   */
  copyEmissionLink = () =>
    this.setState(
      (curr) => ({
        ...curr,
        emissionCopied: true,
      }),
      () => {
        navigator.clipboard.writeText(
          env.HOST + "/e/" + this.props.emission_id
        );
        window.dispatchEvent(new Event("scroll"));
      }
    );

  externalAction = (action) => {
    if (!this.props.userInfo.ban.banned) this.props.fileAction(action);
    else
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
  };

  /**
   * Returns a card background color based on the state of the emission
   *
   * If highlighted, light green
   * If author is blocked or emission is removed, light pink
   * If author blocks the user, light purple
   * If author has privated their account, light teal
   *
   * @returns A CSS background class
   */
  getCardBackground = (emission) => {
    if (emission) {
      if (emission.isBlocked) return "bg-litepurple";
      else if (emission.remove.removed) return "bg-litepink";
      else if (emission.blocksMe) return "bg-litepurple";
      else if (emission.private) return "bg-liteteal";
      else return "bg-default";
    } else return "bg-default";
  };

  /**
   *
   * Special emissions have gold numbers.
   * The following Emission emissionIDs are special:
   * * Emission 1
   * * Any emission over 10 with all the same numbers (i.e. 666)
   * * Any emission over 10 where all numbers except for the first are zeros (i.e. 5000)
   *
   *
   * @returns Boolean - Whether the emission is a special emission
   */
  checkSpecialEmission = (emission) => {
    if (!emission) return false;
    if (emission.emissionID === 1) return true;
    const split = String(emission.emissionID).split("");
    if (split.length === 1) return false;
    if (split.every((c) => c === split[0])) return true;
    if (split.length < 3) return false;
    let special = true;
    split.forEach((char, s) => {
      if (s && Number(char)) special = false;
    });
    return special;
  };

  /**
   * Triggered when the user removes or restores their own emission
   *
   * Removes/restores the emission
   * Updates the emission
   */
  removeRestore = () => {
    if (!this.state.removing)
      this.setState(
        (curr) => ({
          ...curr,
          removing: true,
        }),
        () =>
          axios
            .get(
              `${process.env.REACT_APP_LAMBDA_EMISSIONS}/remove-restore-self/${this.props.emission_id}`,
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  removing: false,
                }),
                () => this.props.updateEmission(res.data.emission)
              );
            })
            .catch((err) =>
              this.setState(
                (curr) => ({
                  ...curr,
                  removing: false,
                }),
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  /**
   * Triggered when the user pins or unpins their own emission
   *
   * Pins/unpins the emission
   * Updates the emission
   */
  togglePin = () => {
    if (!this.state.pinning)
      this.setState(
        (curr) => ({
          ...curr,
          pinning: true,
        }),
        () =>
          axios
            .get(
              `${process.env.REACT_APP_LAMBDA_EMISSIONS}/pin-unpin/${this.props.emission_id}`,
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  pinning: false,
                }),
                () =>
                  this.props.updateEmission(h.setMetadata(res.data.emission))
              );
            })
            .catch((err) =>
              this.setState(
                (curr) => ({
                  ...curr,
                  pinning: false,
                }),
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later");
                }
              )
            )
      );
  };

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  submit = (enterPressed) => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        async () => {
          try {
            this.forceParse();
            const emissionData = document.getElementById(
              "input-reply-file-modal"
            );
            const length = String(emissionData.textContent)
              .split("")
              .filter((c) => {
                const checkWhiteSpace = c.match(/[\s]/);
                if (!checkWhiteSpace) return true;
                else {
                  return [" ", "\n"].indexOf(c) > -1;
                }
              }).length;
            if (
              !emissionData.textContent ||
              emissionData.innerHTML === "<div><p><br /></p></div>"
            )
              throw "Please enter a reply";
            if (length > maxChars)
              throw `Character limit exceeded (Max: ${maxChars} characters)`;
            const fd = new FormData();
            const captchaKey = await this.getRecaptcha();
            fd.append("captchaKey", captchaKey);
            fd.append("emission", emissionData.innerHTML);
            fd.append("replyID", this.props.emission.emissionID);
            fd.append("fromFileModal", true);

            axios
              .post(process.env.REACT_APP_LAMBDA_EMISSIONS + "/reply", fd, {
                headers: {
                  Authorization: this.props.token,
                },
              })
              .then((res) => {
                this.props.set_token(res.data.token);
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                    reset: !this.state.reset,
                    replies: [res.data.emission, ...this.state.replies],
                  }),
                  () => {
                    if (enterPressed)
                      this.setState((curr) => ({
                        ...curr,
                        contentKey: !this.state.contentKey,
                      }));
                  }
                );
              })
              .catch((err) =>
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                  }),
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later.");
                  }
                )
              );
          } catch (err) {
            this.setState(
              (curr) => ({
                ...curr,
                working: false,
              }),
              () => alert(err)
            );
          }
        }
      );
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            animationDirection="right"
            className="bg-transparent"
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={this.props.toggleShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog className="bg-transparent" size="fullscreen">
              <MDBModalContent className="bg-transparent">
                <MDBModalBody className="bg-transparent p-0">
                  {this.props.emission && (
                    <div className="d-flex bg-transparent h-100 file-modal-containers">
                      <div
                        onClick={this.clickToClose}
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.4" }}
                        className="w-75 h-100 d-flex flex-column file-modal-blank-space position-relative"
                      >
                        <i
                          className="fas fa-times fa-lg text-default cursor-pointer w-max-content file-modal-close-small position-absolute"
                          onClick={this.props.toggleShowModal}
                        ></i>
                        <div className="flex-grow-1 h-0 d-flex justify-content-center align-items-center w-100 file-modal-blank-space">
                          <div className="w-10 d-flex justify-content-center file-modal-blank-space">
                            {this.props.fileList[
                              this.props.fileList.indexOf(this.props.file) - 1
                            ] ? (
                              <MDBRipple
                                onClick={this.back}
                                className="cursor-pointer file-arrow-containers  rounded-pill"
                                rippleColor="light"
                              >
                                <i className="fas fa-chevron-left text-light file-modal-arrows"></i>
                              </MDBRipple>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="overflow-x-hidden overflow-y-hidden w-80 h-100 file-modal-blank-space py-2">
                            {this.props.file ? (
                              <StaticRouter
                                location={this.props.file.main.split(".")[0]}
                              >
                                <AnimatePresence exitBeforeEnter>
                                  <Switch
                                    key={this.props.file.main.split(".")[0]}
                                  >
                                    <Route exact path=":file">
                                      <File
                                        file={this.props.file}
                                        enter={this.state.enter}
                                        exit={this.state.exit}
                                        key={JSON.stringify(
                                          this.props.fileList
                                        )}
                                        userInfo={this.props.userInfo}
                                      />
                                    </Route>
                                  </Switch>
                                </AnimatePresence>
                              </StaticRouter>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="w-10 d-flex justify-content-center file-modal-blank-space">
                            {this.props.fileList[
                              this.props.fileList.indexOf(this.props.file) + 1
                            ] ? (
                              <MDBRipple
                                onClick={this.next}
                                className="cursor-pointer file-arrow-containers rounded-pill"
                                rippleColor="light"
                              >
                                <i className="fas fa-chevron-right file-modal-arrows d-block text-light"></i>
                              </MDBRipple>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <MDBContainer className="d-flex justify-content-between file-button-container">
                          <MDBTooltip
                            wrapperProps={{
                              color: "link",
                              rippleColor: "primary",
                              size: "sm",
                              className: "text-nowrap file-footer-buttons",
                              onClick: () =>
                                this.externalAction(
                                  this.props.reply(this.props.emission)
                                ),
                            }}
                            title="Reply"
                          >
                            <span className="file-stats">
                              <i className="far fa-comments file-modal-icons"></i>
                              <Count value={this.props.emission?.replies} />
                            </span>
                          </MDBTooltip>
                          <MDBTooltip
                            wrapperProps={{
                              color: "link",
                              rippleColor: "primary",
                              size: "sm",
                              className: "text-nowrap file-footer-buttons",
                              onClick: () =>
                                this.externalAction(
                                  this.props.signalBoost(this.props.emission)
                                ),
                            }}
                            title={
                              <span className="text-capitalize">
                                {env.SIGNALBOOST_VERB}
                              </span>
                            }
                          >
                            <span className="file-stats">
                              <i className="fas fa-retweet file-modal-icons"></i>
                              <Count
                                value={this.props.emission?.signalBoosts}
                              />
                            </span>
                          </MDBTooltip>
                          <MDBTooltip
                            wrapperProps={{
                              color: "link",
                              rippleColor: "#fc45df",
                              size: "sm",
                              onClick: () =>
                                this.props.like(
                                  this.props.emission?.emissionID
                                ),
                              className: "text-nowrap file-footer-buttons",
                            }}
                            title="Like"
                          >
                            <span
                              className={`${
                                this.props.emission?.liked ? "text-pink" : ""
                              } file-stats`}
                            >
                              <i
                                className={`fa${
                                  this.props.emission?.liked ? "s" : "r"
                                } fa-heart file-modal-icons`}
                              ></i>
                              <Count value={this.props.emission?.likes} />
                            </span>
                          </MDBTooltip>
                          <MDBTooltip
                            className="min-w-max-content text-nowrap"
                            wrapperProps={{
                              color: "link",
                              className:
                                "cursor-default file-footer-buttons text-nowrap",
                              rippleColor: "primary",
                              size: "sm",
                            }}
                            title="Views"
                          >
                            <span className="file-stats">
                              <i className="fas fa-chart-bar file-modal-icons"></i>
                              <Count value={this.props.emission?.views} />
                            </span>
                          </MDBTooltip>
                          <MDBTooltip
                            className="min-w-max-content text-nowrap"
                            wrapperProps={{
                              color: "link",
                              rippleColor: "primary",
                              onClick: this.copyEmissionLink,
                              size: "sm",
                              className: "text-nowrap file-footer-buttons",
                            }}
                            title={
                              this.state.emissionCopied
                                ? "Link Copied"
                                : env.HOST + "/e/" + this.props.emission_id
                            }
                            placement="left"
                          >
                            <span className="file-stats">
                              <i className="fas fa-share-alt file-modal-icons"></i>
                              Link
                            </span>
                          </MDBTooltip>
                        </MDBContainer>
                      </div>
                      <div
                        className={`w-25 h-100 transition-25 ${this.getCardBackground(
                          this.props.emission
                        )} file-modal-info`}
                      >
                        <div className="d-flex justify-content-between align-items-center px-2 pe-1rem">
                          <h5
                            className={`mb-0 mt-1 text-pkmn text-nowrap d-block text-${
                              this.checkSpecialEmission(this.props.emission)
                                ? "gold"
                                : "default"
                            }`}
                          >
                            <Link
                              className="text-default"
                              onClick={(e) =>
                                this.route(
                                  e,
                                  `/e/${this.props.emission?.emissionID}`
                                )
                              }
                              to={`/e/${this.props.emission?.emissionID}`}
                            >
                              #{this.props.emission?.emissionID}
                            </Link>
                          </h5>
                          <i
                            className="fas fa-times fa-lg d-block text-default cursor-pointer w-max-content"
                            onClick={this.props.toggleShowModal}
                          ></i>
                        </div>
                        <div className="pe-1rem">
                          <hr style={{ margin: "0.5rem 0 1rem 0" }}></hr>
                        </div>

                        <div
                          ref={this.scrollRef}
                          className="file-details-body scrollbar-slim pe-1rem"
                        >
                          <div className="d-flex emission-details-file justify-content-between mb-2">
                            <div className="d-flex avatar-name-file">
                              <Link
                                onClick={(e) =>
                                  this.route(
                                    e,
                                    `/${this.props.emission?.username}`
                                  )
                                }
                                className="h-max-content w-max-content"
                                to={`/${this.props.emission?.username}`}
                              >
                                <div className="d-flex justify-content-center align-items-center square-4 cursor-pointer">
                                  <div
                                    className="fit-images rounded-circle fit-round"
                                    style={{
                                      backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/images/${this.props.emission?.avatar.main}")`,
                                    }}
                                  ></div>
                                </div>
                              </Link>
                              <div className="px-2 flex-grow-1">
                                <Link
                                  onClick={(e) =>
                                    this.route(
                                      e,
                                      `/${this.props.emission?.username}`
                                    )
                                  }
                                  className="max-w-max-content d-block"
                                  to={`/${this.props.emission?.username}`}
                                >
                                  <h5 className="mb-0 text-default text-break mx-0">
                                    {h.getBadge(
                                      this.props.emission,
                                      "me-2 badge-h5"
                                    )}
                                    {this.props.emission?.displayName}
                                  </h5>
                                </Link>
                                <Link
                                  onClick={(e) =>
                                    this.route(
                                      e,
                                      `/${this.props.emission?.username}`
                                    )
                                  }
                                  className="max-w-max-content d-block"
                                  to={`/${this.props.emission?.username}`}
                                >
                                  <p className="text-blusteel text-break mx-0">
                                    @{this.props.emission?.username}
                                  </p>
                                </Link>
                              </div>
                            </div>
                            <div className="file-ids">
                              <div className="px-2 ms-auto file-data">
                                <p className="mb-0 text-end d-block text-nowrap">
                                  {h.makeDateHR(this.props.emission?.timestamp)}
                                </p>
                                <p className="mb-0 text-blusteel text-end d-block text-nowrap">
                                  {h.getTimeHR(this.props.emission?.timestamp)}
                                </p>
                              </div>

                              {this.props.emission?.userID ===
                                this.props.userInfo._id &&
                              !this.props.userInfo.ban.banned ? (
                                <div className="d-flex justify-content-end file-action-buttons">
                                  <MDBDropdown className="ms-2">
                                    <MDBDropdownToggle
                                      rippleColor="primary"
                                      color="link"
                                      className="profile-bubbles"
                                    >
                                      <i
                                        style={{ lineHeight: "1.5" }}
                                        className="fas fa-ellipsis-v fa-lg"
                                      ></i>
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                      {this.props.emission?.pinned ? (
                                        <MDBDropdownItem
                                          onClick={this.togglePin}
                                          link
                                        >
                                          <h6 className="m-1">
                                            {this.state.pinning ? (
                                              <>
                                                <Spinner
                                                  color="primary"
                                                  className="me-2"
                                                  size="sm"
                                                />
                                                Unpinning
                                              </>
                                            ) : (
                                              <>
                                                <i className="fas fa-thumbtack me-2 text-primary"></i>
                                                Unpin
                                              </>
                                            )}
                                          </h6>
                                        </MDBDropdownItem>
                                      ) : (
                                        <MDBDropdownItem
                                          onClick={this.togglePin}
                                          link
                                        >
                                          <h6 className="m-1">
                                            {this.state.pinning ? (
                                              <>
                                                <Spinner
                                                  className="me-2"
                                                  size="sm"
                                                  color="primary"
                                                />
                                                Pinning
                                              </>
                                            ) : (
                                              <>
                                                <i className="fas fa-thumbtack me-2 text-primary"></i>
                                                Pin
                                              </>
                                            )}
                                          </h6>
                                        </MDBDropdownItem>
                                      )}
                                      {this.props.emission?.remove.removed ? (
                                        <>
                                          {this.props.emission?.remove.user
                                            .userID ===
                                          this.props.userInfo._id ? (
                                            <MDBDropdownItem
                                              onClick={this.removeRestore}
                                              link
                                            >
                                              <h6
                                                style={{
                                                  opacity: this.state.removing
                                                    ? 0.7
                                                    : 1,
                                                }}
                                                className="m-1"
                                              >
                                                {this.state.removing ? (
                                                  <>
                                                    <Spinner
                                                      color="success"
                                                      size="sm"
                                                      className="me-2"
                                                    />
                                                    Restoring
                                                  </>
                                                ) : (
                                                  <>
                                                    <i className="far fa-check-circle me-2 text-success"></i>
                                                    Restore
                                                  </>
                                                )}
                                              </h6>
                                            </MDBDropdownItem>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <MDBDropdownItem
                                            onClick={this.removeRestore}
                                            link
                                          >
                                            <h6
                                              style={{
                                                opacity: this.state.removing
                                                  ? 0.7
                                                  : 1,
                                              }}
                                              className="m-1"
                                            >
                                              {this.state.removing ? (
                                                <>
                                                  <Spinner
                                                    color="danger"
                                                    className="me-2"
                                                    size="sm"
                                                  />
                                                  Removing
                                                </>
                                              ) : (
                                                <>
                                                  <i className="far fa-trash-alt me-2 text-danger"></i>
                                                  Remove
                                                </>
                                              )}
                                            </h6>
                                          </MDBDropdownItem>
                                        </>
                                      )}
                                    </MDBDropdownMenu>
                                  </MDBDropdown>
                                </div>
                              ) : (
                                <>
                                  {h.checkJanny(this.props.userInfo) ? (
                                    <>
                                      {this.props.emission?.remove.removed ? (
                                        <MDBBtn
                                          onClick={() =>
                                            this.externalAction(() =>
                                              this.props.restore(
                                                this.props.emission
                                              )
                                            )
                                          }
                                          className="text-blusteel d-block ms-auto px-2"
                                          color="link"
                                        >
                                          <i className="far fa-check-circle me-2"></i>
                                          Restore
                                        </MDBBtn>
                                      ) : (
                                        <MDBBtn
                                          onClick={() =>
                                            this.externalAction(() =>
                                              this.props.remove(
                                                this.props.emission
                                              )
                                            )
                                          }
                                          className="text-blusteel d-block ms-auto px-2"
                                          color="link"
                                        >
                                          <i className="fas fa-trash-alt me-2"></i>
                                          Remove
                                        </MDBBtn>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {this.props.emission?.remove.removed ||
                                      this.props.userInfo.ban.banned ||
                                      this.props.emission?.isBlocked ||
                                      this.props.emission?.blocksMe ||
                                      this.props.emission?.private ? (
                                        <></>
                                      ) : (
                                        <>
                                          {this.props.emission?.reported ? (
                                            <motion.h5
                                              transition={t.transition}
                                              exit={t.fade_out_minimize}
                                              animate={t.normalize}
                                              initial={t.fade_out_minimize}
                                              className="text-danger text-end ms-auto"
                                            >
                                              <span className="text-capitalize">
                                                {env.REACT_APP_EMISSION_NAME}
                                              </span>{" "}
                                              Reported
                                            </motion.h5>
                                          ) : (
                                            <MDBBtn
                                              onClick={() =>
                                                this.externalAction(() =>
                                                  this.props.report(
                                                    this.props.emission
                                                  )
                                                )
                                              }
                                              className="text-blusteel d-block ms-auto px-2"
                                              color="link"
                                            >
                                              <i className="fas fa-flag me-2"></i>
                                              Report
                                            </MDBBtn>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div
                            ref={this.scrollRefMobile}
                            className="file-emission-bodies overflow-x-hidden scrollbar-slim"
                          >
                            {h.getMediaFromHtml(this.props.emission?.html) && (
                              <div className="d-flex justify-content-center mb-2 file-media-container">
                                {h.getMediaFromHtml(this.props.emission?.html)}
                              </div>
                            )}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.props.emission?.html,
                              }}
                              onClick={this.clickEmissionBody}
                            ></div>
                            <hr />
                            {this.props.userInfo._id && (
                              <TextInput
                                key={
                                  this.props.emission?._id +
                                  String(this.state.reset)
                                }
                                submit={this.submit}
                                working={this.state.working}
                                flavor="reply-file-modal"
                                maxChars={maxChars}
                                label="Reply"
                                setForceParse={(f) => (this.forceParse = f)}
                                contentKey={this.state.contentKey}
                              />
                            )}

                            {this.state.replies.map((reply) => (
                              <motion.div
                                transition={t.transition}
                                initial={t.fade_out_right}
                                animate={t.normalize}
                                exit={t.fade_out_scale_1}
                                className="my-2"
                                key={reply._id}
                              >
                                <MDBRipple
                                  rippleColor="primary"
                                  className="w-100 cursor-pointer"
                                >
                                  <SampleEmission
                                    emission={reply}
                                    barebones={true}
                                  />
                                </MDBRipple>
                              </motion.div>
                            ))}
                            <div className="mb-2 d-flex justify-content-center">
                              {this.state.loading &&
                              this.props.emission?.replies &&
                              this.props.emission.replies >
                                this.state.replies.length ? (
                                <Spinner color="primary" />
                              ) : (
                                <>
                                  {this.state.replies.length &&
                                  this.state.replies.length <
                                    this.state.totalReplies &&
                                  !this.state.working ? (
                                    <MDBBtn
                                      className="d-block mx-auto"
                                      color="link"
                                      rippleColor="primary"
                                      onClick={this.loadReplies}
                                    >
                                      Load More
                                    </MDBBtn>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route, set_token, notify })(
  FileModal
);
