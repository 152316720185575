import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import Spinner from "../../components/Spinner";

class RemoveConversationModal extends React.Component {
  constructor() {
    super();
    this.state = {
      working: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.working && prevProps.modalShown && !this.props.modalShown)
      this.setState((curr) => ({
        ...curr,
        working: false,
      }));
  }

  confirm = () =>
    this.setState(
      (curr) => ({
        ...curr,
        working: true,
      }),
      () => {
        this.props.socket.emit(
          "remove-conversation",
          this.props.conversation._id
        );
      }
    );

  render() {
    const isStarter =
      this.props.userInfo._id === this.props.conversation?.starter;
    return (
      <MDBModal
        open={this.props.modalShown}
        staticBackdrop
        onClosePrevented={() => {
          if (!this.state.working) this.props.toggleShowModal();
        }}
        tabIndex="-1"
      >
        <MDBModalDialog
          size={
            this.props.screenDimensions.width >
            this.props.screenDimensions.modalBreak
              ? "xl"
              : "fullscreen"
          }
        >
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Remove Conversation</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={this.props.toggleShowModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
              Would you like to permanently{" "}
              {isStarter ? "delete" : "remove yourself from"} this conversation?
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                disabled={this.state.working}
                color="danger"
                onClick={this.confirm}
              >
                {this.state.working ? (
                  <>
                    <Spinner size="sm" className="me-2" />
                    Removing
                  </>
                ) : (
                  <>
                    <i className="fas fa-check-circle me-2" />
                    Confirm
                  </>
                )}
              </MDBBtn>
              <MDBBtn
                color="dark"
                className="bg-gray"
                onClick={this.props.toggleShowModal}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  }
}

const mapStateToProps = (state) => ({
  screenDimensions: state.screenDimensions,
  socket: state.socket,
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, {})(RemoveConversationModal);
