import { store } from "../";
import { v4 as uuid } from "uuid";

export const SET_TOKEN = "SET_TOKEN";
export const set_token = (token) => ({
  type: SET_TOKEN,
  data: token,
});

export const SET_STREAM_ELIGIBILITY = "SET_STREAM_ELIGIBILITY";
export const set_stream_eligibility = (data) => ({
  type: SET_STREAM_ELIGIBILITY,
  data,
});

export const VIRGIL_CHAD_LOAD_MORE = "VIRGIL_CHAD_LOAD_MORE";
export const virgil_chad_load_more = (data) => ({
  type: VIRGIL_CHAD_LOAD_MORE,
  data,
});

export const REMOVE_VIRGIL_CHAD = "REMOVE_VIRGIL_CHAD";
export const remove_virgil_chad = (data) => ({
  type: REMOVE_VIRGIL_CHAD,
  data,
});

export const SET_VIRGIL_CHAD = "SET_VIRGIL_CHAD";
export const set_virgil_chad = (data) => ({
  type: SET_VIRGIL_CHAD,
  data,
});

export const SET_SCREEN_DIMENSIONS = "SET_SCREEN_DIMENSIONS";
export const set_screen_dimensions = (dimensions) => ({
  type: SET_SCREEN_DIMENSIONS,
  data: dimensions,
});

export const SET_HISTORY = "SET_HISTORY";
export const set_history = (
  location,
  history,
  userInfo,
  verificationDetails,
  ping,
  virgil,
  chad
) => ({
  type: SET_HISTORY,
  data: {
    location,
    history,
    userInfo,
    verificationDetails,
    ping,
    virgil,
    chad,
  },
});

export const NOTIFY = "NOTIFY";
export const notify = (icon, text) => ({
  type: NOTIFY,
  data: {
    icon,
    text,
    id: uuid(),
  },
});

export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const remove_notification = (id) => ({
  type: REMOVE_NOTIFICATION,
  data: id,
});

export const TEST = "TEST";
export const test = () => ({
  type: TEST,
});

export const STREAM_CLEAR = "STREAM_CLEAR";
export const stream_clear = () => ({
  type: STREAM_CLEAR,
});

export const TOGGLE_GO_LIVE_MODAL = "TOGGLE_GO_LIVE_MODAL";
export const toggle_go_live_modal = () => ({
  type: TOGGLE_GO_LIVE_MODAL,
});

export const VIEWERS = "VIEWERS";
export const viewers = (count) => ({
  type: VIEWERS,
  data: count,
});

export const UPDATE_SOCKET_STATE = "UPDATE_SOCKET_STATE";
export const update_socket_state = (state) => ({
  type: UPDATE_SOCKET_STATE,
  data: state,
});

export const SET_VERIFICATION_DETAILS = "SET_VERIFICATION_DETAILS";
export const set_verification_details = (details) => ({
  type: SET_VERIFICATION_DETAILS,
  data: details,
});

export const SET_LIVE = "SET_LIVE";
export const set_live = () => ({
  type: SET_LIVE,
});

export const BLOCK = "BLOCK";
export const block = () => ({
  type: BLOCK,
});

export const UNBLOCK = "UNBLOCK";
export const unblock = () => ({
  type: UNBLOCK,
});

export const BLOCK_OTHER = "BLOCK_OTHER";
export const block_other = (userInfo) => ({
  type: BLOCK_OTHER,
  data: userInfo,
});

export const UNBLOCK_OTHER = "UNBLOCK_OTHER";
export const unblock_other = (userInfo, emissions) => ({
  type: UNBLOCK_OTHER,
  data: {
    userInfo: userInfo,
    emissions: emissions,
  },
});

export const STREAM_START = "STREAM_START";
export const stream_start = (data) => ({
  type: STREAM_START,
  data: data,
});

export const STREAM_END = "STREAM_END";
export const stream_end = () => ({
  type: STREAM_END,
});

export const NEW_STREAM_CHAT = "NEW_STREAM_CHAT";
export const new_stream_chat = (message) => ({
  type: NEW_STREAM_CHAT,
  data: message,
});

export const CLEAR_STREAM_CHAT = "CLEAR_STREAM_CHAT";
export const clear_stream_chat = () => ({
  type: CLEAR_STREAM_CHAT,
});

export const STREAM_EVENT_GENERIC = "STREAM_EVENT_GENERIC";
export const stream_event_generic = () => ({
  type: STREAM_EVENT_GENERIC,
});

export const SET_GO_LIVE_MODAL = "SET_GO_LIVE_MODAL";
export const set_go_live_modal = (option) => ({
  type: SET_GO_LIVE_MODAL,
  data: option,
});

export const SET_STREAM = "SET_STREAM";
export const set_stream = (stream) => ({
  type: SET_STREAM,
  data: stream,
});

export const ADD_MESSAGE_PROFILE = "ADD_MESSAGE_PROFILE";
export const add_message_profile = (message) => ({
  type: ADD_MESSAGE_PROFILE,
  data: message,
});

export const DISABLE_MESSAGES = "DISABLE_MESSAGES";
export const disable_messages = (option) => ({
  type: DISABLE_MESSAGES,
  data: option,
});

export const TOGGLE_DISABLE_MESSAGES = "TOGGLE_DISABLE_MESSAGES";
export const toggle_disable_messages = () => ({
  type: TOGGLE_DISABLE_MESSAGES,
});

export const TOGGLE_PRIVATE = "TOGGLE_PRIVATE";
export const toggle_private = () => ({
  type: TOGGLE_PRIVATE,
});

export const PRIVATE_PROFILE = "PRIVATE_PROFILE";
export const private_profile = (userInfo) => ({
  type: PRIVATE_PROFILE,
  data: userInfo,
});

export const NEW_FOLLOW = "NEW_FOLLOW";
export const new_follow = (added) => ({
  type: NEW_FOLLOW,
  data: added,
});

export const NEW_FOLLOW_SELF = "NEW_FOLLOW_SELF";
export const new_follow_self = (added) => ({
  type: NEW_FOLLOW_SELF,
  data: added,
});

export const FOLLOW = "FOLLOW";
export const follow = (data) => ({
  type: FOLLOW,
  data,
});

export const UNFOLLOW = "UNFOLLOW";
export const unfollow = () => ({
  type: UNFOLLOW,
});

export const FOLLOWING = "FOLLOWING";
export const following = (inc) => ({
  type: FOLLOWING,
  data: inc,
});

export const BAN_OTHER = "BAN_OTHER";
export const ban_other = (userInfo) => ({
  type: BAN_OTHER,
  data: userInfo,
});

export const USER_EDIT = "USER_EDIT";
export const user_edit = (userInfo) => ({
  type: USER_EDIT,
  data: userInfo,
});

export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const account_deleted = (userID) => ({
  type: ACCOUNT_DELETED,
  data: userID,
});

export const UPDATE_USER = "UPDATE_USER";
export const update_user = (userInfo, clearValidation) => ({
  type: UPDATE_USER,
  data: {
    userInfo,
    clearValidation,
  },
});

export const SET_USER = "SET_USER";
export const set_user = (userInfo, profileInfo, thing) => {
  return {
    type: SET_USER,
    data: {
      userInfo,
      profileInfo,
      thing,
    },
  };
};

export const UNPRIVATE = "UNPRIVATE";
export const unprivate = (userInfo, emissions) => ({
  type: UNPRIVATE,
  data: {
    userInfo: userInfo,
    emissions: emissions,
  },
});

export const SET_POLL_VOTED = "SET_POLL_VOTED";
export const set_poll_voted = (emissionID) => ({
  type: SET_POLL_VOTED,
  data: emissionID,
});

export const CHANGE_USER_DETAILS = "CHANGE_USER_DETAILS";
export const change_user_details = (userInfo) => ({
  type: CHANGE_USER_DETAILS,
  data: { userInfo },
});

export const ADD_EMISSIONS = "ADD_EMISSIONS";
export const add_emissions = (emissions) => ({
  type: ADD_EMISSIONS,
  data: emissions,
});

export const ADD_LIKES = "ADD_LIKES";
export const add_likes = (likes) => ({
  type: ADD_LIKES,
  data: likes,
});

export const SET_TEMP_ACTION = "SET_TEMP_ACTION";
export const set_temp_action = (action) => ({
  type: SET_TEMP_ACTION,
  data: action,
});

export const CLEAR_TEMP_ACTION = "CLEAR_TEMP_ACTION";
export const clear_temp_action = (action) => ({
  type: CLEAR_TEMP_ACTION,
});

export const RECONNECT_SOCKET = "RECONNECT_SOCKET";
export const reconnect_socket = () => ({
  type: RECONNECT_SOCKET,
});

export const SET_PROFILE = "SET_PROFILE";
export const set_profile = (data) => ({
  type: SET_PROFILE,
  data: data,
});

export const NEW_EMISSION = "NEW_EMISSION";
export const new_emission = (emission) => ({
  type: NEW_EMISSION,
  data: emission,
});

export const NEW_LIKE = "NEW_LIKE";
export const new_like = (emission) => ({
  type: NEW_LIKE,
  data: emission,
});

export const VIEW = "VIEW";
export const view = (emissionID) => ({
  type: VIEW,
  data: emissionID,
});

export const SET_CAPTCHA_READY = "SET_CAPTCHA_READY";
export const set_captcha_ready = () => ({
  type: SET_CAPTCHA_READY,
});

export const VIEWS = "VIEWS";
export const views = (emissionIDs) => ({
  type: VIEWS,
  data: emissionIDs,
});

export const SEARCH_CHANGE = "SEARCH_CHANGE";
export const search_change = (e, source) => ({
  type: SEARCH_CHANGE,
  data: {
    text: e.target.value,
    source: source,
  },
});

export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const set_search_term = () => ({
  type: SET_SEARCH_TERM,
});

export const SET_UNREAD_MESSAGES = "SET_UNREAD_MESSAGES";
export const set_unread_messages = (unreadMessages) => ({
  type: SET_UNREAD_MESSAGES,
  data: unreadMessages,
});

export const SET_UNREAD_MOD_LOGS = "SET_UNREAD_MOD_LOGS";
export const set_unread_mod_logs = (count) => ({
  type: SET_UNREAD_MOD_LOGS,
  data: count,
});

export const SET_OPEN_REPORTS = "SET_OPEN_REPORTS";
export const set_open_reports = (openReports) => ({
  type: SET_OPEN_REPORTS,
  data: openReports,
});

export const SET_PROFILE_REPORTED = "SET_PROFILE_REPORTED";
export const set_profile_reported = () => ({
  type: SET_PROFILE_REPORTED,
});

export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";
export const set_notification_count = (count) => ({
  type: SET_NOTIFICATION_COUNT,
  data: count,
});

export const SET_REPORT_COUNT = "SET_REPORT_COUNT";
export const set_report_count = (count) => ({
  type: SET_REPORT_COUNT,
  data: count,
});

export const VOTE = "VOTE";
export const vote = (pollData) => ({
  type: VOTE,
  data: pollData,
});

export const LIKE_SELF = "LIKE_SELF";
export const like_self = (emissionID, eventID) => ({
  type: LIKE_SELF,
  data: { emissionID, eventID },
});

export const LIKE_OTHER = "LIKE_OTHER";
export const like_other = (data) => ({
  type: LIKE_OTHER,
  data: data,
});

export const SIGNAL_BOOST = "SIGNAL_BOOST";
export const signal_boost = (data) => ({
  type: SIGNAL_BOOST,
  data,
});

export const UPDATE_EMISSION = "UPDATE_EMISSION";
export const update_emission = (emission) => ({
  type: UPDATE_EMISSION,
  data: emission,
});

export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const clear_profile = () => ({
  type: CLEAR_PROFILE,
});

export const SELECT_DASHBOARD_ITEM = "SELECT_DASHBOARD_ITEM";
export const select_dashboard_item = (item) => ({
  type: SELECT_DASHBOARD_ITEM,
  data: item,
});

export const SET_CLICK_PASS = "SET_CLICK_PASS";
export const set_click_pass = (data) => ({
  type: SET_CLICK_PASS,
  data: data,
});

export const SET_TOAST = "SET_TOAST";
export const set_toast = (icon, text) => ({
  type: SET_TOAST,
  data: {
    icon,
    text,
  },
});

export const SET_REDIRECT_URL = "SET_REDIRECT_URL";
export const set_redirect_url = (url) => ({
  type: SET_REDIRECT_URL,
  data: url,
});

export const SET_PROFILE_CONVERSATION = "SET_PROFILE_CONVERSATION";
export const set_profile_conversation = (conversation) => ({
  type: SET_PROFILE_CONVERSATION,
  data: conversation,
});

export const REMOVE_CONVERSATION_PROFILE = "REMOVE_CONVERSATION_PROFILE";
export const remove_conversation_profile = (conversationID, unread) => ({
  type: REMOVE_CONVERSATION_PROFILE,
  data: {
    conversationID,
    unread,
  },
});

export const LEAVE_CONVERSATION_PROFILE = "LEAVE_CONVERSATION_PROFILE";
export const leave_conversation_profile = (conversationID, userID, unread) => ({
  type: LEAVE_CONVERSATION_PROFILE,
  data: {
    conversationID,
    userID,
    unread,
  },
});

export const REMOVE_MESSAGE_PROFILE = "REMOVE_MESSAGE_PROFILE";
export const remove_message_profile = (data) => ({
  type: REMOVE_MESSAGE_PROFILE,
  data,
});

export const PURGE_USER = "PURGE_USER";
export const purge_user = () => ({
  type: PURGE_USER,
});

export const DASHBOARD_NAV = "DASHBOARD_NAV";
export const dashboard_nav = (item) => ({
  type: DASHBOARD_NAV,
  data: item,
});

export const ROUTE = "ROUTE";
export const route = (destination) => {
  /**
   * Get state from reducer
   * Get current route
   * If user logged in and going to login, redirect to dashboard and vice-versa
   * Route user, then add most recent route to history stack in the reducer
   */
  let state = store.getState();
  if (!state.history) {
    console.log("No history");
    window.location = destination;
    return {
      type: "ROUTE",
      data: {
        noHistory: true,
      },
    };
  }
  const prevRoute = state.history.location.pathname;
  if (
    state.userInfo._id &&
    ["login", "create-account", "forgot-password"].indexOf(
      destination.split("/")[1]
    ) > -1
  )
    destination = `/${state.userInfo.username}`;

  return {
    type: "ROUTE",
    data: {
      prevRoute: prevRoute,
      destination: destination,
    },
  };
};

export const SET_CACHE = "SET_CACHE";
export const set_cache = (data) => ({
  type: SET_CACHE,
  data,
});

export const NEW_VIEWER = "NEW_VIEWER";
export const new_viewer = () => ({
  type: NEW_VIEWER,
});

export const LEAVE_VIEWER = "LEAVE_VIEWER";
export const leave_viewer = () => ({
  type: LEAVE_VIEWER,
});

export const REPLY = "REPLY";
export const reply = (replyID) => ({
  type: REPLY,
  data: replyID,
});

export const SET_EMISSION_REPORTED = "SET_EMISSION_REPORTED";
export const set_emission_reported = (emissionID) => ({
  type: SET_EMISSION_REPORTED,
  data: emissionID,
});

export const SET_NSFW_MODAL = "SET_NSFW_MODAL";
export const set_nsfw_modal = (option) => ({
  type: SET_NSFW_MODAL,
  data: option,
});

export const REMOVE_EMISSION = "REMOVE_EMISSION";
export const remove_emission = (emissionID) => ({
  type: REMOVE_EMISSION,
  data: emissionID,
});

export const RESTORE_EMISSION = "RESTORE_EMISSION";
export const restore_emission = (emission) => ({
  type: RESTORE_EMISSION,
  data: emission,
});

export const TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE";
export const toggle_dark_mode = () => ({
  type: TOGGLE_DARK_MODE,
});

export const RESTORE_USER = "RESTORE_USER";
export const restore_user = (userInfo, restoredEmissions) => ({
  type: RESTORE_USER,
  data: {
    userInfo: userInfo,
    emissions: restoredEmissions,
  },
});

export const SET_POLL_MODAL = "SET_POLL_MODAL";
export const set_poll_modal = (options) => ({
  type: SET_POLL_MODAL,
  data: options,
});
