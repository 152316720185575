import env from "../../env";
import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import { route, update_user, set_token, notify } from "../../redux/actions";
import TextInput from "../textInput/TextInput";
import axios from "axios";
import SampleEmission from "../emission/SampleEmission";
import { v4 as uuid } from "uuid";

const maxChars = Number(env.MAX_EMISSION_CHARS);

class SignalBoostModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the signal boost is in the process of being submitted
       */
      working: false,
      reset: false,
    };
  }

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Get captcha key
   * Make request to server
   * Add signal boost to application state
   * Hide modal
   * Notify user
   */
  submit = (files) => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        async () => {
          try {
            if (
              files?.length &&
              files.reduce((prev, curr) => prev + curr.size, 0) >
                Number(env.MAX_TOTAL_FILE_SIZE)
            )
              throw `Max total file size exceeded. (Max: ${Math.round(
                Number(env.MAX_TOTAL_FILE_SIZE) / (1024 * 1024)
              )}MB)`;
            const emissionData = document.getElementById("input-boost");
            const length = String(emissionData.textContent)
              .split("")
              .filter((c) => {
                const checkWhiteSpace = c.match(/[\s]/);
                if (!checkWhiteSpace) return true;
                else {
                  return [" ", "\n"].indexOf(c) > -1;
                }
              }).length;
            if (length > maxChars)
              throw `Character limit exceeded (Max: ${maxChars} characters)`;
            const eventID = uuid();
            const fd = new FormData();
            const captchaKey = await this.getRecaptcha();
            files.forEach((file) => {
              fd.append("files", file.file, file.name);
              if (file.file.type.includes("video"))
                fd.append("thumbnails", file.thumbnail, file.name);
            });
            fd.append("captchaKey", captchaKey);
            fd.append("emission", emissionData.innerHTML);
            fd.append("signalBoost", this.props.emission.emissionID);
            fd.append("eventID", eventID);
            axios
              .post(
                process.env.REACT_APP_LAMBDA_EMISSIONS + "/signalboost",
                fd,
                {
                  headers: {
                    Authorization: this.props.token,
                  },
                }
              )
              .then((res) => {
                this.props.toggleShowModal();
                this.props.set_token(res.data.token);
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                    reset: !this.state.reset,
                  }),
                  () => {
                    this.props.signalBoost({
                      eventID,
                      emissionID: this.props.emission.emissionID,
                      boostID: res.data.emission.emissionID,
                      userID: this.props.userInfo._id,
                    });
                    setTimeout(() => {
                      this.props.update_user({
                        ...this.props.userInfo,
                        boosts: [
                          ...this.props.userInfo.boosts,
                          {
                            boostID: this.props.emission.emissionID,
                            emissionID: res.data.emission.emissionID,
                          },
                        ],
                      });
                      this.props.notify(
                        <i className="fas fa-retweet me-2 text-success"></i>,
                        <>
                          Signal Boosted{" "}
                          <span className="text-pkmn">
                            #{this.props.emission.emissionID}
                          </span>
                        </>
                      );
                    }, 250);
                  }
                );
              })
              .catch((err) =>
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                  }),
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later.");
                  }
                )
              );
          } catch (err) {
            this.setState(
              (curr) => ({
                ...curr,
                working: false,
              }),
              () => alert(err)
            );
          }
        }
      );
  };

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  render() {
    return (
      <MDBModal
        staticBackdrop
        open={this.props.modalShown}
        onClosePrevented={() => {
          if (!this.state.working) this.props.toggleShowModal();
        }}
        tabIndex="-1"
      >
        <MDBModalDialog
          size={
            this.props.screenDimensions.width >
            this.props.screenDimensions.modalBreak
              ? "xl"
              : "fullscreen"
          }
        >
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                <span className="text-capitalize">
                  {env.SIGNALBOOST_CURRENT}{" "}
                </span>
                #
                <span className="text-pkmn">
                  {this.props.emission.emissionID}
                </span>
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={this.props.toggleShowModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <TextInput
                working={this.state.working}
                submit={this.submit}
                key={this.state.reset}
                flavor="boost"
                maxChars={maxChars}
                label="Quote Reply (Optional)"
              />
              {this.props.emission ? (
                <SampleEmission
                  emission={this.props.emission}
                  toggleModal={this.props.toggleShowModal}
                />
              ) : (
                <></>
              )}
            </MDBModalBody>
            {this.props.screenDimensions.width <=
            this.props.screenDimensions.modalBreak ? (
              <MDBModalFooter>
                <MDBBtn
                  className="bg-gray"
                  color="dark"
                  onClick={this.props.toggleShowModal}
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            ) : (
              <></>
            )}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  route,
  update_user,
  set_token,
  notify,
})(SignalBoostModal);
