import env from "../env";
import React from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import LogoLoader from "../components/LogoLoader";
import {
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  route,
  set_notification_count,
  set_token,
  set_redirect_url,
  set_cache,
} from "../redux/actions";
import UserInteractionModal from "./notifications/UserInteractionModal";

class Notifications extends React.Component {
  constructor(props) {
    super();
    let cachedData;
    this.initialScrollTop = 0;
    if (
      props.history?.location?.state?.currRoute.split("#")[0] ===
      "/notifications"
    ) {
      cachedData = props.cache.find((c) => c.page === "notifications");
      if (cachedData) this.initialScrollTop = cachedData?.scrollTop;
    }
    this.state = cachedData
      ? cachedData.state
      : {
          /**
           * notifications: Array - List of notifications
           * loaded: Boolean - Whether the initial data load has completed
           * interactionUsers: Array - Users that have interacted with the action that caused the selected notification
           * interactionDescription: String - Description of the selected notification
           * interactionModalShown: Boolean - Whether the Interaction modal is shown
           * socketConnected: Boolean - Whether the notification socket is connected
           */
          notifications: [],
          loaded: false,
          interactionUsers: [],
          interactionDescription: "",
          interactionModalShown: false,
          socketConnected: false,
          redirecting: false,
          socketEvents: [],
        };
  }

  /**
   * If the user is not logged in, redirect to the login page
   * Else, load notifications
   */
  componentDidMount() {
    if (this.initialScrollTop) {
      const root = document.getElementById("root");
      root.style.scrollBehavior = "auto";
      root.scrollTop = this.initialScrollTop;
      root.style.scrollBehavior = "smooth";
    }
    if (!this.props.userInfo._id) {
      this.setState(
        (curr) => ({
          ...curr,
          redirecting: true,
        }),
        () => {
          if (this.props.verificationDetails)
            this.props.history.push("/validate-email");
          else {
            this.props.set_redirect_url("/notifications");
            this.props.history.push("/login");
          }
        }
      );
    } else this.load();
  }

  /**
   * If the user logs out, route to the login page
   * If notifications are added, set timeout to mark the new ones as read
   * If disconnected socket reconnects, or if socket is first connected, initialize the socket
   */
  componentDidUpdate(prevProps) {
    if (!this.props.userInfo._id && !this.state.redirecting)
      this.setState(
        (curr) => ({
          ...curr,
          redirecting: true,
        }),
        () => {
          this.props.route("/login");
        }
      );
    if (prevProps.userInfo.notifications !== this.props.userInfo.notifications)
      this.readAll();
    if (!prevProps.socket && this.props.socket) this.initializeSocket();
  }

  componentWillUnmount() {
    this.props.set_cache({
      page: "notifications",
      state: this.state,
      scrollTop: document.getElementById("root")?.scrollTop,
    });
  }

  /**
   *
   * @param {String} description - Notification description
   * @param {Array} users - List of Users who are listed in the notification
   *
   * Triggered when the user clicks on one of the notifications that lists more than 3 users
   * Opens the Interaction modal
   */
  showInteractions = (description, users) =>
    this.setState(
      (curr) => ({
        ...curr,
        interactionDescription: description,
        interactionUsers: users,
      }),
      this.toggleInteractionModal
    );

  /**
   * Loads the initial data
   */
  load = () =>
    axios
      .get(process.env.REACT_APP_LAMBDA_API_NOTIFICATIONS, {
        headers: {
          Authorization: this.props.token,
        },
      })
      .then((res) => {
        this.props.set_token(res.data.token);
        this.setState(
          (curr) => ({
            ...curr,
            notifications: h.updateArrayItems(
              this.state.notifications,
              res.data.notifications
            ),
            loaded: true,
          }),
          () => {
            this.readAll();
            // if (this.props.socket && !this.state.socketConnected)
            //   this.connectSocket();
          }
        );
      })
      .catch((err) => {
        console.log("notification load error", err);
        if (!this.state.redirecting) setTimeout(this.load, 1000);
      });

  /**
   * Turn off and then turn back on all socket actions
   */
  initializeSocket = () => {
    // console.log("socket", this.props.socket);
    if (!this.props.socket) return;
    this.props.socket.off("notification");
    this.props.socket.off("remove-notification");
    this.props.socket.off("update-user");

    /**
     *
     * @param {Object} userInfo - Users document
     *
     * Received when a user in any of the notifications in state updates their profile
     * Update avatar/display name/verified/role of all emissions/replies/signalboosts with updated user info
     */
    this.props.socket.on("update-user", (userInfo) => {
      try {
        if (
          userInfo?.eventID &&
          this.state.socketEvents.find((e) => e === userInfo?.eventID)
        )
          return;
        this.setState((curr) => ({
          ...curr,
          socketEvents: userInfo?.eventID
            ? [...this.state.socketEvents, userInfo?.eventID]
            : this.state.socketEvents,
          notifications: this.state.notifications.map((notification) => {
            notification.interactions = notification.interactions.map(
              (interaction) => {
                if (interaction.userInfo._id === userInfo._id)
                  interaction.userInfo = userInfo;
                return interaction;
              }
            );
            return notification;
          }),
        }));
      } catch (err) {
        console.log("update-user error", err);
      }
    });

    /**
     * Received when a notification is removed (such as when another user deletes an emission that mentions the user)
     * Filters that notification from the list
     */
    this.props.socket.on("remove-notification", (info) => {
      console.log("remove-notification", info);
      try {
        if (
          info?.eventID &&
          this.state.socketEvents.find((e) => e === info?.eventID)
        )
          return;
        else
          this.setState(
            (curr) => ({
              ...curr,
              socketEvents: info?.eventID
                ? [...this.state.socketEvents, info?.eventID]
                : this.state.socketEvents,
              notifications: this.state.notifications
                .map((notification) => {
                  if (notification._id === info.notificationID)
                    return {
                      ...notification,
                      interactions: notification.interactions.filter(
                        (interaction) =>
                          interaction.userInfo._id !== info.userID
                      ),
                    };
                  else return notification;
                })
                .filter((notification) => notification.interactions.length),
            }),
            () =>
              this.props.set_notification_count(
                this.props.userInfo.notifications - 1
              )
          );
      } catch (err) {
        console.log("remove-notification error", err);
      }
    });

    /**
     * Received when a new notification is received
     * Insert that notification into state
     */
    this.props.socket.on("notification", (notification) => {
      console.log("notification", notification);
      try {
        if (
          notification?.eventID &&
          this.state.socketEvents.find((e) => e === notification?.eventID)
        )
          return;
        else
          this.setState(
            (curr) => ({
              ...curr,
              socketEvents: notification?.eventID
                ? [...this.state.socketEvents, notification?.eventID]
                : this.state.socketEvents,
              notifications: this.state.notifications.find(
                (n) => n._id === notification._id
              )
                ? this.state.notifications.map((n) => {
                    if (notification._id === n._id) return notification;
                    else return n;
                  })
                : [...curr.notifications, notification],
            }),
            () =>
              this.props.set_notification_count(
                this.props.userInfo.notifications + 1
              )
          );
      } catch (err) {
        console.log("notification error", err);
      }
    });
  };

  /**
   * Triggered any time the user loads the page or receives new notifications
   * After 1 second, marks all mod logs as read
   */
  readAll = () =>
    setTimeout(
      () =>
        axios
          .get(process.env.REACT_APP_LAMBDA_PROFILE + "/read-notifications", {
            headers: {
              Authorization: this.props.token,
            },
          })
          .then((res) => {
            this.props.set_token(res.data.token);
            this.setState(
              (curr) => ({
                ...curr,
                notifications: this.state.notifications.map((n) => ({
                  ...n,
                  unread: false,
                })),
              }),
              () => this.props.set_notification_count(0)
            );
          }),
      1000
    );

  /**
   *
   * @param {Click Event} e
   * @param {String} path - href/URL
   *
   * Triggered when the user clicks a link
   * Override default behavior and use redux props.route method
   */
  route = (e, destination) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.route(destination);
  };

  toggleInteractionModal = () =>
    this.setState((curr) => ({
      ...curr,
      interactionModalShown: !this.state.interactionModalShown,
    }));

  setInteractionModal = (option) =>
    this.setState((curr) => ({
      ...curr,
      interactionModalShown: option,
    }));

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
        className="pt-4 h-100 page-container"
      >
        <UserInteractionModal
          users={this.state.interactionUsers}
          description={this.state.interactionDescription}
          modalShown={this.state.interactionModalShown}
          toggleShowModal={this.toggleInteractionModal}
          setShowModal={this.setInteractionModal}
          route={this.route}
        />
        <MDBContainer>
          {this.state.loaded ? (
            <motion.div
              transition={t.transition}
              exit={t.fade_out_scale_1}
              animate={t.normalize}
              initial={t.fade_out}
            >
              {this.state.notifications.length ? (
                <MDBCard className="cards-full-width">
                  <MDBCardBody>
                    <h5 className="text-center display-6">Notifications</h5>
                    <hr />

                    <MDBListGroup light>
                      {[
                        ...this.state.notifications
                          .filter((n) => n.unread)
                          .sort(
                            (a, b) =>
                              new Date(b.lastInteraction) -
                              new Date(a.lastInteraction)
                          ),
                        ...this.state.notifications
                          .filter((n) => !n.unread)
                          .sort(
                            (a, b) =>
                              new Date(b.lastInteraction) -
                              new Date(a.lastInteraction)
                          ),
                      ].map((notification) => (
                        <MDBListGroupItem
                          className={`${
                            notification.unread ? "bg-litepink" : ""
                          } transition-1 px-2 notification-list-items`}
                          key={notification._id}
                        >
                          {(() => {
                            let named;
                            let others;
                            let allInteractions;
                            switch (notification.type) {
                              case "reply":
                                allInteractions = [];
                                notification.interactions
                                  .sort(
                                    (a, b) =>
                                      new Date(b.timestamp) -
                                      new Date(a.timestamp)
                                  )
                                  .forEach((interaction) => {
                                    if (
                                      !allInteractions.find(
                                        (i) =>
                                          i.userInfo._id ===
                                          interaction.userInfo._id
                                      )
                                    )
                                      allInteractions.push(interaction);
                                  });
                                named = allInteractions
                                  .filter((interaction, i) => i < 3)
                                  .map((interaction) => ({
                                    avatar:
                                      interaction.userInfo.avatar.thumbnail,
                                    user: interaction.userInfo.username,
                                  }));
                                others = allInteractions.length - 3;
                                if (others < 0) others = 0;
                                return (
                                  <div className="d-flex align-items-center notification-icon-avatar">
                                    <div className="d-flex align-items-center">
                                      <div
                                        style={{ width: "25px" }}
                                        className="me-3"
                                      >
                                        <i
                                          onClick={() =>
                                            this.showInteractions(
                                              <h5 className="m-0">
                                                <i
                                                  style={{
                                                    color: "rgb(255, 171, 0)",
                                                  }}
                                                  className="fas fa-comments me-2"
                                                />
                                                {"Users who replied to "}
                                                <Link
                                                  onClick={(e) =>
                                                    this.route(
                                                      e,
                                                      `/e/${notification.emissionID}`
                                                    )
                                                  }
                                                  to={`/e/${notification.emissionID}`}
                                                  className="links-generic fw-bold text-pkmn"
                                                >
                                                  #{notification.emissionID}
                                                </Link>
                                              </h5>,
                                              allInteractions.map(
                                                (interaction) => ({
                                                  _id: interaction.userInfo._id,
                                                  name: interaction.userInfo
                                                    .username,
                                                  avatar:
                                                    interaction.userInfo.avatar
                                                      .thumbnail,
                                                  link: `/e/${interaction.emissionID}`,
                                                })
                                              )
                                            )
                                          }
                                          style={{ color: "rgb(255, 171, 0)" }}
                                          className="fas fa-comments fa-lg cursor-pointer"
                                        />
                                      </div>
                                      <div
                                        style={{
                                          width: "48px",
                                          height: "48px",
                                          marginRight: "2.5rem",
                                        }}
                                        className="position-relative"
                                      >
                                        {named.map((name, n) => (
                                          <div
                                            key={name.user + "thumbnail"}
                                            style={{ left: `${1 * n}rem` }}
                                            className="chat-avatars position-absolute rounded-circle p-1 border border-blusteel bg-light"
                                          >
                                            <div
                                              className="fit-images fit-round"
                                              style={{
                                                backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${name.avatar}")`,
                                                borderRadius: "50%",
                                              }}
                                            ></div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>

                                    <div className="notification-names">
                                      {named.map((name, n) => (
                                        <>
                                          <Link
                                            onClick={(e) =>
                                              this.route(e, `/${name.user}`)
                                            }
                                            key={name.user + "name"}
                                            to={`/${name.user}`}
                                            className="links-generic fw-bold"
                                          >
                                            @{name.user}
                                          </Link>
                                          <span className="text-default">
                                            {n === named.length - 3 ? ", " : ""}
                                            {n === named.length - 2
                                              ? others
                                                ? ", "
                                                : named.length > 2
                                                ? ", and "
                                                : " and "
                                              : ""}
                                            {n === named.length - 1
                                              ? others
                                                ? ", and "
                                                : " "
                                              : ""}
                                          </span>
                                        </>
                                      ))}
                                      {others ? (
                                        <span
                                          onClick={() =>
                                            this.showInteractions(
                                              <h5 className="m-0">
                                                <i
                                                  style={{
                                                    color: "rgb(255, 171, 0)",
                                                  }}
                                                  className="fas fa-comments me-2"
                                                />
                                                {"Users who replied to "}
                                                <Link
                                                  onClick={(e) =>
                                                    this.route(
                                                      e,
                                                      `/e/${notification.emissionID}`
                                                    )
                                                  }
                                                  to={`/e/${notification.emissionID}`}
                                                  className="links-generic fw-bold text-pkmn"
                                                >
                                                  #{notification.emissionID}
                                                </Link>
                                              </h5>,
                                              allInteractions.map(
                                                (interaction) => ({
                                                  _id: interaction.userInfo._id,
                                                  name: interaction.userInfo
                                                    .username,
                                                  avatar:
                                                    interaction.userInfo.avatar
                                                      .thumbnail,
                                                  link: `/e/${interaction.emissionID}`,
                                                })
                                              )
                                            )
                                          }
                                          className="text-primary fw-bold cursor-pointer"
                                        >
                                          {others} Other
                                          {others === 1 ? " " : "s "}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      <span className="text-default">
                                        {"replied to "}
                                      </span>
                                      <Link
                                        onClick={(e) =>
                                          this.route(
                                            e,
                                            `/e/${notification.emissionID}`
                                          )
                                        }
                                        to={`/e/${notification.emissionID}`}
                                        className="links-generic fw-bold text-pkmn"
                                      >
                                        #{notification.emissionID}
                                      </Link>
                                    </div>
                                  </div>
                                );
                              case "signalboost":
                                allInteractions = [];
                                notification.interactions
                                  .sort(
                                    (a, b) =>
                                      new Date(b.timestamp) -
                                      new Date(a.timestamp)
                                  )
                                  .forEach((interaction) => {
                                    if (
                                      !allInteractions.find(
                                        (i) =>
                                          i.userInfo._id ===
                                          interaction.userInfo._id
                                      )
                                    )
                                      allInteractions.push(interaction);
                                  });
                                named = allInteractions
                                  .filter((interaction, i) => i < 3)
                                  .map((interaction) => ({
                                    avatar:
                                      interaction.userInfo.avatar.thumbnail,
                                    user: interaction.userInfo.username,
                                  }));
                                others = allInteractions.length - 3;
                                if (others < 0) others = 0;
                                return (
                                  <div className="d-flex align-items-center notification-icon-avatar">
                                    <div className="d-flex align-items-center">
                                      <div
                                        style={{ width: "25px" }}
                                        className="me-3"
                                      >
                                        <i
                                          className="fas fa-retweet fa-lg text-danger cursor-pointer"
                                          onClick={() =>
                                            this.showInteractions(
                                              <h5 className="m-0">
                                                <i className="fas fa-retweet text-danger me-2" />
                                                {"Users who"}
                                                <span className="text-capitalize">
                                                  {" "}
                                                  {
                                                    env.REACT_APP_SIGNALBOOST_PAST
                                                  }{" "}
                                                </span>
                                                <Link
                                                  onClick={(e) =>
                                                    this.route(
                                                      e,
                                                      `/e/${notification.emissionID}`
                                                    )
                                                  }
                                                  to={`/e/${notification.emissionID}`}
                                                  className="links-generic fw-bold text-pkmn"
                                                >
                                                  #{notification.emissionID}
                                                </Link>
                                              </h5>,
                                              allInteractions.map(
                                                (interaction) => ({
                                                  _id: interaction.userInfo._id,
                                                  name: interaction.userInfo
                                                    .username,
                                                  avatar:
                                                    interaction.userInfo.avatar
                                                      .thumbnail,
                                                  link: `/e/${interaction.emissionID}`,
                                                })
                                              )
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        style={{
                                          width: "48px",
                                          height: "48px",
                                          marginRight: "2.5rem",
                                        }}
                                        className="position-relative"
                                      >
                                        {named.map((name, n) => (
                                          <div
                                            key={name.user + "thumbnail"}
                                            style={{ left: `${1 * n}rem` }}
                                            className="chat-avatars position-absolute rounded-circle p-1 border border-blusteel bg-light"
                                          >
                                            <div
                                              className="fit-images fit-round"
                                              style={{
                                                backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${name.avatar}")`,
                                                borderRadius: "50%",
                                              }}
                                            ></div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>

                                    <div className="notification-names">
                                      {named.map((name, n) => (
                                        <>
                                          <Link
                                            onClick={(e) =>
                                              this.route(e, `/${name.user}`)
                                            }
                                            key={name.user + "name"}
                                            to={`/${name.user}`}
                                            className="links-generic fw-bold"
                                          >
                                            @{name.user}
                                          </Link>
                                          <span className="text-default">
                                            {n === named.length - 3 ? ", " : ""}
                                            {n === named.length - 2
                                              ? others
                                                ? ", "
                                                : named.length > 2
                                                ? ", and "
                                                : " and "
                                              : ""}
                                            {n === named.length - 1
                                              ? others
                                                ? ", and "
                                                : " "
                                              : ""}
                                          </span>
                                        </>
                                      ))}
                                      {others ? (
                                        <span
                                          onClick={() =>
                                            this.showInteractions(
                                              <h5 className="m-0">
                                                <i className="fas fa-retweet text-danger me-2" />
                                                {"Users who"}
                                                <span className="text-capitalize">
                                                  {" "}
                                                  {
                                                    env.REACT_APP_SIGNALBOOST_PAST
                                                  }{" "}
                                                </span>
                                                <Link
                                                  onClick={(e) =>
                                                    this.route(
                                                      e,
                                                      `/e/${notification.emissionID}`
                                                    )
                                                  }
                                                  to={`/e/${notification.emissionID}`}
                                                  className="links-generic fw-bold text-pkmn"
                                                >
                                                  #{notification.emissionID}
                                                </Link>
                                              </h5>,
                                              allInteractions.map(
                                                (interaction) => ({
                                                  _id: interaction.userInfo._id,
                                                  name: interaction.userInfo
                                                    .username,
                                                  avatar:
                                                    interaction.userInfo.avatar
                                                      .thumbnail,
                                                  link: `/e/${interaction.emissionID}`,
                                                })
                                              )
                                            )
                                          }
                                          className="text-primary fw-bold cursor-pointer"
                                        >
                                          {others} Other
                                          {others === 1 ? " " : "s "}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      <span className="text-default">
                                        <span className="text-capitalize">
                                          {env.REACT_APP_SIGNALBOOST_PAST}{" "}
                                        </span>
                                      </span>
                                      <Link
                                        onClick={(e) =>
                                          this.route(
                                            e,
                                            `/e/${notification.emissionID}`
                                          )
                                        }
                                        to={`/e/${notification.emissionID}`}
                                        className="links-generic fw-bold text-pkmn"
                                      >
                                        #{notification.emissionID}
                                      </Link>
                                    </div>
                                  </div>
                                );
                              case "like":
                                allInteractions = [];
                                notification.interactions
                                  .sort(
                                    (a, b) =>
                                      new Date(b.timestamp) -
                                      new Date(a.timestamp)
                                  )
                                  .forEach((interaction) => {
                                    if (
                                      !allInteractions.find(
                                        (i) =>
                                          i.userInfo._id ===
                                          interaction.userInfo._id
                                      )
                                    )
                                      allInteractions.push(interaction);
                                  });
                                named = allInteractions
                                  .filter((interaction, i) => i < 3)
                                  .map((interaction) => ({
                                    avatar:
                                      interaction.userInfo.avatar.thumbnail,
                                    user: interaction.userInfo.username,
                                  }));
                                others = allInteractions.length - 3;
                                if (others < 0) others = 0;
                                return (
                                  <div className="d-flex align-items-center notification-icon-avatar">
                                    <div className="d-flex align-items-center">
                                      <div
                                        style={{ width: "25px" }}
                                        className="me-3"
                                      >
                                        <i
                                          className="fas fa-heart fa-lg text-pink cursor-pointer"
                                          onClick={() =>
                                            this.showInteractions(
                                              <h5 className="m-0">
                                                <i className="fas fa-heart text-pink me-2" />
                                                {"Users who liked "}
                                                <Link
                                                  onClick={(e) =>
                                                    this.route(
                                                      e,
                                                      `/e/${notification.emissionID}`
                                                    )
                                                  }
                                                  to={`/e/${notification.emissionID}`}
                                                  className="links-generic fw-bold text-pkmn"
                                                >
                                                  #{notification.emissionID}
                                                </Link>
                                              </h5>,
                                              allInteractions.map(
                                                (interaction) => ({
                                                  _id: interaction.userInfo._id,
                                                  name: interaction.userInfo
                                                    .username,
                                                  avatar:
                                                    interaction.userInfo.avatar
                                                      .thumbnail,
                                                  link: `/${interaction.userInfo.username}`,
                                                })
                                              )
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        style={{
                                          width: "48px",
                                          height: "48px",
                                          marginRight: "2.5rem",
                                        }}
                                        className="position-relative"
                                      >
                                        {named.map((name, n) => (
                                          <div
                                            key={name.user + "thumbnail"}
                                            style={{ left: `${1 * n}rem` }}
                                            className="chat-avatars position-absolute rounded-circle p-1 border border-blusteel bg-light"
                                          >
                                            <div
                                              className="fit-images fit-round"
                                              style={{
                                                backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${name.avatar}")`,
                                                borderRadius: "50%",
                                              }}
                                            ></div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>

                                    <div className="notification-names">
                                      {named.map((name, n) => (
                                        <>
                                          <Link
                                            onClick={(e) =>
                                              this.route(e, `/${name.user}`)
                                            }
                                            key={name.user + "name"}
                                            to={`/${name.user}`}
                                            className="links-generic fw-bold"
                                          >
                                            @{name.user}
                                          </Link>
                                          <span className="text-default">
                                            {n === named.length - 3 ? ", " : ""}
                                            {n === named.length - 2
                                              ? others
                                                ? ", "
                                                : named.length > 2
                                                ? ", and "
                                                : " and "
                                              : ""}
                                            {n === named.length - 1
                                              ? others
                                                ? ", and "
                                                : " "
                                              : ""}
                                          </span>
                                        </>
                                      ))}
                                      {others ? (
                                        <span
                                          onClick={() =>
                                            this.showInteractions(
                                              <h5 className="m-0">
                                                <i className="fas fa-heart text-pink me-2" />
                                                {"Users who liked "}
                                                <Link
                                                  onClick={(e) =>
                                                    this.route(
                                                      e,
                                                      `/e/${notification.emissionID}`
                                                    )
                                                  }
                                                  to={`/e/${notification.emissionID}`}
                                                  className="links-generic fw-bold text-pkmn"
                                                >
                                                  #{notification.emissionID}
                                                </Link>
                                              </h5>,
                                              allInteractions.map(
                                                (interaction) => ({
                                                  _id: interaction.userInfo._id,
                                                  name: interaction.userInfo
                                                    .username,
                                                  avatar:
                                                    interaction.userInfo.avatar
                                                      .thumbnail,
                                                  link: `/${interaction.userInfo.username}`,
                                                })
                                              )
                                            )
                                          }
                                          className="text-primary fw-bold cursor-pointer"
                                        >
                                          {others} Other
                                          {others === 1 ? " " : "s "}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      <span className="text-default">
                                        {"liked "}
                                      </span>
                                      <Link
                                        onClick={(e) =>
                                          this.route(
                                            e,
                                            `/e/${notification.emissionID}`
                                          )
                                        }
                                        to={`/e/${notification.emissionID}`}
                                        className="links-generic fw-bold text-pkmn"
                                      >
                                        #{notification.emissionID}
                                      </Link>
                                    </div>
                                  </div>
                                );
                              case "vote":
                                allInteractions = [];
                                notification.interactions
                                  .sort(
                                    (a, b) =>
                                      new Date(b.timestamp) -
                                      new Date(a.timestamp)
                                  )
                                  .forEach((interaction) => {
                                    if (
                                      !allInteractions.find(
                                        (i) =>
                                          i.userInfo._id ===
                                          interaction.userInfo._id
                                      )
                                    )
                                      allInteractions.push(interaction);
                                  });
                                named = allInteractions
                                  .filter((interaction, i) => i < 3)
                                  .map((interaction) => ({
                                    avatar:
                                      interaction.userInfo.avatar.thumbnail,
                                    user: interaction.userInfo.username,
                                  }));
                                others = allInteractions.length - 3;
                                if (others < 0) others = 0;
                                return (
                                  <div className="d-flex align-items-center notification-icon-avatar">
                                    <div className="d-flex align-items-center">
                                      <div
                                        style={{ width: "25px" }}
                                        className="me-3"
                                      >
                                        <i
                                          className="fas fa-poll fa-lg text-secondary cursor-pointer"
                                          onClick={() =>
                                            this.showInteractions(
                                              <h5 className="m-0">
                                                <i className="fas fa-poll text-secondary me-2" />
                                                {"Users who voted on "}
                                                <Link
                                                  onClick={(e) =>
                                                    this.route(
                                                      e,
                                                      `/e/${notification.emissionID}`
                                                    )
                                                  }
                                                  to={`/e/${notification.emissionID}`}
                                                  className="links-generic fw-bold text-pkmn"
                                                >
                                                  #{notification.emissionID}
                                                </Link>
                                              </h5>,
                                              allInteractions.map(
                                                (interaction) => ({
                                                  _id: interaction.userInfo._id,
                                                  name: interaction.userInfo
                                                    .username,
                                                  avatar:
                                                    interaction.userInfo.avatar
                                                      .thumbnail,
                                                  link: `/${interaction.userInfo.username}`,
                                                })
                                              )
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        style={{
                                          width: "48px",
                                          height: "48px",
                                          marginRight: "2.5rem",
                                        }}
                                        className="position-relative"
                                      >
                                        {named.map((name, n) => (
                                          <div
                                            key={name.user + "thumbnail"}
                                            style={{ left: `${1 * n}rem` }}
                                            className="chat-avatars position-absolute rounded-circle p-1 border border-blusteel bg-light"
                                          >
                                            <div
                                              className="fit-images fit-round"
                                              style={{
                                                backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${name.avatar}")`,
                                                borderRadius: "50%",
                                              }}
                                            ></div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>

                                    <div className="notification-names">
                                      {named.map((name, n) => (
                                        <>
                                          <Link
                                            onClick={(e) =>
                                              this.route(e, `/${name.user}`)
                                            }
                                            key={name.user + "name"}
                                            to={`/${name.user}`}
                                            className="links-generic fw-bold"
                                          >
                                            @{name.user}
                                          </Link>
                                          <span className="text-default">
                                            {n === named.length - 3 ? ", " : ""}
                                            {n === named.length - 2
                                              ? others
                                                ? ", "
                                                : named.length > 2
                                                ? ", and "
                                                : " and "
                                              : ""}
                                            {n === named.length - 1
                                              ? others
                                                ? ", and "
                                                : " "
                                              : ""}
                                          </span>
                                        </>
                                      ))}
                                      {others ? (
                                        <span
                                          onClick={() =>
                                            this.showInteractions(
                                              <h5 className="m-0">
                                                <i className="fas fa-poll text-secondary me-2" />
                                                Users who voted on
                                                <Link
                                                  onClick={(e) =>
                                                    this.route(
                                                      e,
                                                      `/e/${notification.emissionID}`
                                                    )
                                                  }
                                                  to={`/e/${notification.emissionID}`}
                                                  className="links-generic fw-bold text-pkmn"
                                                >
                                                  {" "}
                                                  #{notification.emissionID}
                                                </Link>
                                              </h5>,
                                              allInteractions.map(
                                                (interaction) => ({
                                                  _id: interaction.userInfo._id,
                                                  name: interaction.userInfo
                                                    .username,
                                                  avatar:
                                                    interaction.userInfo.avatar
                                                      .thumbnail,
                                                  link: `/${interaction.userInfo.username}`,
                                                })
                                              )
                                            )
                                          }
                                          className="text-primary fw-bold cursor-pointer"
                                        >
                                          {others} Other
                                          {others === 1 ? " " : "s "}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      <span className="text-default">
                                        {"voted in "}
                                      </span>
                                      <Link
                                        onClick={(e) =>
                                          this.route(
                                            e,
                                            `/e/${notification.emissionID}`
                                          )
                                        }
                                        to={`/e/${notification.emissionID}`}
                                        className="links-generic fw-bold text-pkmn"
                                      >
                                        #{notification.emissionID}
                                      </Link>
                                    </div>
                                  </div>
                                );
                              case "follow":
                                return (
                                  <div className="d-flex align-items-center notification-icon-avatar">
                                    <div className="d-flex align-items-center">
                                      <div
                                        style={{ width: "25px" }}
                                        className="me-3"
                                      >
                                        <i className="fas fa-user-plus fa-lg text-success" />
                                      </div>
                                      <div
                                        style={{
                                          width: "48px",
                                          height: "48px",
                                          marginRight: "2.5rem",
                                        }}
                                      >
                                        <div className="chat-avatars rounded-circle p-1 border border-blusteel bg-light">
                                          <div
                                            className="fit-images fit-round"
                                            style={{
                                              backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${notification.interactions[0].userInfo.avatar.thumbnail}")`,
                                              borderRadius: "50%",
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="notification-names">
                                      <Link
                                        onClick={(e) =>
                                          this.route(
                                            e,
                                            `/${notification.interactions[0].userInfo.username}`
                                          )
                                        }
                                        to={`/${notification.interactions[0].userInfo.username}`}
                                        className="links-generic fw-bold"
                                      >
                                        @
                                        {
                                          notification.interactions[0].userInfo
                                            .username
                                        }
                                      </Link>
                                      <span className="text-default">
                                        {` ${env.FOLLOW_PAST} you`}
                                      </span>
                                    </div>
                                  </div>
                                );
                              case "mention":
                                if (notification.emissionID)
                                  return (
                                    <div className="d-flex align-items-center notification-icon-avatar">
                                      <div className="d-flex align-items-center">
                                        <div
                                          style={{ width: "25px" }}
                                          className="me-3"
                                        >
                                          <i className="fas fa-at fa-lg text-success" />
                                        </div>
                                        <div
                                          style={{
                                            width: "48px",
                                            height: "48px",
                                            marginRight: "2.5rem",
                                          }}
                                        >
                                          <div className="chat-avatars rounded-circle p-1 border border-blusteel bg-light">
                                            <div
                                              className="fit-images fit-round"
                                              style={{
                                                backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${notification.interactions[0].userInfo.avatar.thumbnail}")`,
                                                borderRadius: "50%",
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="notification-names">
                                        <Link
                                          onClick={(e) =>
                                            this.route(
                                              e,
                                              `/${notification.interactions[0].userInfo.username}`
                                            )
                                          }
                                          to={`/${notification.interactions[0].userInfo.username}`}
                                          className="links-generic fw-bold"
                                        >
                                          @
                                          {
                                            notification.interactions[0]
                                              .userInfo.username
                                          }
                                        </Link>
                                        <span className="text-default">
                                          {" mentioned you in "}
                                        </span>
                                        <Link
                                          onClick={(e) =>
                                            this.route(
                                              e,
                                              `/e/${notification.emissionID}`
                                            )
                                          }
                                          to={`/${notification.emissionID}`}
                                          className="links-generic fw-bold text-pkmn"
                                        >
                                          #{notification.emissionID}
                                        </Link>
                                      </div>
                                    </div>
                                  );
                                else
                                  return (
                                    <div className="d-flex align-items-center notification-icon-avatar">
                                      <div className="d-flex align-items-center">
                                        <div
                                          style={{ width: "25px" }}
                                          className="me-3"
                                        >
                                          <i className="fas fa-at fa-lg text-success" />
                                        </div>
                                        <div
                                          style={{
                                            width: "48px",
                                            height: "48px",
                                            marginRight: "2.5rem",
                                          }}
                                        >
                                          <div className="chat-avatars rounded-circle p-1 border border-blusteel bg-light">
                                            <div
                                              className="fit-images fit-round"
                                              style={{
                                                backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${notification.interactions[0].userInfo.avatar.thumbnail}")`,
                                                borderRadius: "50%",
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="notification-names">
                                        <Link
                                          onClick={(e) =>
                                            this.route(
                                              e,
                                              `/${notification.interactions[0].userInfo.username}`
                                            )
                                          }
                                          to={`/${notification.interactions[0].userInfo.username}`}
                                          className="links-generic fw-bold"
                                        >
                                          @
                                          {
                                            notification.interactions[0]
                                              .userInfo.username
                                          }
                                        </Link>
                                        <span className="text-default">
                                          {" mentioned you in their profile"}
                                        </span>
                                      </div>
                                    </div>
                                  );
                            }
                          })()}
                        </MDBListGroupItem>
                      ))}
                    </MDBListGroup>
                  </MDBCardBody>
                </MDBCard>
              ) : (
                <h5 className="text-center display-6 mb-4 mt-5">
                  You do not have any notifications
                </h5>
              )}
            </motion.div>
          ) : (
            <>
              <h5 className="text-center display-6 mb-4 mt-5">Notifications</h5>
              <LogoLoader />
            </>
          )}
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  route,
  set_notification_count,
  set_token,
  set_redirect_url,
  set_cache,
})(Notifications);
