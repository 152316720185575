import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBValidation,
  MDBValidationItem,
  MDBTextArea,
} from "mdb-react-ui-kit";
import Spinner from "../../../../components/Spinner";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import h from "../../../../utilities/helpers";
import axios from "axios";
import { connect } from "react-redux";
import { set_token } from "../../../../redux/actions";

// Reasons start
const reasons = [
  {
    id: "spam",
    label: "Spam/Advertising",
  },
  {
    id: "fed",
    label: "Illegal/Fedposting",
  },
  {
    id: "other",
    label: "Other",
  },
];
// Reasons end

class ReportModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the report is in the process of being submitted
       * reason: String - The report reason
       * input: Object - The text input data (values, errors, etc)
       */
      working: false,
      reason: reasons[0].id,
      input: {
        id: "profile-report-input",
        error: "",
        invalid: false,
        value: "",
      },
    };
  }

  componentDidUpdate() {
    h.floatLabels();
  }

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) => {
    this.setState(
      (curr) => ({
        ...curr,
        input: {
          ...curr.input,
          value: e.target.value,
        },
      }),
      () => {
        if (this.state.input.value.length > 5000)
          this.setState(
            (curr) => ({
              ...curr,
              input: {
                ...curr.input,
                invalid: true,
                error: "Too long (Max: 5000 chars)",
              },
            }),
            () =>
              document
                .getElementById("profile-report-input")
                .setCustomValidity(this.state.input.error)
          );
        else
          this.setState(
            (curr) => ({
              ...curr,
              input: {
                ...curr.input,
                invalid: false,
                error: "",
              },
            }),
            () =>
              document
                .getElementById("profile-report-input")
                .setCustomValidity("")
          );
      }
    );
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Update emission
   * Hide modal
   */
  submit = () => {
    document
      .getElementById("profile-report-form")
      .classList.add("was-validated");
    if (this.state.input.value.length > 5000)
      document
        .getElementById("profile-report-input")
        .setCustomValidity(this.state.input.error);
    if (!this.state.working && this.state.input.value.length <= 5000)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        async () => {
          try {
            const captchaKey = await this.getRecaptcha();
            const submission = {
              captchaKey: captchaKey,
              details: this.state.input.value,
              reason: this.state.reason,
              user_id: this.props.profileInfo?.user?.user_id,
            };
            axios
              .post(
                process.env.REACT_APP_LAMBDA_SUPPORT_USERS + "/report",
                submission,
                {
                  headers: {
                    Authorization: this.props.token,
                  },
                }
              )
              .then((res) => {
                this.props.setReported();
                this.props.set_token(res.data.token);
                this.setState((curr) => ({
                  ...curr,
                  working: false,
                  reason: reasons[0].id,
                  input: {
                    id: "profile-report-input",
                    error: "",
                    invalid: false,
                    value: "",
                  },
                }));
              })
              .catch((err) =>
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                  }),
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later.");
                  }
                )
              );
          } catch (err) {
            this.setState(
              (curr) => ({
                ...curr,
                working: false,
              }),
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  reasonChange = (e) =>
    this.setState((curr) => ({
      ...curr,
      reason: e.target.value,
    }));

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={() => {
              if (!this.state.working) this.props.toggleShowModal();
            }}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                    Report @{this.props.profileInfo?.user?.username}
                  </MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="d-flex mb-4">
                    <div className="px-4">
                      <FormControl>
                        <h5 className="fs-5 display-6 m-0">Reason</h5>
                        <hr className="my-1" />
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue={this.state.reason}
                          name="radio-buttons-group"
                          onChange={this.reasonChange}
                        >
                          {reasons.map((reason) => (
                            <FormControlLabel
                              key={reason.id}
                              value={reason.id}
                              control={<Radio />}
                              label={reason.label}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="flex-grow-1">
                      <MDBValidation
                        className="h-100"
                        method="dialog"
                        id="profile-report-form"
                        name="profile-report-form"
                      >
                        <MDBValidationItem
                          className="pb-4 h-100 h-100-child"
                          feedback={this.state.input.error}
                          invalid={true}
                        >
                          <MDBTextArea
                            name="profile-report-input"
                            onChange={this.changeHandler}
                            id="profile-report-input"
                            label="Details"
                            size="lg"
                            className={`h-100 ${
                              !this.state.input.invalid ? "mb-0" : 0
                            }`}
                          />
                        </MDBValidationItem>
                      </MDBValidation>
                    </div>
                  </div>
                  <small className="mt-4 d-block mx-auto text-center">
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy">
                      {" "}
                      Privacy Policy
                    </a>{" "}
                    and
                    <a href="https://policies.google.com/terms">
                      {" "}
                      Terms of Service
                    </a>{" "}
                    apply.
                  </small>
                </MDBModalBody>
                <MDBModalFooter>
                  {this.state.working ? (
                    <MDBBtn disabled color="danger">
                      <Spinner className="me-2" size="sm" />
                      Reporting
                    </MDBBtn>
                  ) : (
                    <MDBBtn onClick={this.submit} color="danger">
                      <i className="fas fa-flag me-2"></i>Report
                    </MDBBtn>
                  )}
                  <MDBBtn
                    className="bg-gray"
                    color="dark"
                    onClick={this.props.toggleShowModal}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { set_token })(ReportModal);
