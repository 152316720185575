const settings = document.getElementById("jizzer-env")?.textContent;

const imageExtensions =
  "image/png image/jpeg image/jpg image/gif image/bmp image/webp image/svg+xml ";
const audioExtensions =
  "audio/aac audio/mpeg audio/ogg audio/wav audio/wave audio/x-wav ";
const videoExtensions = "video/mp4 video/webm ";
let env = {};

const getExtensionString = (preferences) => {
  let string = "";
  if (preferences.IMAGES_ALLOWED) string += imageExtensions;
  if (preferences.AUDIO_ALLOWED) string += audioExtensions;
  if (preferences.VIDEO_ALLOWED) string += videoExtensions;

  return string.trim();
};

try {
  if (settings) {
    env = JSON.parse(settings);
    env.ALLOWED_EXTENSIONS = getExtensionString(env);
  } else
    Object.keys(process.env)
      .filter((key) => key.includes("REACT_APP_"))
      .forEach(
        (key) => (env[key.replace("REACT_APP_", "")] = process.env[key])
      );
} catch (err) {
  console.log("An error occurred while loading the environment", err, settings);
}

// console.log("env", env);

export default env;
