import env from "../env";
import React from "react";
import { connect } from "react-redux";
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBRipple,
} from "mdb-react-ui-kit";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import { motion } from "framer-motion";
import { route } from "../redux/actions";
import { Link } from "react-router-dom";
import Url from "url-parse";

class ProfileInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * userActionModalShown: Boolean - Whether the User Action (edit/ban) modal is shown
       * messageModalShown: Boolean - Whether the Direct Message modal is shown
       * liveStreamModalShown: Boolean - Whether the Live Stream modal is shown
       * reportModalShown: Boolean - Whether the Report modal is shown
       * working: Boolean - Whether the user is in the process of following or unfollowing the profile
       * showUnfollowText: Boolean - Whether the Unfollow prompt should be shown
       * blocking: Boolean - Whether the user is in the process of blocking the profile
       * unblocking: Boolean - Whether the user is in the process of unblocking the profile
       */
      userActionModalShown: false,
      messageModalShown: false,
      liveStreamModalShown: false,
      reportModalShown: false,
      working: false,
      showUnfollowText: false,
      blocking: false,
      unblocking: false,
      rippleDuration: 500,
    };
  }

  /**
   *
   * Special profiles have gold numbers.
   * The following User user_ids are special:
   * * User 1
   * * Any user over 10 with all the same numbers (i.e. 666)
   * * Any user over 10 where all numbers except for the first are zeros (i.e. 5000)
   *
   *
   * @returns Boolean - Whether the user is a special user
   */
  checkSpecialProfile = () => {
    if (this.props.profile.user_id === 1) return true;
    const split = String(this.props.profile.user_id).split("");
    if (split.length === 1) return false;
    if (split.every((c) => c === split[0])) return true;
    if (split.length < 3) return false;
    let special = true;
    split.forEach((char, s) => {
      if (s && Number(char)) special = false;
    });
    return special;
  };

  /**
   *
   * @param {Click Event} e
   * @param {String} path - href/URL
   *
   * Triggered when the user clicks a link
   * Override default behavior and use redux props.route method
   */
  route = (e, destination) => {
    e.preventDefault();
    this.props.route(destination);
  };

  /**
   * Returns a card background color based on the state of the emission
   *
   * If highlighted, light green
   * If author is blocked or emission is removed, light pink
   * If author blocks the user, light purple
   * If author has privated their account, light teal
   *
   * @returns A CSS background class
   */
  getCardBackground = () => {
    if (this.props.profile.isBlocked || this.props.profile.blocksMe)
      return "bg-litepurple";
    else if (this.props.profile.ban.banned || this.props.profile.deleted)
      return "bg-litepink";
    else if (this.props.profile.private) return "bg-liteteal";
    else return "";
  };

  /**
   *
   * @returns The ban expiration data of a banned user
   */
  getBanExpiry = () => {
    if (this.props.profile.ban.duration === "permanent")
      return "Ban is permanent";
    else {
      const expiry = new Date(this.props.profile.ban.timestamp);
      switch (this.props.profile.ban.duration) {
        case "day":
          expiry.setDate(expiry.getDate() + 1);
          break;
        case "week":
          expiry.setDate(expiry.getDate() + 7);
          break;
        case "month":
          expiry.setMonth(expiry.getMonth() + 1);
          break;
        default:
          console.log("ban is permanent");
      }
      return `Ban will expire on ${h.makeDateHR(expiry)} at ${h.getTimeHR(
        expiry
      )}`;
    }
  };

  /**
   * A message will be displayed at the bottom of the main profile card if any of the following are true:
   * * User blocks the profile
   * * User is blocked by the profile
   * * Profile is banned
   * * Profile is private
   *
   * @returns The profile footer
   */
  getFooter = () => {
    if (this.props.profile.deleted)
      return (
        <MDBContainer>
          <motion.section
            key="isBlocked"
            transition={t.transition}
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
          >
            <hr></hr>
            <h5 className="text-center display-6 mt-4 mb-2">
              @{this.props.profile.username} has deleted their account
            </h5>
          </motion.section>
        </MDBContainer>
      );
    else if (this.props.profile.isBlocked)
      return (
        <MDBContainer>
          <motion.section
            key="isBlocked"
            transition={t.transition}
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
          >
            <hr></hr>
            <h5 className="text-center display-6 mt-4 mb-2">
              @{this.props.profile.username} is blocked
            </h5>
          </motion.section>
        </MDBContainer>
      );
    else if (this.props.profile.blocksMe)
      return (
        <MDBContainer>
          <motion.article
            key="blocksMe"
            transition={t.transition}
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
          >
            <hr></hr>
            <h5 className="text-center display-6 mt-4 mb-2">You are blocked</h5>
          </motion.article>
        </MDBContainer>
      );
    else if (this.props.profile.ban.banned)
      return (
        <MDBContainer>
          <motion.div
            key="banned"
            transition={t.transition}
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
          >
            <hr></hr>
            <h5 className="text-center display-6">
              @{this.props.profile.username} is banned
            </h5>
            <div className="bg-emphasis p-2 rounded-6">
              <h5>
                Ban reason:{" "}
                <span className="text-transform-capitalize">
                  {this.props.profile.ban.reason}
                </span>
              </h5>
              <p>{this.props.profile.ban.details}</p>
              <p className="text-center">{this.getBanExpiry()}</p>
            </div>
          </motion.div>
        </MDBContainer>
      );
    else if (this.props.profile.private)
      return (
        <MDBContainer>
          <motion.section
            key="private"
            transition={t.transition}
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
          >
            <hr></hr>
            <h5 className="text-center display-6 mt-4 mb-2">
              @{this.props.profile.username} has made their profile private
            </h5>
          </motion.section>
        </MDBContainer>
      );
    else return <></>;
  };

  clickElsewhere = (e) => {
    if (!this.props.preventRouting) {
      e.stopPropagation();
      if (!e.target.classList.contains("no-route")) {
        this.props.route(`/${this.props.profile.username}`);
      }
    }
  };

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks inside the profile's bio
   * If the user clicked a link, route to the href
   */
  clickBio = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target;
    if (e.target.tagName === "SPAN") element = e.target.parentElement;
    if (element.tagName === "A") {
      const href = element.getAttribute("href");
      if (!href) {
        console.log("no link found", element, e.target);
        return;
      }
      const url = new Url(href);
      if (url?.hostname === window.location.hostname)
        this.props.route(url.pathname);
      else {
        console.log("native route SampleProfile");
        window.location = href;
      }
    } else if (!this.props.preventRouting)
      this.props.route("/" + this.props.profile.username);
  };

  render() {
    return (
      <>
        <MDBCard
          className={`${
            this.props.index ? "mb-4" : "my-4"
          } transition-25 ${this.getCardBackground()} ${
            this.props.preventRouting ? "" : "cursor-pointer scale-minor-hover"
          }`}
          onClick={this.clickElsewhere}
        >
          <MDBRipple rippleColor="primary" className="w-100" rippleTag="div">
            <MDBCardBody>
              <div className="position-relative">
                <div
                  className="w-100"
                  style={{
                    backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/images/${this.props.profile.background.main}")`,
                  }}
                  id="background-image-profile"
                ></div>
                <div id="avatar-border-profile">
                  <div
                    id="avatar-image-profile"
                    style={{
                      backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/images/${this.props.profile.avatar.main}")`,
                    }}
                  ></div>
                </div>
              </div>
              <MDBContainer className="sample-profile-info px-0" fluid>
                <div className="row mx-0 align-items-start">
                  {(!this.props.profile.private ||
                    h.checkJanny(this.props.userInfo)) &&
                  !this.props.profile.deleted ? (
                    <div className="col-12 col-xl-10 offset-0 offset-xl-2 px-0 d-flex justify-content-center">
                      {this.props.profile.location && (
                        <p className="text-blusteel text-break sample-profile-fact">
                          <i className="fas fa-map-marker-alt me-2"></i>
                          {this.props.profile.location}
                        </p>
                      )}
                      {this.props.profile.website && (
                        <a
                          className="ms-4 text-break sample-profile-fact no-route"
                          href={
                            this.props.profile.website.startsWith("http://") ||
                            this.props.profile.website.startsWith("https://")
                              ? this.props.profile.website
                              : `//${this.props.profile.website}`
                          }
                        >
                          <p className="no-route">
                            <i className="fas fa-link me-2 no-route"></i>
                            {this.props.profile.website}
                          </p>
                        </a>
                      )}
                      <p className="text-blusteel ms-4 sample-profile-fact">
                        <i className="far fa-calendar-alt me-2"></i>Joined{" "}
                        {h.getNiceDate(this.props.profile.creationDate)}
                      </p>
                    </div>
                  ) : (
                    <div className="col-12 col-xl-10 offset-0 offset-xl-2 px-0 d-flex justify-content-center">
                      <p className="invis">Private</p>
                    </div>
                  )}
                </div>
              </MDBContainer>
              <div className="d-flex justify-content-between sample-profile-profile-container">
                <div className="d-flex flex-column align-self-stretch min-w-20 ">
                  <Link
                    to={`/${this.props.profile.username}`}
                    onClick={(e) =>
                      this.route(e, `/${this.props.profile.username}`)
                    }
                  >
                    <h5 className="mb-0 text-default">
                      {this.props.profile.displayName}
                    </h5>
                  </Link>
                  <Link
                    to={`/${this.props.profile.username}`}
                    onClick={(e) =>
                      this.route(e, `/${this.props.profile.username}`)
                    }
                  >
                    <p className="text-blusteel mb-1">
                      @{this.props.profile.username}
                    </p>
                  </Link>
                  <div className="d-flex flex-column justify-content-end flex-grow-1">
                    {(!(
                      this.props.profile.ban.banned ||
                      this.props.profile.blocksMe ||
                      this.props.profile.isBlocked ||
                      this.props.profile.private
                    ) ||
                      this.props.userInfo._id === this.props.profile._id ||
                      h.checkJanny(this.props.userInfo)) &&
                      !this.props.profile.deleted && (
                        <div className="d-flex">
                          <p
                            title={
                              h.numberWithCommas(this.props.profile.followers) +
                              " " +
                              env[
                                this.props.profile.followers === 1
                                  ? "FOLLOW_NAME"
                                  : "FOLLOW_PLURAL"
                              ]
                            }
                            className="me-2 mb-0"
                          >
                            <span className="fw-bold">
                              {h.compiledNumber(this.props.profile.followers)}
                            </span>{" "}
                            {this.props.profile.followers === 1 ? (
                              <span className="text-capitalize text-blusteel">
                                {env.FOLLOW_NAME}
                              </span>
                            ) : (
                              <span className="text-capitalize text-blusteel">
                                {env.FOLLOW_PLURAL}
                              </span>
                            )}
                          </p>
                          <p
                            title={
                              h.numberWithCommas(this.props.profile.following) +
                              " " +
                              env.FOLLOW_CURRENT
                            }
                            className="mb-0"
                          >
                            <span className="fw-bold">
                              {h.compiledNumber(this.props.profile.following)}
                            </span>{" "}
                            <span className="text-blusteel">
                              {env.FOLLOW_CURRENT}
                            </span>
                          </p>
                        </div>
                      )}
                  </div>
                </div>
                {this.props.profile.bio &&
                (!(
                  this.props.profile.ban.banned ||
                  this.props.profile.blocksMe ||
                  this.props.profile.isBlocked ||
                  this.props.profile.private
                ) ||
                  this.props.userInfo._id === this.props.profile._id ||
                  h.checkJanny(this.props.userInfo)) &&
                !this.props.profile.deleted &&
                h.checkHTMLLength(this.props.profile.bio) ? (
                  <div className="flex-grow-1 align-self-stretch bg-emphasis mt-4 mx-2 p-2 rounded-6 text-break min-w-50">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.profile.bio,
                      }}
                      onClick={this.clickBio}
                    ></div>
                  </div>
                ) : (
                  <></>
                )}
                <div>
                  <Link
                    to={`/${this.props.profile.username}`}
                    onClick={(e) =>
                      this.route(e, `/${this.props.profile.username}`)
                    }
                  >
                    <h5
                      className={`mb-0 text-pkmn text-end mt-3 text-${
                        this.checkSpecialProfile() ? "gold" : "default"
                      }`}
                    >
                      #{this.props.profile.user_id}
                    </h5>
                  </Link>
                  {!(
                    this.props.profile.ban.banned ||
                    this.props.profile.blocksMe ||
                    this.props.profile.isBlocked ||
                    this.props.profile.private
                  ) ||
                    this.props.userInfo._id === this.props.profile._id ||
                    (h.checkJanny(this.props.userInfo) &&
                      !this.props.profile.deleted && (
                        <>
                          <p className="m-0 text-end">
                            <span className="fw-bold">
                              {this.props.profile.emissionCount}{" "}
                            </span>
                            {this.props.profile.emissionCount === 1
                              ? env.EMISSION_NAME
                              : env.EMISSION_PLURAL}
                          </p>
                          {this.props.live && this.props.profile.live && (
                            <>
                              <hr />
                              <h5 className="m-0 text-end">
                                {this.props.profile.live.streamTitle}
                              </h5>
                              <hr />
                              <h5 className="m-0 text-end">
                                <i className="fas fa-eye me-2 text-primary" />
                                {this.props.profile.live.viewers} Viewers
                              </h5>
                            </>
                          )}
                        </>
                      ))}
                </div>
              </div>
              {this.getFooter()}
            </MDBCardBody>
          </MDBRipple>
        </MDBCard>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route })(ProfileInfo);
