import env from "../env";
import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import { user_schema } from "../utilities/validations";
import axios from "axios";
import {
  route,
  set_user,
  set_verification_details,
  set_token,
} from "../redux/actions";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn,
  MDBContainer,
  MDBRipple,
  MDBCheckbox,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import h from "../utilities/helpers";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";
import TextInput from "../components/textInput/TextInput";
import Encrypter from "../utilities/Encrypter";

/**
 * Create Account
 */

const fields = [
  {
    text: "Username",
    id: "username",
    type: "text",
  },
  {
    text: "Email Address",
    id: "email",
    type: "text",
  },
  {
    text: "Password",
    id: "password1",
    type: "password",
  },
  {
    text: "Re-Enter Password",
    id: "password2",
    type: "password",
  },
  {
    text: "Display Name",
    id: "displayName",
    type: "text",
  },
  {
    text: "Location (Optional)",
    id: "location",
    type: "text",
  },
  {
    text: "Website (Optional)",
    id: "website",
    type: "text",
  },
];

const allowedExtensions = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/bmp",
  "image/webp",
  "image/svg+xml",
];

class CreateAccount extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether a new account is in the process of being submitted
       * inputs: Array - The input data (values, errors, etc)
       * avatar: String, Path to the user's avatar
       * avatarName: String, "Click to Change", or the name of the file the user has selected, if any
       * avatarFile: false | File object that contains an avatar file that the user selected
       * background: String, Path to the user's background
       * backgroundName: String, "Click to Change", or the name of the file the user has selected, if any
       * backgroundFile: false | File object that contains an background file that the user selected
       * reset: Boolean - When flipped, fixes an mdb ui bug
       * termsAgreed: Boolean - Whether the user has accepted the terms and conditions
       */
      working: false,
      inputs: fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: true,
        value: "",
      })),
      avatar: `${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/images/blank-avatar.png`,
      avatarName: "Click to Change",
      avatarFile: "",
      background: `${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/images/default-background.webp`,
      backgroundName: "Click to Change",
      backgroundFile: "",
      termsAgreed: false,
    };
  }

  /**
   * If user is already logged in, route to the user's profile page
   * Run blank changeHandler
   */
  componentDidMount() {
    if (this.props.userInfo.username)
      this.props.history.push(`/${this.props.userInfo.username}`);
    else if (this.props.verificationDetails)
      this.props.history.push("validate-email");
    document
      .getElementById("terms-check")
      .setCustomValidity("You must agree to the terms of service");
    this.changeHandler({
      target: {
        name: "",
      },
    });
  }

  /**
   * When logged in, route to user profile
   * After login attempt and sent verification details, navigate to page asking to validate email
   */
  componentDidUpdate(prevProps) {
    h.floatLabels();
    if (prevProps.userInfo.username !== this.props.userInfo.username) {
      this.props.route(`/${this.props.userInfo.username}`);
    }

    if (!prevProps.verificationDetails && this.props.verificationDetails) {
      this.props.route("/validate-email");
    }
  }

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) => {
    this.setState(
      (curr) => ({
        ...curr,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      }),
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value.trim()])
        );
        try {
          user_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState((curr) => ({
            ...curr,
            inputs: this.state.inputs.map((input) => {
              document.getElementById(input.id).setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          }));
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            (curr) => ({
              ...curr,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            }),
            () =>
              this.state.inputs.forEach((input) => {
                if (input.invalid)
                  document
                    .getElementById(input.id)
                    .setCustomValidity(input.error);
                else document.getElementById(input.id).setCustomValidity("");
              })
          );
        }
      }
    );
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Set user to newly created account
   */
  submit = () => {
    this.forceParse();
    document.getElementById("form").classList.add("was-validated");
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document.getElementById(input.id).setCustomValidity(input.error)
    );
    if (!this.state.working && !invalidInputs.length && this.state.termsAgreed)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        async () => {
          const data = Object.fromEntries(
            this.state.inputs.map((input) => [input.id, input.value.trim()])
          );
          try {
            user_schema.validateSync(data, {
              abortEarly: false,
            });
            const bio = document.getElementById("input-bio-main");
            const length = String(bio.textContent)
              .split("")
              .filter((c) => {
                const checkWhiteSpace = c.match(/[\s]/);
                if (!checkWhiteSpace) return true;
                else {
                  return [" ", "\n"].indexOf(c) > -1;
                }
              }).length;
            if (length > 1000)
              this.setState(
                (curr) => ({
                  ...curr,
                  working: false,
                }),
                () => alert("Your bio is too long (Max: 1000 chars)")
              );
            else {
              const captchaKey = await this.getRecaptcha();
              const fd = new FormData();
              for (const key in data) {
                fd.append(key, data[key]);
              }
              fd.append(
                "bio",
                bio.innerHTML.replace(/[\u200B-\u200D\uFEFF]/g, "")
              );
              if (this.state.avatarFile)
                fd.append(
                  "avatar",
                  this.state.avatarFile,
                  this.state.avatarName
                );
              if (this.state.backgroundFile)
                fd.append(
                  "background",
                  this.state.backgroundFile,
                  this.state.backgroundName
                );
              fd.append("captchaKey", captchaKey);
              axios
                .post(
                  process.env.REACT_APP_LAMBDA_AUTH + "/create_account",
                  fd,
                  {
                    headers: {
                      Authorization: this.props.token,
                    },
                  }
                )
                .then((res) => {
                  this.props.set_token(res.data.token);
                  if (res.data.error)
                    this.setState(
                      (curr) => ({
                        ...curr,
                        working: false,
                      }),
                      () => alert(res.data.error)
                    );
                  else {
                    window.requireApproval = env.REQUIRE_APPROVAL;
                    window.emailVerificationRequired =
                      env.EMAIL_VERIFICATION_REQUIRED;
                    if (String(window.emailVerificationRequired) === "true") {
                      this.props.set_verification_details(res.data);
                    } else if (String(window.requireApproval) === "true") {
                      this.props.route("/awaiting-approval");
                    } else {
                      localStorage.setItem("userID", res.data.userInfo._id);
                      localStorage.setItem(
                        "chatKey",
                        new Encrypter(
                          this.state.inputs.find(
                            (i) => i.id === "password1"
                          ).value
                        ).decrypt(res.data.userInfo.chatKey)
                      );
                      this.props.set_user(res.data.userInfo);
                    }
                  }
                })
                .catch((err) =>
                  this.setState(
                    (curr) => ({
                      ...curr,
                      working: false,
                    }),
                    () => {
                      console.log(err);
                      alert("An error occurred. Please try again later");
                    }
                  )
                );
            }
          } catch (err) {
            this.setState(
              (curr) => ({
                ...curr,
                working: false,
              }),
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  pressEnter = (e) => {
    if (e.key === "Enter") this.submit();
  };

  /**
   * Fired when the user clicks their avatar or background
   *
   * Creates a virtual file input
   * Adds a change event that sets the selected file into state
   * Appends to document body (necessary for iDevices and possibly others)
   * Clicks the input
   * Removes the input after the file is selected
   */
  selectFile = (type) => {
    if (String(env.UPLOADS_LOCKED) === "true")
      return alert(
        "File uploads are disabled because this free tier instance has reached or exceeded the free tier limit."
      );
    let input = document.createElement("input");
    input.type = "file";
    input.style.visibility = "hidden";
    input.style.position = "fixed";
    document.body.appendChild(input);
    input.onchange = (e) => {
      let file = e.target.files[0];
      if (allowedExtensions.indexOf(file.type) !== -1) {
        if (file.size > Number(env.MAX_INDIVIDUAL_FILE_SIZE))
          alert(
            `Max individual file size exceeded. (Max: ${Math.round(
              Number(env.MAX_INDIVIDUAL_FILE_SIZE) / (1024 * 1024)
            )}MB)`
          );
        else
          this.setState(
            (curr) => ({
              ...curr,
              [`${type}Name`]: e.target.files[0].name,
              [`${type}File`]: e.target.files[0],
              [type]: URL.createObjectURL(e.target.files[0]),
            }),
            () => document.body.removeChild(input)
          );
      } else {
        document.body.removeChild(input);
        alert("Please select a valid image file (png, jpg, gif, bmp, webp)");
      }
    };
    input.click();
  };

  /**
   *
   * @param {Click Event} e
   * @param {String} path - href/URL
   *
   * Triggered when the user clicks a link
   * Override default behavior and use redux props.route method
   */
  linkRoute = (e, path) => {
    e.preventDefault();
    this.props.route(path);
  };

  /**
   *
   * @param {Event} e - Keypress event
   *
   * Triggered when the user presses the Tab key
   * Moves cursor to next input (MDB is bugged)
   * Removed when MDB fixes
   */
  pressTab = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const input = this.state.inputs.find((f) => f.id === e.target.id);
      if (input) {
        const nextField =
          this.state.inputs[this.state.inputs.indexOf(input) + 1];
        if (nextField) {
          const element = document.getElementById(nextField.id);
          if (element) {
            setTimeout(() => {
              element.focus();
              element.select();
            }, 100);
          }
        }
      }
    }
  };

  /**
   *
   * @param {Checkbox Event} e
   *
   * Triggered when the user toggles the Terms and Conditions checkbox
   */
  termsChange = (e) =>
    this.setState(
      (curr) => ({
        ...curr,
        termsAgreed: e.target.checked,
      }),
      () => {
        if (!this.state.termsAgreed)
          document
            .getElementById("terms-check")
            .setCustomValidity("You must agree to the terms of service");
        else document.getElementById("terms-check").setCustomValidity("");
      }
    );

  render() {
    return (
      <motion.div
        className="py-4 page-container"
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
      >
        <MDBContainer>
          <h1 className="display-4 text-center">Create Account</h1>
          <div className="w-100 d-flex justify-content-center">
            <MDBBtn
              color="link"
              rippleColor="primary"
              className="d-block mx-auto mt-2 mb-0 text-unset fs-6"
              onClick={() => this.props.route("/login")}
            >
              Already Have an Account? Login
              <i className="fas fa-sign-in-alt ms-2"></i>
            </MDBBtn>
          </div>
          <hr></hr>
          <MDBCard className="cards-full-width">
            <MDBCardBody>
              <MDBValidation
                method="dialog"
                id="form"
                name="form"
                className="row mt-4"
                onSubmit={this.submit}
              >
                {fields
                  .filter((field) => ["bio", "avatar"].indexOf(field.id) === -1)
                  .map((i) => (
                    <MDBValidationItem
                      key={i.id}
                      className="pb-4 col-12 col-lg-6"
                      feedback={
                        this.state.inputs.find((input) => input.id === i.id)
                          .error
                      }
                      invalid={true}
                    >
                      <MDBInput
                        name={i.id}
                        onChange={this.changeHandler}
                        id={i.id}
                        label={i.text}
                        size="lg"
                        className={
                          !this.state.inputs.find((input) => input.id === i.id)
                            .invalid
                            ? "mb-0"
                            : 0
                        }
                        type={i.type}
                        onKeyPress={this.pressEnter}
                        onKeyDown={this.pressTab}
                      />
                    </MDBValidationItem>
                  ))}
                <div className="col-12 col-lg-6">
                  <TextInput
                    setForceParse={(f) => (this.forceParse = f)}
                    working={this.state.working}
                    maxChars={1000}
                    flavor="bio-main"
                    label="Bio"
                    reset={false}
                  />
                </div>
                <div className="row justify-content-center">
                  <div className="col-12 col-lg-6 mt-4">
                    <p
                      style={{ fontSize: "1.5rem" }}
                      className="text-center display-6"
                    >
                      Display Picture
                    </p>
                    <div className="d-flex justify-content-center">
                      <div className="d-flex justify-content-center align-items-center square-15 mx-auto">
                        <MDBRipple
                          onClick={() => this.selectFile("avatar")}
                          tag="div"
                          rippleColor="primary"
                          className="fit-images fit-round"
                          style={{
                            backgroundImage: `url("${this.state.avatar}")`,
                            cursor: "pointer",
                            borderRadius: "50%",
                          }}
                        ></MDBRipple>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 mt-4">
                    <p
                      style={{ fontSize: "1.5rem" }}
                      className="text-center display-6"
                    >
                      Background Picture
                    </p>
                    <div
                      style={{ height: "250px" }}
                      className="d-flex justify-content-center align-items-center w-100 mx-auto"
                    >
                      <MDBRipple
                        onClick={() => this.selectFile("background")}
                        tag="div"
                        rippleColor="light"
                        className="fit-background cursor-pointer"
                        style={{
                          backgroundImage: `url("${this.state.background}")`,
                        }}
                      ></MDBRipple>
                    </div>
                  </div>
                  <div className="col-12">
                    <hr></hr>
                  </div>
                  <MDBValidationItem
                    className="d-flex justify-content-center"
                    feedback="You must agree to the terms of service"
                    invalid
                  >
                    <MDBCheckbox
                      label={
                        <p className="m-0">
                          I have read and agree to the{" "}
                          <Link
                            to="/info"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open("/info");
                            }}
                          >
                            Terms and Conditions
                          </Link>
                        </p>
                      }
                      id="terms-check"
                      checked={this.state.termsAgreed}
                      onChange={this.termsChange}
                    />
                  </MDBValidationItem>
                  <div className="col-12 col-lg-6">
                    {this.state.working ? (
                      <MDBBtn
                        htmlFor="button"
                        color="success"
                        size="lg"
                        className="w-100 mt-4"
                        block
                        disabled
                      >
                        <Spinner size="sm" className="me-2" />
                        Working
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        htmlFor="button"
                        color="success"
                        onClick={this.submit}
                        size="lg"
                        block
                        className="w-100 mt-4"
                      >
                        <i className="fas fa-paper-plane me-2"></i>Submit
                      </MDBBtn>
                    )}
                    <small className="mt-2 d-block mx-auto text-center">
                      This site is protected by reCAPTCHA and the Google
                      <a href="https://policies.google.com/privacy">
                        {" "}
                        Privacy Policy
                      </a>{" "}
                      and
                      <a href="https://policies.google.com/terms">
                        Terms of Service
                      </a>{" "}
                      apply.
                    </small>
                  </div>
                </div>
              </MDBValidation>
            </MDBCardBody>
          </MDBCard>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  route,
  set_user,
  set_verification_details,
  set_token,
})(CreateAccount);
