import React from "react";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import { connect } from "react-redux";
import {
  MDBContainer,
  MDBBtn,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import {
  set_verification_details,
  set_token,
  route,
  set_redirect_url,
  notify,
} from "../redux/actions";
import Count from "../components/Count";
import Spinner from "../components/Spinner";
import axios from "axios";
import { change_email_schema } from "../utilities/validations";

class ValidateEmail extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether another verification email is being requested
       * validationSent: Boolean - Whether a verification email has been sent
       * reset: Boolean - Measures nothing, but flipping this will cause the countdown to re-render
       * resetInterval: false | Interval that toggles state every second
       */
      working: false,
      validationSent: false,
      reset: false,
      resetInterval: false,
      ten: "0",
      fadeTen: true,
      newEmail: {
        value: "",
        error: "",
      },
      changingEmail: false,
      redirecting: false,
    };
  }

  // Set resetInterval
  componentDidMount() {
    if (!this.props.verificationDetails)
      this.setState(
        (curr) => ({
          ...curr,
          redirecting: true,
        }),
        () => {
          this.props.set_redirect_url("/validate-email");
          this.props.history.push("/login");
        }
      );
    else
      this.setState((curr) => ({
        ...curr,
        resetInterval: setTimeout(this.reset, 1000),
      }));
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.userInfo._id && this.props.userInfo._id)
      this.props.route("/" + this.props.userInfo.username);
    else if (!this.props.verificationDetails && !this.props.userInfo._id) {
      this.props.route("/login");
    }
  }

  /**
   * Clear reset interval
   * Clear verification details
   */
  componentWillUnmount() {
    clearTimeout(this.state.resetInterval);
    if (this.props.userInfo._id) this.props.set_verification_details(false);
  }

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  reset = () => {
    const secondsLeft =
      (Math.round(
        new Date().getTime() / 1000 -
          new Date(
            new Date().setTime(
              new Date(this.props.verificationDetails.timestamp).getTime()
            )
          ).getTime() /
            1000
      ) -
        60) *
      -1;
    const ten = String(secondsLeft).split("")[0];
    this.setState(
      (curr) => ({
        ...curr,
        reset: !this.state.reset,
        ten: ten,
        fadeTen: ten !== this.state.ten,
      }),
      () => {
        if (secondsLeft)
          this.setState((curr) => ({
            ...curr,
            resetInterval: setTimeout(this.reset, 1000),
          }));
      }
    );
  };

  /**
   * Triggered when the user clicks the Resend button
   * Sends another verification email
   * Updates application state with new verification details
   * https://localhost:3001/verify/d6a7a8f0-df4a-41b6-ad40-22c759b080a1
   */
  resend = () => {
    if (!(this.state.working || this.state.changingEmail))
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        () =>
          axios
            .get(process.env.REACT_APP_LAMBDA_AUTH + "/resend-verification", {
              headers: {
                Authorization: this.props.token,
              },
            })
            .then((res) => {
              this.props.set_token(res.data.token);
              this.props.notify(
                <i className="fas fa-paper-plane me-2 text-success" />,
                "Email Resent"
              );
              this.setState(
                (curr) => ({
                  ...curr,
                  validationSent: true,
                  working: false,
                }),
                () => {
                  this.props.set_verification_details(res.data);
                }
              );
            })
            .catch((err) => {
              console.log(err);
              this.setState(
                (curr) => ({
                  ...curr,
                  working: false,
                }),
                () => alert("An error occurred. Please try again later.")
              );
            })
      );
  };

  getSecondsLeft = (secondsLeft) => {
    return (
      <>
        {secondsLeft === 60 ? (
          "1:00"
        ) : (
          <>
            0:{String(secondsLeft).length === 1 ? "0" : ""}
            {String(secondsLeft)
              .split("")
              .map((digit, d) => {
                if (!d && !this.state.fadeTen) {
                  return <div className="d-inline">{digit}</div>;
                }

                return <Count value={digit} />;
              })}
          </>
        )}
      </>
    );
  };

  emailChange = (e) => {
    let error = "";
    try {
      change_email_schema.validateSync(
        {
          email: e.target.value,
        },
        {
          abortEarly: false,
        }
      );
    } catch (err) {
      error = err.inner.find((error) => error.path === "email").message;
    }
    document.getElementById("email").setCustomValidity(error);
    this.setState((curr) => ({
      ...curr,
      newEmail: {
        value: e.target.value,
        error: error,
      },
    }));
  };

  changeEmail = () => {
    const secondsLeft =
      (Math.round(
        new Date().getTime() / 1000 -
          new Date(
            new Date().setTime(
              new Date(this.props.verificationDetails.timestamp).getTime()
            )
          ).getTime() /
            1000
      ) -
        60) *
      -1;
    if (
      !(
        secondsLeft > 0 ||
        this.state.working ||
        this.state.changingEmail ||
        this.state.newEmail.error
      )
    ) {
      document.getElementById("change-email").classList.add("was-validated");
      this.setState(
        (curr) => ({
          ...curr,
          changingEmail: true,
        }),
        () => {
          axios
            .post(
              process.env.REACT_APP_LAMBDA_AUTH + "/update-email",
              {
                email: this.state.newEmail.value,
              },
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.props.notify(
                <i className="fas fa-check-circle me-2 text-success" />,
                "Email Updated"
              );
              this.setState(
                (curr) => ({
                  ...curr,
                  changingEmail: false,
                }),
                () => {
                  if (res.data.error) alert(res.data.error);
                  else {
                    this.props.set_verification_details(res.data);
                  }
                }
              );
            })
            .catch((err) => {
              console.log(err);
              this.setState(
                (curr) => ({
                  ...curr,
                  changingEmail: false,
                }),
                () => alert("An error occurred. Please try again later.")
              );
            });
        }
      );
    }
  };

  render() {
    if (!this.props.verificationDetails) return <></>;
    const secondsLeft =
      (Math.round(
        new Date().getTime() / 1000 -
          new Date(
            new Date().setTime(
              new Date(this.props.verificationDetails.timestamp).getTime()
            )
          ).getTime() /
            1000
      ) -
        60) *
      -1;
    return (
      <motion.div
        className={`my-4 ${this.props.fromLoginModal ? "" : "page-container"}`}
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
      >
        <MDBContainer>
          <h5 className="text-center display-6">
            An email has been sent from{" "}
            {process.env.REACT_APP_VERIFICATION_EMAIL} to verify your email
            address, {this.props.verificationDetails.email}. Please click the
            link in this email to verify your account.
          </h5>
          {this.props.verificationDetails ? (
            <MDBBtn
              disabled={
                secondsLeft > 0 ||
                this.state.working ||
                this.state.changingEmail
              }
              color="primary"
              className="d-block mx-auto mt-2 text-nowrap"
              onClick={this.resend}
              size="lg"
            >
              {secondsLeft <= 0 ? (
                <motion.div
                  transition={t.transition}
                  initial={t.fade_out}
                  animate={t.normalize}
                  exit={t.fade_out_scale_1}
                >
                  {this.state.working ? (
                    <>
                      <Spinner size="sm" className="me-2" />
                      Resending
                    </>
                  ) : (
                    <>
                      <i className="fas fa-paper-plane me-2"></i>
                      Resend Email
                    </>
                  )}
                </motion.div>
              ) : (
                <motion.section
                  transition={t.transition}
                  initial={t.fade_out}
                  animate={t.normalize}
                  exit={t.fade_out_scale_1}
                >
                  Resend in <Count value={secondsLeft} isTime={true} />
                </motion.section>
              )}
            </MDBBtn>
          ) : (
            <></>
          )}
          <hr />
          <div className="w-50 mx-auto">
            <p className="text-center mb-2">
              If your email address is incorrect, you may change it here.
            </p>
            <MDBValidation
              name="change-email"
              method="dialog"
              id="change-email"
              onSubmit={this.changeEmail}
            >
              <MDBValidationItem
                className="pb-4"
                feedback={this.state.newEmail.error}
                invalid={true}
              >
                <MDBInput
                  name="email"
                  id="email"
                  value={this.state.newEmail.value}
                  disabled={this.state.working || this.state.changingEmail}
                  label="New Email"
                  size="lg"
                  className={!this.state.newEmail.error ? "mb-0" : 0}
                  onChange={this.emailChange}
                />
              </MDBValidationItem>
            </MDBValidation>

            <MDBBtn
              className={`d-block mx-auto ${
                this.props.fromLoginModal ? "" : "w-50"
              }`}
              color="success"
              onClick={this.changeEmail}
              disabled={
                secondsLeft > 0 ||
                this.state.working ||
                this.state.changingEmail
              }
              size="lg"
            >
              {secondsLeft <= 0 ? (
                <motion.div
                  transition={t.transition}
                  initial={t.fade_out}
                  animate={t.normalize}
                  exit={t.fade_out_scale_1}
                >
                  {this.state.changingEmail ? (
                    <>
                      <Spinner size="sm" className="me-2" />
                      Updating Email
                    </>
                  ) : (
                    <>
                      <i className="fas fa-edit me-2" />
                      Update email
                    </>
                  )}
                </motion.div>
              ) : (
                <motion.section
                  transition={t.transition}
                  initial={t.fade_out}
                  animate={t.normalize}
                  exit={t.fade_out_scale_1}
                >
                  You may change in <Count value={secondsLeft} isTime={true} />
                </motion.section>
              )}
            </MDBBtn>
          </div>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  set_verification_details,
  set_token,
  route,
  set_redirect_url,
  notify,
})(ValidateEmail);
